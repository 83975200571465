<div class="page-container h-100">
    <div class="page-content h-100">

        <!-- Back to teams header -->
        <app-weekly-planning-teams [teamId]="teamId" (calendarChanged)="calendarChanged($event)"
        [calendarId]="calendarId" (teamSize)="teamSizeHandler($event)"></app-weekly-planning-teams>
        <!-- End of back to teams header -->
        <div [ngClass]="{'d-flex': mapMode === 1}">
            <div [ngClass]="{'map-small': mapMode === 3, 'map-split': mapMode === 1, 'map-large': mapMode === 2}">
                <div class="position-markers-explanations shadow p-2" [hidden]="showExplanations">
                    <load-spinner *ngIf="spinner"></load-spinner>
                    <div *ngIf="!spinner">
                        <div *ngFor="let item of markersExplanations">
                            <div class="d-flex px-2">
                                <div class="svg-dimensions" [innerHTML]="item.marker" *ngIf="item.marker != null"></div>
                                <div class="ms-1 small-text position-text gray-text">{{item.explanations}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="position-info-box float-end">
                    <div *ngIf="showMapSizes" class="mr-3 d-inline-block">
                        <button type="button" (click)="selectMapSize(1)" class="btn btn-secondary padding-button shadow">{{'planner.weeklyPlanningMap.split' | translate }}</button>
                        <button type="button" (click)="selectMapSize(2)" class="btn btn-secondary padding-button shadow mx-3">{{'planner.weeklyPlanningMap.large' | translate }}</button>
                        <button type="button" (click)="selectMapSize(3)" class="btn btn-secondary padding-button shadow">{{'planner.weeklyPlanningMap.small' | translate }}</button>
                    </div>
                    <button type="button" class="btn btn-secondary padding-button mr-3 shadow" (click)="toggleMapSize()">
                        <img [src]=mapInfoBox>
                    </button>
                    <button type="button" class="btn btn-secondary padding-button shadow" (click)="toggleMarkersExplanations()">
                        <img [src]=infoBox>
                    </button>
                </div>
                <div id="select-box" *ngIf="selectedMeters.length > 0" [ngClass]="{'select-box-small': mapMode === 3, 'select-box-split': mapMode === 1, 'select-box-large': mapMode === 2}">
                        <div class="p-3 meter-container">
                            <h6>{{'basic.selected' | translate }} ({{selectedMeters.length}})</h6>
                            <div class=" gray-text">
                                <div class="small-text" *ngFor="let meter of selectedMeters">
                                
                                    <div *ngIf="!meter.workorders" class="dual-container">
                                        <div class="left">
                                            <div class="left svg-inside" *ngIf="meter.icon" [innerHtml]="meter.icon | safe"></div>
                                            <div class="right ms-1" [hidden]="!meter.old_device_id">{{meter.old_device_id}}</div>
                                            <div class="right red-text ms-1" [hidden]="meter.old_device_id">{{'basic.noID' |
                                                translate }}</div>
                                        </div>
                                        <div class="right">
                                            <div class="right d-flex mr-3">
                                                <button class="btn-close my-auto"
                                                    (click)="removeFromSelected(meter.id)"></button>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <details *ngIf="meter.workorders">
                                        <summary class="dual-container">
                                            <div class="left">
                                                <div class="left svg-inside mr-6px" *ngIf="meter.icon" [innerHtml]="meter.icon | safe"></div>
                                                <div class="right small-text" (click)="expandArrowListener(meter.groupId)">
                                                    {{'basic.ordergroup' | translate }} {{meter.identifier}}
                                                    <br>
                                                    <div class="dual-container">
                                                        <div class="left">
                                                            ({{meter.workorders.length}} {{'basic.meters' | translate |
                                                            lowercase }}
                                                            )
                                                        </div>
                                                        <div class="right">
                                                            <img id="arrowImg-{{meter.groupId}}" class="right"
                                                                src="assets/icons/expand_more_gray_24dp.svg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="right">
                                                <div class="right d-flex mr-3">
                                                    
                                                    <button class="btn-close my-auto"
                                                        (click)="removeFromSelected(meter.groupId)"></button>
                                                    
                                                </div>
                                            </div>
                                        </summary>
                                    
                                        <div class="dual-container ordergroup-meters small-text"
                                            *ngFor="let groupMeter of meter.workorders">
                                            <div class="left d-flex">
                                                <div class="left svg-inside mr-1" [innerHtml]="groupMeter.icon | safe">
                                                </div>
                                                <div class="right">
                                                    <span *ngIf="groupMeter.old_device_id">{{'basic.meter' | translate }}
                                                        {{groupMeter.old_device_id}}</span>
                                                    <span class="red-text" *ngIf="!groupMeter.old_device_id">{{'basic.noID'
                                                        | translate }}</span>
                                                </div>
                                            </div>
                                            <div class="right d-flex mr-3">
                                                <button class="btn-close my-auto"
                                                    (click)="removeFromSelectedGroup(meter.id, groupMeter.id)"></button>
                                            </div>
                                        </div>
                                    </details>
                                
                                </div>
                            </div>
                        </div>
                        <div class="btn-stuff px-3 pb-3">
                            <hr class="margin-hr">
                            <!-- Button container -->
                            <div class="align-items-center d-flex">
                                <div class="break-word">{{'basic.installTime' | translate }}</div>
                                <div *ngIf="!selectedSpinner" class="ms-1 blue-text">{{selectedTime}}</div>
                            </div>
                            <load-spinner *ngIf="selectedSpinner" class="small-spin"></load-spinner>
                            <div class="w-100">
                                <div class="w-100" *ngIf="!selectSlotMode && !selectedSpinner">
                                    <button class="btn btn-secondary btn-sm mb-1 w-100" (click)="drawMarkersToSelection()"><img
                                            src="assets/icons/edit_gray_24dp.svg"
                                            class="mr-1 height-16">{{'planner.weeklyPlanningMap.drawSelection' | translate}}
                                    </button>
                                    <button class="btn btn-primary btn-sm mb-1 w-100" (click)="massAddMeters()"> + {{'calendar.addToCalendar' | translate}}</button>
                                    <button class="btn btn-primary btn-sm mb-1 w-100" (click)="addToOneSlot()">
                                        + {{'calendar.addToSlot' | translate }}</button>
                                    <button class="btn btn-primary btn-sm w-100" (click)="addToWorkList()">+ {{'planner.weeklyPlanningMap.addToWorklist' | translate}}</button>
                                </div>
                                <div class="w-100 text-center" *ngIf="selectSlotMode && !selectedSpinner">
                                    <span class="gray-text">{{'calendar.selectSlotAdvice' | translate }}</span>
                                    <button class="btn btn-secondary w-100 mb-1"
                                        (click)="activateSlotSelect(false, false)">{{'basic.cancel' | translate }}</button>
                                </div>
                            
                            </div>
                        
                        </div>
                </div>
                <div id="mapmap" class="map-container structure-child"></div>
            </div>
        
            <div class="bottom-container" [ngClass]="{'w-60': mapMode === 1}">
                <div class="row week-picker" *ngIf="!tableSpinner">
                    <div class="col-4">
                        <div class="gray-text">{{'calendar.maxSlotcCapacity' | translate }}
                            : {{calendarData.slot_max_default}}%
                        </div>
                        <div class="gray-text">* {{'calendar.differingMaxCapacity' | translate }}</div>
                    </div>
                    <div class="col-4 mt-2">
                        <app-week-picker (weekChange)="moveWeek($event)">
                        </app-week-picker>
                    </div>
                    <div class="col-4 d-flex justify-content-between">
                            <div class="relative">
                                <button class="btn btn-secondary btn-lg" (click)="showWorkList = !showWorkList">
                                    <img src="/assets/icons/list_alt_gray_24dp.svg">
                                    <span>{{'planner.weeklyPlanningMap.worklist' | translate}} ({{workList.length}})</span>
                                </button>
                                <div class="work-list" *ngIf="showWorkList">
                                    <div>{{'planner.weeklyPlanningMap.worklistDescription' | translate}}</div>
                                    <div *ngFor="let meter of workList" class="d-flex">
                                        <input type="checkbox" [(ngModel)]="meter.checked">
                                        <div class="d-flex">
                                            <div *ngIf="meter.icon" class="left svg-inside d-flex align-items-center" [innerHtml]="meter.icon | safe"></div>
                                            <div class="right ms-1">{{meter.old_device_id}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf="workList.length === 0" class="red-text">{{'planner.weeklyPlanningMap.noWorkordersWorklist' | translate}}</div>
                                    <button *ngIf="workList.length > 0" type="button" class="btn btn-danger" (click)="deleteFromWorkList()">{{'planner.weeklyPlanningMap.removeSelectedWorkorders' | translate}}</button>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-secondary calendar-btn" (click)="editCalendar()"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'calendar.editCalendar' | translate }}</button>
                            </div>
                        <!--<button class="btn btn-secondary calendar-btn"><img src="assets/icons/add_gray_24dp.svg" class="btn-icon">{{'calendar.newCalendar' | translate }}</button>-->
                    </div>
                </div>
                <load-spinner *ngIf="tableSpinner" class="spinner-table"></load-spinner>
            
                <div class="calendar-container">
                    <table class="table table-bordered text-center" *ngIf="!tableSpinner">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th *ngFor="let day of calendarDays; let i = index" scope="col">{{ getDayName(day) }} {{
                                    day.getDate() }}.{{ day.getMonth() + 1 }}
                                    <img *ngIf="!isDayLocked(i)" class="right lock-icon"
                                        src="assets/icons/lock_open_gray_24dp.svg" role="button" (click)="lockDay(i)">
                                    <img *ngIf="isDayLocked(i)" class="right day-locked lock-icon"
                                        src="assets/icons/lock_black_24dp.svg" role="button" (click)="unlockDay(i)">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of slotRows; let i = index">
                                <th>{{'basic.slot' | translate}} {{i + 1}}</th>
                                <td *ngFor="let slot of row; let index = index" class="relative" [style.z-index]="90 - i"
                                    [ngClass]="{'slot-disabled': slot.disabled}">
                                    <div class="slot-container" [style.background]="slot.color"
                                        [style.cursor]="selectSlotMode ? 'pointer' : null"
                                        (mouseenter)="selectSlotMode ? toggleFill(slot, index) : null"
                                        (click)="selectSlot(slot.id, i)">
                                        <div class="mt-1 w-100 d-flex justify-content-center">
                                            <div class="left text-small">
                                                {{getTime(slot.starttime, slot.endtime)}}
                                            </div>
                                            <div *ngIf="!slot.disabled && getTime(slot.starttime, slot.endtime)"
                                                class="right ml-1">
                                                <img *ngIf="!slot.locked" class="right lock-icon"
                                                    src="assets/icons/lock_open_gray_24dp.svg" role="button"
                                                    (click)="lockSlot(slot.id, i, index)">
                                                <img *ngIf="slot.locked" class="right day-locked lock-icon"
                                                    src="assets/icons/lock_black_24dp.svg" role="button"
                                                    (click)="unlockSlot(slot.id, i, index)">
                                            </div>
                                        </div>
                                        <div class="d-table h-100 w-100" style="min-height: 30px;">
                                            <div class="slot-stuff" *ngIf="getTime(slot.starttime, slot.endtime)">
                                                <div class="d-inline-block wrap-anywhere" *ngIf="slot.disabled">
                                                    ({{slot.disabled_reason}})
                                                </div>
                                                <div class="d-inline-block"
                                                    *ngIf="!slot.disabled && bypassMode && !selectSlotMode">
                                                    <input type="checkbox"> {{'calendar.bypass' | translate }}
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center"
                                                    *ngIf="!slot.disabled && !bypassMode && selectSlotMode">
                                                    <img height="30px" src="/assets/icons/add_gray_24dp.svg">
                                                    <span *ngIf="slot.startHere === true"
                                                        class="start-here">{{'planner.weeklyPlanningMap.startHere' |
                                                        translate}}</span>
                                                </div>
                                            
                                                <details #detailsRef
                                                    *ngIf="!slot.disabled && !bypassMode && !selectSlotMode"
                                                    [attr.open]="openedSlotId === slot.id ? '' : null"
                                                    id="{{'slot-' + slot.id}}" [style.background]="slot.color"
                                                    [style.cursor]="!selectSlotMode ? 'pointer' : null" class="slot-open">
                                                    <summary class="slot-items">
                                                        <div *ngFor="let marker of slot.counts" class="slot-marker">
                                                            <div class="svg-inside left" [innerHtml]="marker.icon | safe">
                                                            </div>
                                                            <p class="right">{{marker.count}}</p>
                                                        </div>
                                                    </summary>
                                                    <div class="slot-details">
                                                        <div class="mt-3">{{getTime(slot.starttime, slot.endtime)}}</div>
                                                        <div class="progress-bars mt-2"
                                                            [style.background-color]="slot.color">
                                                            <div class="dual-container mt-1">
                                                                <div class="ml-1 left d-flex">
                                                                    <img
                                                                        src="/assets/icons/calendar_available_black_24dp.svg">
                                                                    <span *ngIf="slot.notFree"
                                                                        class="small">{{slot.notFree}}%</span>
                                                                    <span *ngIf="!slot.notFree" class="small">0%</span>
                                                                </div>
                                                                <div class="progress progress-primary right">
                                                                    <div class="progress-bar" role="progressbar"
                                                                        [ngStyle]="setProgress(slot.notFree)"
                                                                        aria-valuenow="0" aria-valuemin="0"
                                                                        aria-valuemax="100">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="dual-container mt-1 mb-1">
                                                                <div class="ml-1 left d-flex">
                                                                    <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                                    <span *ngIf="slot.freeAccess"
                                                                        class="small">{{slot.freeAccess}}%</span>
                                                                    <span *ngIf="!slot.freeAccess" class="small">0%</span>
                                                                </div>
                                                                <div class="progress progress-primary right">
                                                                    <div class="progress-bar" role="progressbar"
                                                                        [ngStyle]="setProgress(slot.freeAccess)"
                                                                        aria-valuenow="0" aria-valuemin="0"
                                                                        aria-valuemax="100">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="spacer"></div>
                                                        <div class="dual-container justify-content-center mt-1 mb-1">
                                                            <span class="pill pill-1"
                                                                [ngClass]="slot.slot_max != null && calendarData.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'">
                                                                {{slot.slot_max != null ? slot.slot_max :
                                                                calendarData.slot_max_default}}%
                                                            </span>
                                                        </div>
                                                        <div class="mt-2 mb-2 break-word">
                                                            {{'basic.installTime' | translate }}
                                                            <br>
                                                            <span class="blue-text">{{getSlotTime(slot.workorders)}}</span>
                                                        </div>
                                                        <button type="button" class="btn btn-danger btn-sm text-small mx-1"
                                                            (click)="removeMarkers(slot)">{{'planner.weeklyPlanningMap.removeSelected'
                                                            | translate}}</button>
                                                        <div class="meter-details" *ngFor="let meter of slot.markers"
                                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                                            title="{{meter.time + ' min'}}">
                                                            <div class="form-check mx-1">
                                                                <input class="form-check-input margin-top-checkbox"
                                                                    type="checkbox" [(ngModel)]="meter.checked">
                                                                <label class="form-check-label d-flex text-small">
                                                                    <div *ngIf="meter.icon" class="svg-inside" [innerHtml]="meter.icon | safe">
                                                                    </div>
                                                                    <div class="align-self-center ms-1 break-word">
                                                                        <span
                                                                            [hidden]="!meter.old_device_id">{{'basic.meter'
                                                                            | translate }} {{meter.old_device_id}}</span>
                                                                        <span [hidden]="meter.old_device_id"
                                                                            class="red-text">{{'basic.noID' |
                                                                            translate}}</span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div (click)="closeDetailsWithId('slot-' + slot.id)">
                                                            <img src="/assets/icons/expand_less_black_24dp.svg">
                                                        </div>
                                                    </div>
                                                </details>
                                            </div>
                                        </div>
                                        <div *ngIf="!slot.disabled && !bypassMode && getTime(slot.starttime, slot.endtime)"
                                            class="progress-bars">
                                            <div *ngIf="slot.notFree || slot.freeAccess">
                                                <div class="dual-container mt-1">
                                                    <div class="ml-1 left d-flex">
                                                        <img src="/assets/icons/calendar_available_black_24dp.svg">
                                                        <span *ngIf="slot.notFree" class="small">{{slot.notFree}}%</span>
                                                        <span *ngIf="!slot.notFree" class="small">0%</span>
                                                    </div>
                                                    <div class="progress progress-primary right">
                                                        <div class="progress-bar" role="progressbar"
                                                            [ngStyle]="setProgress(slot.notFree)" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dual-container mt-1 mb-1">
                                                    <div class="ml-1 left d-flex">
                                                        <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                        <span *ngIf="slot.freeAccess"
                                                            class="small">{{slot.freeAccess}}%</span>
                                                        <span *ngIf="!slot.freeAccess" class="small">0%</span>
                                                    </div>
                                                    <div class="progress progress-primary right">
                                                        <div class="progress-bar" role="progressbar"
                                                            [ngStyle]="setProgress(slot.freeAccess)" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="spacer"></div>
                                                <div class="border border-gray editSingleSlot"
                                                    [hidden]="slotToEdit != slot.id">
                                                    <div class="display-flex justify-content-center mb-2 mx-2">
                                                        <div class="calendar-name mt-2">
                                                            {{'weeklyPlanningDetails.calendarSlotFill_1' | translate }}
                                                        </div>
                                                        <div class="mt-2">
                                                            <input type="text" min="0" max="{{defaultmax}}"
                                                                placeholder="{{calendarData.slot_max_default}}%"
                                                                class="form-control max-content "
                                                                [(ngModel)]="slot.slot_max">
                                                            <div class="red-text small-text"
                                                                [hidden]="slot.slot_max <= defaultmax">
                                                                {{'weeklyPlanningDetails.overdefault' | translate }}
                                                                {{defaultmax}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-center">
                                                        <button type="button" class="btn btn-primary mb-2"
                                                            (click)="saveSingleSlot(slot)">
                                                            <span class="small-text">{{'weeklyPlanningDetails.saveSlot' |
                                                                translate }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="dual-container justify-content-center mt-1 mb-1">
                                                    <span class="pill pill-2"
                                                        [ngClass]="slot.slot_max != null && calendarData.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'"
                                                        (click)="activateSingleSlot(slot.id, i, index)">
                                                        {{slot.slot_max != null ? slot.slot_max :
                                                        calendarData.slot_max_default}}%
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="progress-bars-empty" *ngIf="!slot.notFree && !slot.freeAccess">
                                                <div class="align-center justify-content-center gray-text">
                                                    <div class="dual-container mt-1">
                                                        <div class="ml-1 left d-flex">
                                                            <img src="/assets/icons/calendar_available_black_24dp.svg">
                                                            <span class="small">0%</span>
                                                        </div>
                                                        <div class="progress progress-primary right">
                                                            <div class="progress-bar" role="progressbar"
                                                                [ngStyle]="setProgress(0)" aria-valuenow="0"
                                                                aria-valuemin="0" aria-valuemax="100">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dual-container mt-1 mb-1">
                                                        <div class="ml-1 left d-flex">
                                                            <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                            <span class="small">0%</span>
                                                        </div>
                                                        <div class="progress progress-primary right">
                                                            <div class="progress-bar" role="progressbar"
                                                                [ngStyle]="setProgress(0)" aria-valuenow="0"
                                                                aria-valuemin="0" aria-valuemax="100">
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                
                                                    <div class="spacer"></div>
                                                    <div class="border border-gray editSingleSlot"
                                                        [hidden]="slotToEdit != slot.id">
                                                        <div class="display-flex justify-content-center mb-2 mx-2">
                                                            <div class="calendar-name mt-2">
                                                                {{'weeklyPlanningDetails.calendarSlotFill_1' | translate }}
                                                            </div>
                                                            <div class="mt-2">
                                                                <input type="text" min="0" max="{{defaultmax}}"
                                                                    placeholder="{{calendarData.slot_max_default}}%"
                                                                    class="form-control max-content "
                                                                    [(ngModel)]="slot.slot_max">
                                                                <div class="red-text small-text"
                                                                    [hidden]="slot.slot_max <= defaultmax">
                                                                    {{'weeklyPlanningDetails.overdefault' | translate }}
                                                                    {{defaultmax}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-center">
                                                            <button type="button" class="btn btn-primary mb-2"
                                                                (click)="saveSingleSlot(slot)">
                                                                <span class="small-text">{{'weeklyPlanningDetails.saveSlot'
                                                                    | translate }}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="dual-container justify-content-center mt-1 mb-1">
                                                    
                                                        <span class="pill pill-3"
                                                            [ngClass]="slot.slot_max != null && calendarData.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'"
                                                            (click)="activateSingleSlot(slot.id, i, index)">
                                                            {{slot.slot_max != null ? slot.slot_max :
                                                            calendarData.slot_max_default}}%
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
