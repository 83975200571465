import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { locale_en } from './../../../../../assets/ag-grid/locale.en';
import { locale_fi } from './../../../../../assets/ag-grid/locale.fi';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent, SelectionChangedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from 'ag-grid-community';
import {DatePipe} from "@angular/common";
import {AgGridAngular} from "ag-grid-angular";
import Swal from 'sweetalert2';
import { Status } from '@shared/models/status';
import { MSA } from '@shared/models/MSA';
import { Interrupt } from '@shared/models/interrupt';
import { Calendar } from '@shared/models/calendar';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()
  private getWorkOrdersSubscription: Subscription | null = null
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild('exportModal') exportModal!: ElementRef;


  locationNumber: string = ""
  searchResults: Array<any> = []
  loading: boolean = true
  // workOrders: Array<any> = []
  userLang
  selectedOrders: Array<any> = []
  statuses: Array<any> = []
  selectedStatus: Partial<Status> = {definition: undefined, id: 0}
  showRescheduleModal = false
  disabledbutton = false
  // Pagination
  currentPage: number = 1;
  itemsPerPage: number = 10
  @ViewChild('closeModalButton') modalClose;
  hasSearched: boolean = false
  notesForInstaller: any = "";
  additionalInformation: any  = "";
  allColumns: any;

  localeText = {};
  superuser: boolean = false
  columnDefs: ColDef[] = []
  defaultColDef: ColDef = {
    filter: true,
    floatingFilter: true,
    filterParams: {
      maxNumConditions: 1,
    },
    headerValueGetter: this.localizeHeader.bind(this)
  }
  autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  };

  workOrders: any[] = [];
  originalWorkOrders: any[] = [];
  private gridApi: any;

  // Default what everyone loads in pagination, not saved to memory if changed. Causes problems with filter and column save operations
  paginationPageSize: number = 10;
  paginationPageSizes: number[] = [10, 20, 50, 100]
  formTab: number = 0;
  selectedAccessType = {description: null, id: 0}
  selectedConfirmed = null
  accessTypes = [{id: 1, description: null}, {id: 2, description: null}, {id: 3, description: null}]
  inputTypes = [{id: 1, text: null}, {id: 2, text: null}, {id: 3, text: null}, {id: 4, text: null}, {id: 5, text: null}, {id: 6, text: null}]
  selectedInput = null
  interruptReason: string = ''
  interruptCode: Interrupt | null = null
  interruptOptions: Interrupt[] = []
  calendars: Calendar[] = []

  // MSA
  selectedMsa: any = {id: 0, name: ''}
  msas: MSA[] = []

  // Search workorders
  searchValue: string = ""
  searchTypes = [{text: '', id: 1, description: 'basic.locationNumber', databaseColumn: 'locationNumber'}, {text: '', id: 2, description: 'basic.address', databaseColumn: 'address'}, {text: '', id: 3, description: 'basic.oldDeviceID', databaseColumn: 'address'}]
  selectedSearchType = this.searchTypes[0]

  constructor(
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
    private plannersService: PlannerProjectService,
    private workOrderService: WorkOrderService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private calendarsService: CalendarsService
  ) { }

  /**
   * 13.12.2022
   * Added event listener to input for enter keystroke
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    let role = localStorage.getItem('ROLE')
    if (role == '1') this.superuser = true
    else this.superuser = false

    this.columnDefs  = [
      { field: "button",
        filter: false,
        checkboxSelection: true,
        resizable:false,
        maxWidth: 40,
        headerCheckboxSelection: this.superuser ? true : false,
        headerCheckboxSelectionFilteredOnly: this.superuser ? true : false,
      },
      { field: "report",
        filter: false,
        onCellClicked: (event: CellClickedEvent) => this.moveToReportPage(event.data.id),
        cellStyle: {cursor: 'pointer'},
        resizable: false,
        maxWidth: 40,
        cellRenderer: (params) => `<img class="card-icon dp24" src="assets/icons/search.svg">`
      },
      { field: "address" },
      { field: "zip" },
      { field: "city" },
      { field: "ordergroup_identifier" },
      { field: "identifier" },
      { field: "location_number" },
      { field: "access_type" },
      { field: "week" },
      { field: "msa_name" },
      { field: "status_definition" },
      { field: "interrupt_code" },
      { field: "interrupt_reason" },
      { field: "time_confirmed"},
      { field: "old_device_id" },
      { field: "worker_notes" },
      { field: "attendee" },
      { field: "reportsFinal" },
      { field: "sms_sent"},
      { field: "mail_sent"},
      {
        field: 'created',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any): string => {
          if (params.value) {
            return this.datePipe.transform(params.value, 'dd.MM.yyyy HH:mm') || '';
          }
          return '';
        },
        filterParams: {
          inRangeFloatingFilterDateFormat: 'MM/DD/YYYY', // Include it here
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.toLocaleDateString('en-US');
            if (dateAsString === null) return -1;
            const cellDate = new Date(dateAsString);
      
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
      {
        field: 'modified',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any): string => {
          if (params.value) {
            return this.datePipe.transform(params.value, 'dd.MM.yyyy HH:mm') || '';
          }
          return '';
        },
        filterParams: {
          inRangeFloatingFilterDateFormat: 'MM/DD/YYYY', // Include it here
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.toLocaleDateString('en-US');
            if (dateAsString === null) return -1;
            const cellDate = new Date(dateAsString);
      
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
      { field: "calendar_name" },
      { field: "notes"},
      {
        field: 'time_window_start',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any): string => {
          if (params.value) {
            return this.datePipe.transform(params.value, 'dd.MM.yyyy HH:mm') || '';
          }
          return '';
        },
        filterParams: {
          inRangeFloatingFilterDateFormat: 'MM/DD/YYYY', // Include it here
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.toLocaleDateString('en-US');
            if (dateAsString === null) return -1;
            const cellDate = new Date(dateAsString);
      
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
  
    ];

    this.userLang = localStorage.getItem('userlanguage');
    if(this.userLang == 'fi') {
      this.localeText = locale_fi;
    } else {
      this.localeText = locale_en;
    }
    this.translateKeys()
    let input = <HTMLInputElement>document.getElementById('search-input')
    input.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') this.searchReportsApi()
    })

    const queryParamSnapshot = this.activatedRoute.snapshot.queryParamMap.get('workorders');
    let workorders: number[] | null = null
    if (queryParamSnapshot) {
      // Get numbers from queryParams to array of numbers
      workorders = queryParamSnapshot.split(',').map(num => parseInt(num, 10));
      // Filter out NaN values, if by some chance there has been strings or something else mixed in
      workorders = workorders.filter(num => !isNaN(num));
    }
    this.getInterruptOptions()
    this.getCalendars(workorders)
    this.getStatuses()
    this.getMsas()
  }

  ngAfterViewInit(): void {
    // Ensure the element is available
    if (this.exportModal) {
      this.exportModal.nativeElement.addEventListener('shown.bs.modal', () => {
        this.checkHeaders();
      });
    }
  }

  translateKeys() {
    this.accessTypes.forEach(element => {
      this.translateService.get('accessTypes.' + element.id).subscribe(
        key => element.description = key
      )
    });
    this.translateService.get('basic.status').subscribe(
      key => this.inputTypes[0].text = key
    )
    this.translateService.get('planner.reports.notesForInstaller').subscribe(
      key => this.inputTypes[1].text = key
    )
    this.translateService.get('planner.reports.additionalInformation').subscribe(
      key => this.inputTypes[2].text = key
    )
    this.translateService.get('planner.reports.accessType').subscribe(
      key => this.inputTypes[3].text = key
    )
    this.translateService.get('planner.reports.msa').subscribe(
      key => this.inputTypes[5].text = key
    )
    this.translateService.get('planner.reports.confirmed').subscribe(
      key => this.inputTypes[4].text = key
    )
    this.translateService.get('planner.reports.selectInput').subscribe(
      key => this.selectedInput = key
    )
    this.translateService.get('planner.reports.selectStatus').subscribe(
      key => this.selectedStatus.definition = key
    )
    this.translateService.get('planner.reports.selectAccessType').subscribe(
      key => this.selectedAccessType.description = key
    )
    this.translateService.get('planner.reports.selectMsa').subscribe(
      key => this.selectedMsa.name = key
    )

    this.searchTypes.forEach(element => {
      this.translateService.get(element.description).subscribe(
        key => {
          element.text = key
        }
      )
    })
  }

  getCalendars(workorders: number[] | null) {
    this.calendarsService.getCalendars().subscribe(
      data => {
        this.calendars = data
        this.getWorkOrders(workorders)
      },
      error => {
        this.toastService.sendToast(false, 'Error fetching calendars')
        this.getWorkOrders(workorders)
      }
    )

  }

  checkHeaders() {
    this.allColumns = this.agGrid.api.getAllDisplayedColumns()
      .map(col => {
          return {
            colId: col.getColId(),
            headerName: this.localizeHeader({ colDef: { field: col.getColId() } }),
            selected: true
          };
        })
      .filter(col => col.colId !== 'button' && col.colId !== 'report');
  }

  // Custom cleanup that destorys observables, preventing memory leaks.
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
    if (this.getWorkOrdersSubscription) {
      this.getWorkOrdersSubscription.unsubscribe();
    }
  }

  gridReady(params: GridReadyEvent) {
    this.gridApi = params.api

    let state = localStorage.getItem('persistent_gridState')
    if (state) {
      const savedState = JSON.parse(state)
      if (savedState.columnState) {
        this.gridApi.applyColumnState({
          state: savedState.columnState,
          applyOrder: true,
        });
      }
      if (savedState.filterState) {
        this.gridApi.setFilterModel(savedState.filterState);
      }
    }

    // @ts-ignore
    this.allColumns = this.agGrid.api.getColumns().map(col => {
      return {
        colId: col.getColId(),
        headerName: this.localizeHeader({ colDef: { field: col.getColId() } }),
        selected: true
      };
    });

    // Remove first two columns (checkbox and link to details page)
    this.checkHeaders();

  }


  onGridStateChanged() {
    const columnState = this.agGrid.api.getColumnState();
    const filterState = this.agGrid.api.getFilterModel();
    const gridState = {
      columnState,
      filterState
    };
    localStorage.setItem('persistent_gridState', JSON.stringify(gridState));
  }

  getWorkOrders(workordersById: number[] | null) {
    this.loading = true;
    this.workOrders = [];
    this.originalWorkOrders = [];

    if (workordersById !== null) {
      this.getWorkOrdersSubscription =  this.workOrderService.getWorkordersByManyIds(workordersById)
      .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          orders => {
            orders.forEach(order => this.processOrder(order));
            this.loading = false;
          },
          error => this.handleError(error)
        );
    } else {
      this.getWorkOrdersSubscription = this.workOrderService.getWorkOrdersWOM('ALL')
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          orders => {
            orders.forEach(order => this.processOrder(order));
            this.loading = false;
          },
          error => this.handleError(error)
        );
    }
  }

  processOrder(order: any) {
    let definition;
    let defjson;
    if (order.status_definition) defjson = JSON.parse(order.status_definition) || {};
    if (defjson) {
      if (this.userLang) {
        definition = defjson[this.userLang] || defjson['en'] || undefined;
      } else {
        definition = defjson['en'] || undefined
      }
    }
    order.status_definition = definition;
    if (order.firstname && order.lastname) order.attendee = order.firstname + " " + order.lastname

    if (order.interrupt_code) {
      let interrupt = this.interruptOptions.find(code => code.interrupt_code === order.interrupt_code)
      if (interrupt) order.interrupt_code = interrupt.definition
    }


    order.week = this.datePipe.transform(order.time_window_start, 'w') || '';
    if (order.time_window_start) {
      const dateString = order.time_window_start.endsWith('Z')
        ? order.time_window_start
        : order.time_window_start + 'Z';
      order.time_window_start = new Date(dateString);
    }
    if (order.access_type !== 0 && order.access_type) order.access_type = this.translateService.instant("accessTypes." + order.access_type);
    else order.access_type = this.translateService.instant('call-service.accessTypeNotFound')

    if (order.created) {
      const dateString = order.created.endsWith('Z')
        ? order.created
        : order.created + 'Z';
      order.created = new Date(dateString);
    }
    if (order.modified) {
      const dateString = order.modified.endsWith('Z')
        ? order.modified
        : order.modified + 'Z';
      order.modified = new Date(dateString);
    }
      if (order.report_time) {
        const dateString = order.report_time.endsWith('Z')
        ? order.report_time
        : order.report_time + 'Z';
      order.report_time = new Date(dateString);

    }
    if (order.reports && order.reports.length > 0) {
      order.reportsFinal = []
      order.reports.forEach(element => {
        order.reportsFinal.push(`${element.firstname} ${element.lastname} - ${this.datePipe.transform(element.created, 'dd.MM.yyyy HH:mm')}`)
      });
    }
    if (order.sms_sent === 0) order.sms_sent = this.translateService.instant('basic.no')
    if (order.sms_sent === 1) order.sms_sent = this.translateService.instant('basic.yes')
    if (order.mail_sent === 0) order.mail_sent = this.translateService.instant('basic.no')
    if (order.mail_sent === 1) order.mail_sent = this.translateService.instant('basic.yes')
    if (order.time_confirmed === 0) order.time_confirmed = this.translateService.instant('basic.no')
    if (order.time_confirmed === 1) order.time_confirmed = this.translateService.instant('basic.yes')
    if (order.calendar_id) {
      let calendar = this.calendars.find(c => c.id === order.calendar_id)
      if (calendar) order.calendar_name = calendar.name
    }


    this.workOrders.push(order);
    this.originalWorkOrders.push(order);
  }

  handleError(error: any) {
    this.loading = false;
    // Handle the error, such as logging it and notifying the user
    this.toastService.sendToast(false, 'Error fetching work orders: ' + error.message);
  }

  localizeHeader(params){
    let headerIdentifier = params.colDef.field;

    if(headerIdentifier == 'button' || headerIdentifier == 'report') {
      return "";
    } else if(headerIdentifier == 'address') {
      return this.translateService.instant("basic.address");
    } else if(headerIdentifier == 'ordergroup_identifier') {
      return this.translateService.instant("basic.ordergroup");
    } else if(headerIdentifier == 'identifier') {
      return this.translateService.instant("planner.reports.workorderIdentifier");
    } else if(headerIdentifier == 'access_type') {
      return this.translateService.instant("basic.accessType");
    } else if(headerIdentifier == 'week') {
      return this.translateService.instant("basic.week");
    } else if(headerIdentifier == 'msa_name') {
      return this.translateService.instant("planner.reports.msa");
    } else if(headerIdentifier == 'interrupt_code') {
      return this.translateService.instant("planner.reports.interruptCode");
    } else  if(headerIdentifier == 'interrupt_reason') {
      return this.translateService.instant("planner.reports.interruptReason");
    } else if(headerIdentifier == 'status_definition') {
      return this.translateService.instant("planner.reports.workorderStatus");
    } else if(headerIdentifier == 'location_number') {
      return this.translateService.instant("planner.reports.locationNumber");
    } else if(headerIdentifier === 'time_confirmed') {
      return this.translateService.instant('basic.confirmed')
    } else if (headerIdentifier === 'old_device_id') return this.translateService.instant('basic.oldDeviceID')
    else if (headerIdentifier === 'created') return this.translateService.instant('basic.created')
    else if (headerIdentifier === 'modified') return this.translateService.instant('basic.modified')
    else if (headerIdentifier === 'worker_notes') return this.translateService.instant('basic.workerNotes')
    else if (headerIdentifier === 'attendee') return this.translateService.instant('basic.reported')
    else if (headerIdentifier === 'city') return this.translateService.instant('planner.newProject.columns.city')
    else if (headerIdentifier === 'zip') return this.translateService.instant('planner.newProject.columns.zip')
    else if (headerIdentifier === 'reportsFinal') return this.translateService.instant('basic.reportInfo')
    else if (headerIdentifier === 'sms_sent') return this.translateService.instant('basic.smsSent')
    else if (headerIdentifier === 'mail_sent') return this.translateService.instant('basic.mailSent')
    else if (headerIdentifier === 'calendar_name') return this.translateService.instant('weeklyPlanningDetails.calendarName')
    else if (headerIdentifier === 'notes') return this.translateService.instant('call-service.dashboard.additionalInfo')
    else if (headerIdentifier === 'time_window_start') return this.translateService.instant('basic.startTime')
    else return this.translateService.instant(headerIdentifier)
  }

  /**
   * Activate spinner.
   * Search for customer with input's keyword.
   * Set results to interface for showing.
   * Disable spinner after api-call.
   *
   * @edit 13.12.2022
   * Added search field to api-call. Check for search word and search field before making api-call.
   * @author Jesse Lindholm
   * 
   * @edit 3.9.2024
   * Revamped whole function to perform search with filter and not with api-call
   */
  /*
  searchReports() {
    this.workOrders = JSON.parse(JSON.stringify(this.originalWorkOrders))
    const searchFields = {
      1: 'location_number',
      2: 'address',
      3: 'old_device_id'
    };

    const searchField = searchFields[this.selectedSearchType.id];

    let searchValues: string[] = [];
    if (this.searchValue.includes(',')) searchValues = this.searchValue.split(',');
    else searchValues = [this.searchValue];

    if (searchField) {
      let searchedValues: any[] = [];
      searchValues.forEach(element => {
        searchedValues = [
          ...searchedValues,
          ...this.workOrders.filter(wo =>
            wo[searchField].toLowerCase().includes(element.toLowerCase().trim())
          )
        ];
      });

      const uniqueArray = searchedValues.filter(
        (obj, index, self) => index === self.findIndex(o => o.id === obj.id)
      );

      this.workOrders = uniqueArray;
    }
  }
  */

  searchReportsApi() {
    // Cancel the previous API call if it's still ongoing
    if (this.getWorkOrdersSubscription) {
      this.getWorkOrdersSubscription.unsubscribe();
      this.getWorkOrdersSubscription = null;
    }

    let searchType = this.selectedSearchType
    if (searchType === null) {
      Swal.fire(
        this.translateService.instant('planners.reports.wrongInput'),
        this.translateService.instant('planners.reports.searchWorkordersError'),
        'error')
    }
      this.loading = true

      this.getWorkOrdersSubscription = this.workOrderService.searchWorkorders(
        this.searchValue, 'reportsearch'
      ).pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: data => {
            this.workOrders = data
            this.loading = false
          },
          // Error handling, show error message in HTML after stopping spinner
          error: () => {
            this.toastService.sendToast(false, this.translateService.instant('planners.reports.noWorkordersFound'))
            this.loading = false
          }
        })

  }

  resetSearch() {
    this.searchValue = ""
    this.gridApi.setQuickFilter(null)
    this.gridApi.setFilterModel(null)
    this.workOrders = JSON.parse(JSON.stringify(this.originalWorkOrders))
  }

  moveToReportPage(workorderId) {
    this.router.navigate(['planner/report-workorder-detail/' + workorderId])
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
  }

  formatDateToEng(dateString: string, format: string, locale: string): string {
    const [day, month, year] = dateString.split('.');
    return `${year}-${month}-${day}`;
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selectedData = this.gridApi.getSelectedRows();
    this.selectedOrders = selectedData
  }

  getStatuses() {
    this.plannersService.getStatuses()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.statuses = data

          for (let i = 0; i < this.statuses.length; i++) {
            let definition
            let defjson = JSON.parse(this.statuses[i].definition) || {}
            if(this.userLang) {
              definition = defjson[this.userLang] ||
                defjson['en'] ||
                undefined;
            } else {
              definition = defjson['en'] || undefined;
            }
            this.statuses[i].definition = definition
            // this.allStates.push({id: this.teamStates[i].id, definition: definition})
          }

          // TODO hotfix to show only first status
          // remove this when status behavior is thought about
          // this.statuses = [this.statuses[0]]
        }
      )
  }


  doScheduling(): Promise<boolean> {
    this.disabledbutton = true;
    if (this.selectedOrders.length === 0) {
        this.toastService.sendToast(false, this.translateService.instant('planner.reports.noWorkordersSelected'));
        this.disabledbutton = false;
        return Promise.resolve(false);
    } else if (this.selectedStatus && this.selectedStatus.id === 0 && this.selectedAccessType.id === 0 && this.additionalInformation === '' && this.notesForInstaller === '' && this.selectedConfirmed == null && this.selectedMsa.id === 0) {
      this.toastService.sendToast(false, this.translateService.instant('planner.reports.noInput'))
      this.disabledbutton = false
      return Promise.resolve(false)
    } else if (this.selectedStatus.state === 1 || this.selectedMsa.id !== 0) {
        return Swal.fire({
            title: this.translateService.instant('planner.reports.confirmStatus'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                this.sendRestatusWorkorders();
                return true;
            } else {
                this.disabledbutton = false;
                return false;
            }
        });
    } else {
        this.sendRestatusWorkorders();
        return Promise.resolve(true);
    }
}


  sendRestatusWorkorders() {
    this.workOrderService.restatusWorkorders(this.selectedStatus.id, this.selectedOrders, this.notesForInstaller, this.additionalInformation, this.selectedAccessType.id, this.interruptReason, this.interruptCode, this.selectedConfirmed, this.selectedMsa.id)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          data => {
            this.workOrders = []
            this.selectedOrders = []
            this.loading = true
            this.modalClose.nativeElement.click();
            this.disabledbutton = false
            this.clearWorkorderData()
            this.toastService.sendToast(true, this.translateService.instant('basic.success'))
            this.ngOnInit()
          },
          error => {
            this.toastService.sendToast(false, this.translateService.instant('basic.failed') + error.toString())
          }
        )
  }


  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(event.target.value);
  }


  exportToExcel(): void {
    const selectedRows = this.agGrid.api.getSelectedRows();
    const selectedColumns = this.allColumns.filter(col => col.selected).map(col => col.colId);
  
    const gridData = selectedRows.map(row => {
      const filteredRow = {};
      selectedColumns.forEach(colId => {
        filteredRow[colId] = row[colId];
      });
      return filteredRow;
    });
  
    const headers = selectedColumns.map(colId => this.localizeHeader({ colDef: { field: colId } }));
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    worksheet.columns = selectedColumns.map((colId, index) => {
      return {
        header: headers[index],
        key: colId,
        width: 20
      };
    });
  
    gridData.forEach(dataRow => {
      worksheet.addRow(dataRow);
    });
  
    // Optional: Apply formatting to header row
    worksheet.getRow(1).font = { bold: true };
  
    // Adjust column widths
    worksheet.columns.forEach(column => {
      if (column && column.eachCell) {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 20 ? 20 : maxLength;
      }
    });
  
    workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer]), 'work-order-data.xlsx');
    }).catch(err => {
      console.error('Error writing excel export', err);
    });
  }



  private getGridDataInDisplayOrder(visibleColumns: string[]): any[] {
    const displayedData: Array<any> = [];
    this.agGrid.api.forEachNodeAfterFilterAndSort(node => {
      const filteredData = {};
      visibleColumns.forEach(colId => {
        filteredData[colId] = node.data[colId];
      });
      displayedData.push(filteredData);
    });
    return displayedData;
  }

  clearWorkorderData() {
    this.resetAccessType()
    this.resetStatus()
    this.resetMsa()
    this.additionalInformation = ''
    this.notesForInstaller = ''
    this.selectedInput = this.translateService.instant('planner.reports.selectInput')
    this.formTab = 0
    this.interruptReason = ''
    this.interruptCode = null
    this.selectedConfirmed = null
  }

  changeInputType(inputType) {
    this.formTab = inputType.id
    this.selectedInput = inputType.text
  }

  resetStatus() {
    this.selectedStatus = {definition: this.translateService.instant('planner.reports.selectStatus'), id: 0}
  }

  resetAccessType() {
    this.selectedAccessType = {description: this.translateService.instant('planner.reports.selectAccessType'), id: 0}
  }

  getMsas() {
    this.workOrderService.getMsas().subscribe(
      data => this.msas = data
    )
  }

  resetMsa() {
    this.selectedMsa = {id: 0, name: this.translateService.instant('planner.reports.selectMsa')}
  }


  setMsaToNull() {
    this.selectedMsa = {id: null, name: this.translateService.instant('basic.noMsa')}
  }

  getInterruptOptions() {
    this.plannersService.getInterruptOptions(null, null)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (interrupts: Interrupt[]) => {
          if (interrupts.length > 0) {
            interrupts.forEach(interrupt => {
                let interrupt_definition
                let interruptdef = interrupt.definition || {}
                if(this.userLang) {
                  interrupt_definition = interruptdef[this.userLang] ||
                    interruptdef['en'] ||
                    undefined;
                } else {
                  interrupt_definition = interruptdef['en'] || undefined;
                }

              interrupt.definition = interrupt.interrupt_code + ' - ' + interrupt_definition
            });

            let otherInterrupt: any = {definition: this.translateService.instant('basic.other'), interrupt_code: '00'}
            interrupts = [...interrupts, otherInterrupt]
            this.interruptOptions = interrupts
          } else this.interruptOptions = [{definition: this.translateService.instant('basic.other'), interrupt_code: '00'} as any]
        },
        (error) => {
          console.error('Error fetching interrupt options', error);
        }

      )

  }

}

