import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';


@Component({
  selector: 'app-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss']
})
export class WeekPickerComponent implements OnInit, OnChanges {

  constructor(
    private datePipe: DatePipe
  ) { }

  weekNumber: string | null = null
  lastDay: Date | null = null
  firstDay: Date | null = null

  @Input() changeWeekPickerDate: Date | null = null

  @Output() weekChange = new EventEmitter();


  /**
   * Init component:
   * Setting date for first day
   * Setting date for last day
   * Setting week number
   */
  ngOnInit(): void {
    let firstDayOfWeek = localStorage.getItem('week')
    if (firstDayOfWeek) {
      let date = new Date(firstDayOfWeek)
      date.setHours(0, 0, 0, 0)
      this.moveToSpecificDate(date)
    } else this.moveToToday()

    //this.moveToToday()
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    if (changes['changeWeekPickerDate'] && changes['changeWeekPickerDate'].currentValue != null) {
      let date = new Date(changes['changeWeekPickerDate'].currentValue)
      this.moveToSpecificDate(date)
    }
  }

  /**
   * Move backwards or forwards in weeks. Use new Date object to create a new date, because Angular Datepipe does not rerender
   * on changes to same Date object.
   * @param forward boolean that represents whetever to go forward or backwards in weeks
   */
  moveWeek(forward) {
    // Null check
    if (this.firstDay && this.lastDay) {
      let newFirst = new Date(this.firstDay)
      let newLast = new Date(this.lastDay)
      // Going forward
      if (forward) {
        newFirst.setDate(this.firstDay.getDate() + 7)
        newLast.setDate(this.lastDay.getDate() + 7)
        this.firstDay = newFirst
        this.lastDay = newLast
        this.weekChange.emit(this.firstDay)
      }
      // Going backwards
      else {
        newFirst.setDate(this.firstDay.getDate() - 7)
        newLast.setDate(this.lastDay.getDate() - 7)
        this.firstDay = newFirst
        this.lastDay = newLast
        this.weekChange.emit(this.firstDay)
      }
      this.weekNumber = this.datePipe.transform(this.firstDay, 'w')
      localStorage.setItem('week', this.firstDay.toISOString())
    }
  }

  moveToToday() {
    let date = new Date();
    let day = date.getDay(),

    diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    this.firstDay = new Date(date.setDate(diff));
    this.lastDay = new Date()
    this.lastDay.setDate(this.firstDay.getDate() + 6)
    this.weekNumber = this.datePipe.transform(this.firstDay, 'w')

    this.weekChange.emit(this.firstDay)
    localStorage.setItem('week', this.firstDay.toISOString())
  }

  moveToSpecificDate(dateToMove) {
    // Validate the date input
    if (dateToMove instanceof Date && !isNaN(dateToMove.getTime())) {
      // Create a new date instance to avoid mutating the original date
      let date = new Date(dateToMove);
  
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      let day = date.getDay() || 7; // Adjust Sunday from 0 to 7 for calculation
  
      // Calculate the first day of the week (Monday)
      let firstDay = new Date(date);
      firstDay.setDate(date.getDate() - day + 1);
  
      // Calculate the last day of the week (Sunday)
      let lastDay = new Date(firstDay);
      lastDay.setDate(firstDay.getDate() + 6);
  
      // Update class properties
      this.firstDay = firstDay;
      this.lastDay = lastDay;
  
      // Get the week number using datePipe
      this.weekNumber = this.datePipe.transform(this.firstDay, 'w');
      this.weekChange.emit(this.firstDay)
    } else {
      // Handle invalid date input
      console.error('Invalid date provided to moveToSpecificDate:', dateToMove);
    }
  }

}
