<div class="page-container">
    <div class="page-heading">
        <h3>
            <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
           <span class="page-title">{{'sideNavigation.titleWeeklyPlanning' | translate}}</span>
        </h3>
     </div>
    <div class="page-content">
        <section class="section">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <div class="ms-3">
                            <load-spinner *ngIf="spinner"></load-spinner>
                            <div *ngIf="!spinner" >
                            <ul class="list-group teams-list" >
                                <li class="list-group-item border-start-0 border-end-0" *ngFor="let item of teams">
                                    <div class="row">
                                        <div class="col-3 d-flex align-items-center">
                                            <a (click)="moveToSingleTeamPage(item.teamId)" style="width: 100%;">
                                                <div class="single-item p-1">
                                                    <div class="fw-bold text-black">{{item.name}}</div>
                                                    <div class="text-item">
                                                        <div *ngIf="item.calendarsCount > 1">({{item.calendarsCount}} {{'calendar.calendars' | translate}})</div>
                                                        <div *ngIf="item.calendarsCount == 1">({{item.calendarsCount}} {{'calendar.calendar' | translate | lowercase}})</div>
                                                        <div *ngIf="!item.calendarsCount">({{'calendar.noCalendarsFound' | translate}})</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        
                                        <div class="col-4">
                                            <circle-progress
                                            [percent]="item.efficiency"
                                            [radius]="18"
                                            [outerStrokeWidth]="4"
                                            [innerStrokeWidth]="4"
                                            [space]="-4"
                                            [outerStrokeColor]="item.colorOuter"
                                            [innerStrokeColor]="item.colorInner"
                                            [animation]="true"
                                            [animationDuration]="300"
                                            [titleFontSize]="'13'"
                                            [title]="item.efficiencyValue"
                                            [showUnits]=false
                                            [titleColor]="item.colorOuter"
                                            [titleFontWeight]="'600'"
                                            ></circle-progress> 
                                            <span class="text-item">{{'teams.overallEfficiency' | translate}}</span>
                                        </div>
                                        <div class="col-3 text-item mt-3">
                                            <img src="assets/icons/groups_black_24dp.svg">
                                            <span class="position-fix-workers ms-3">{{item.workersCount}} {{'planner.weeklyPlanningMain.workers' | translate}}</span>
                                        </div>
                                        <div class="col-2 d-flex">
                                            <div class="align-self-center col-12">
                                                <button type="button" class="btn btn-sm btn-primary float-end" (click)="createCalendar(item.teamId)">{{'calendar.createCalendar' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                               </li>
                            </ul>
                            <div *ngIf="teams.length == 0" class="red-text">{{'workers.noWorkerteamsFound' | translate}}</div>
                            </div>
                            
                            <div class="mt-5">
                                <h4>{{'planner.weeklyPlanningMain.cleanupCalendars' | translate}}</h4>
                                <load-spinner *ngIf="spinner2"></load-spinner>
                                <div *ngIf="!spinner2" style="position: relative;">
                                    <table class="teams-list">
                                        <thead>
                                            <div class="d-flex gray-text">
                                                <div class="col-2">{{'planner.weeklyPlanningMain.cleanupCalendarName' | translate}}</div>
                                                <div class="col-3 d-flex justify-content-center">{{'planner.weeklyPlanningMain.calendarStartEndDates' | translate}}</div>
                                                <div class="col-2">{{'planner.weeklyPlanningMain.msaName' | translate}}</div>
                                                <div class="col-3">{{'planner.weeklyPlanningMain.assignToTeam' | translate}}</div>
                                                <div class="col-2">{{'planner.weeklyPlanningMain.openCalendar' | translate}}</div>
                                            </div>
                                        </thead>
                                        <tbody class="border-bottom border-top border-gray" *ngFor="let cleanupCalendar of cleanupCalendars">
                                            <div class="d-flex row py-2 align-items-center">
                                                <div class="col-2 fw-bold">{{cleanupCalendar.name}}</div>
                                                <div class="gray-text d-flex col-3 justify-content-center small-text">
                                                    <div>{{cleanupCalendar.startdate}} - {{cleanupCalendar.enddate}} ({{cleanupCalendar.totalWeeks}} {{'planner.weeklyPlanningMain.weeks' | translate}})</div>
                                                </div>
                                                <div class="col-2 small-text">{{cleanupCalendar.msaName}}</div>
                                                <div class="col-3">
                                                    <div ngbDropdown>
                                                      <button class="btn btn-sm btn-secondary mt-2 text-break" type="button" id="dropdownRangeButton" ngbDropdownToggle>
                                                        <span [hidden]="!cleanupCalendar.team" class="mt-2 black-text">
                                                          {{cleanupCalendar.team}}
                                                        </span>
                                                        <span [hidden]="cleanupCalendar.team" class="mt-2 black-text text-break">
                                                          {{'teams.noTeamAssigned' | translate}}
                                                        </span>
                                                      </button>
                                                      <div ngbDropdownMenu aria-labelledby="dropdownRangeButton">
                                                        <button *ngIf="teams.length > 0" ngbDropdownItem type="button" (click)="assignTeam(null, cleanupCalendar.id)">
                                                          {{'teams.noTeam' | translate}}
                                                        </button>
                                                        <div *ngFor="let team of teams">
                                                          <button ngbDropdownItem type="button" (click)="assignTeam(team.teamId, cleanupCalendar.id)">
                                                            {{team.name}}
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>  
                                                  </div>
                                                
                                                  <!-- Second Button Container -->
                                                  <div class="col-2">
                                                    <button type="button" class="btn btn-sm btn-primary mt-2" (click)="openCalendar(cleanupCalendar.id)">
                                                      {{'planner.weeklyPlanningMain.openCalendar' | translate}}
                                                    </button>
                                                  </div>
                                            </div>
                                        </tbody>
                                    </table>
                                    <div *ngIf="cleanupCalendars.length == 0" class="red-text">{{'planner.weeklyPlanningMain.noCleanup' | translate}}</div>
                                    <button class="btn btn-secondary py-0 mt-4" type="button" (click)="createCalendar(null)">
                                        <span class="fs-3">+</span>
                                        <span class="position-fix-plus ms-2 small-text">{{'planner.weeklyPlanningMain.createCleanup' | translate}}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
