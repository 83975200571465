import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { CallServiceService } from '@modules/call-service/services/call-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '@shared/models/customer';
import { InputType } from '@shared/models/inputType';
import { ProjectCallService } from '@shared/models/project-call-service';
import { ToastService } from '@shared/services/toast/toast.service';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { exportCSV } from '../../../planner/components/Utils/csvUtils';
import { mockData } from './mock-data';
import { ClipboardService } from 'ngx-clipboard';
import { Project } from '@shared/models/project';

@Component({
  selector: 'app-call-service-dashboard',
  templateUrl: './call-service-dashboard.component.html',
  styleUrls: ['./call-service-dashboard.component.scss']
})
export class CallServiceDashboardComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  tab: number = 1
  searchWord: string = ""
  searchResults: Array<Customer> = Array()
  listSpinner: boolean = false

  // Input types
  inputTypes: Array<InputType> = [
    { text: 'placeholder', value: 'name' },
    { text: 'placeholder', value: 'address' },
    { text: 'placeholder', value: 'location_number' },
    { text: 'placeholder', value: 'device_id' },
    { text: 'placeholder', value: 'phone' }
  ]
  selectedInputType: InputType = { text: 'placeholder', value: 'name' }


  // Projects
  selectedProjects: ProjectCallService[] = [];
  projects: ProjectCallService[] = []

  // Weeks
  selectedWeek?: { label: string, number: number };// Current week
  weeks: { label: string, number: number }[] = []; // List of weeks
  selectedWeekForMailingList?: { label: string, number: number };// Current week
  weeksMailingList: { label: string, number: number }[] = []; // List of weeks

  //mock calling list data
  mockDataList = mockData;
  allCallingListData: any[] = []; // Initialize with an empty array
  mandatoryCallingList: any[] = [];
  callingList: any[] = [];
  timePassedCallingList: any[] = [];

  // Filtering for calling lists
  projectsFilterGroup: any[] = [{name: null, id: 0}]
  selectedProjectFilter: any = this.projectsFilterGroup[0]

  msaFilterGroup: any[] = [{name: null, id: 0, project_id: 0}]
  selectedMSAFilter: any = this.msaFilterGroup[0]

  accessTypeFilterGroup: any = [{name: null, id: 0, projectIds: [0]}]
  selectedAccessTypeFilter: any = this.accessTypeFilterGroup[0]

  enduserTypes = [{name: null, id: undefined}, {name: null, id: 0}, {name: null, id: 2}]
  selectedEnduserTypeFilter: any = this.enduserTypes[0]

  // Sort calling log
  sortState: string = 'asc'
  newSortState: string = 'asc'

  // Filter by name
  filterName: string = ''


  constructor(
    private authService: AuthService,
    private callService: CallServiceService,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
    private calendarsService: CalendarsService,
    private route: ActivatedRoute,
    private clipboardService: ClipboardService
  ) { }

  /**
   * 13.12.2022
   * Added event listener to input for enter keystroke
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    
    this.getProjects();
    this.getWeeks();
    
    this.getWeeksForCallingList();
    let input = <HTMLInputElement>document.getElementById('search-input')
    input.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') this.searchCustomer()
    })
  

    this.translateService.get('basic.name').subscribe(
      name => {
        this.inputTypes[0].text = name
        this.inputTypes[1].text = this.translateService.instant('basic.address')
        this.inputTypes[2].text = this.translateService.instant('basic.meteringPointNumber')
        this.inputTypes[3].text = this.translateService.instant('basic.meterNumber'),
        this.inputTypes[4].text = this.translateService.instant('basic.phoneNumber')

        this.selectedInputType.text = this.translateService.instant('basic.name')

        this.enduserTypes[0].name = this.translateService.instant('call-service.enduserAll')
        this.enduserTypes[1].name = this.translateService.instant('personTypes.0')
        this.enduserTypes[2].name = this.translateService.instant('personTypes.2')

        this.msaFilterGroup[0].name = this.translateService.instant('call-service.allMsas')
        this.projectsFilterGroup[0].name = this.translateService.instant('call-service.allProjects')
        this.accessTypeFilterGroup[0].name = this.translateService.instant('call-service.allAccessTypes')
      }
    )
      
    this.route.queryParams.subscribe(params => {
      if (params.tab) {
        this.switchTab(Number(params.tab));
      } else {
        const storedTab = localStorage.getItem('selectedTab');
        if (storedTab) {
          this.tab = parseInt(storedTab, 10);
        } else {
          // If no tab index is stored, default to tab 1
          this.tab = 1;
        }
      }
    });
  }

  // Custom cleanup that destorys observables, preventing memory leaks.
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Switch tab between calling list and search customer. Only changes tab if new one is clicked.
   * @param number new tab number
   */
  switchTab(number) {
    if (number != this.tab) this.tab = number
    if (number !== 3) localStorage.setItem('selectedTab', this.tab.toString());
  }

  /**
   * Activate spinner.
   * Search for customer with input's keyword.
   * Set results to interface for showing.
   * Disable spinner after api-call.
   *
   * @edit 13.12.2022
   * Added search field to api-call. Check for search word and search field before making api-call.
   * @author Jesse Lindholm
   */
  searchCustomer() {
    if (this.searchWord.length < 3 || this.selectedInputType.value === null || this.selectedProjects.length === 0)
      Swal.fire(
        this.translateService.instant('call-service.wrongInput'),
        this.translateService.instant('call-service.searchCustomerError'),
        'error')
    else {
      this.listSpinner = true
      const projectIds = this.selectedProjects.map(project => project.id).filter(id => id !== null) as string[];
      const selectedWeek = this.selectedWeek;
      this.callService.customerSearch(
        this.searchWord,
        this.selectedInputType.value,
        projectIds,
        this.selectedWeek?.label ?? ''
      ).pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: data => {
            this.searchResults = data
            this.listSpinner = false
          },
          // Error handling, show error message in HTML after stopping spinner
          error: () => {
            this.toastService.sendToast(false, this.translateService.instant('call-service.dashboard.noPersonsFound'))
            this.listSpinner = false
          }
        })
    }
  }

  moveToEnduserPage(id, workorderId) {
    const queryParamsValue: Params = { workorderId: workorderId, backTab: this.tab };
    this.router.navigate(['call-service/enduser/' + id], {
      queryParams: queryParamsValue
    })
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/login'])
  }

  changeInputType(givenInputType: InputType) {
    this.selectedInputType = givenInputType
  }

  /**
   * Get projects for displaying them in UI.
   */
  getProjects() {
    this.callService.getProjects()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.projectsFilterGroup = JSON.parse(JSON.stringify(data));
        let partialProject: Partial<Project> = {name: 'All projects', id: 0}
        this.projectsFilterGroup.unshift(partialProject)
        this.projects = data;
        this.projects.unshift({ name: 'Search from all projects (slow)', id: 'allprojects' });
        this.selectedProjects = [this.projects[0]];

        this.downloadCallingListByWeekAndProjects();
      });
  }
  

  getSelectedProjectNames(): string {
    if (this.selectedProjects.length > 0) {
      return this.selectedProjects.map(project => project.name).join(', ');
    }
    return '';
  }

  isProjectSelected(project: ProjectCallService): boolean {
    return this.selectedProjects.some(selectedProject => selectedProject.id === project.id);
  }

  toggleProjectSelection(project: ProjectCallService): void {
    if (project.id === 'allprojects') {
      if (this.isProjectSelected(project)) {
        this.selectedProjects = [];
      } else {
        this.selectedProjects = [project];
      }
    } else {
      const index = this.selectedProjects.findIndex(selectedProject => selectedProject.id === project.id);
      if (index !== -1) {
        this.selectedProjects.splice(index, 1); // Deselect the project
      } else {
        // Remove the "Search from all Projects (slow)" project from selectedProjects
        const allProjectsIndex = this.selectedProjects.findIndex(selectedProject => selectedProject.id === 'allprojects');
        if (allProjectsIndex !== -1) {
          this.selectedProjects.splice(allProjectsIndex, 1);
        }
        this.selectedProjects.push(project); // Select the project
      }
    }
  }

  selectWeek(week: { label: string, number: number }) {
    this.selectedWeek = week;
  }

  selectWeekForMailingList(week: { label: string, number: number }) {
    this.selectedWeekForMailingList = week;
  }


  getWeeksForCallingList() {
    type Week = { label: string; number: number };
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const weekStart = new Date(currentYear, 0, 1);
    const weeks: Week[] = [];

    // Find the first Monday of the year
    while (weekStart.getDay() !== 1) {
      weekStart.setDate(weekStart.getDate() + 1);
    }

    let weekNumber = 1;
    let weekEnd = new Date(weekStart.getTime());
    weekEnd.setDate(weekEnd.getDate() + 6);
    let weekLabel = `${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)}`;

    // Placeholder is set as "All weeks"
    const placeholderWeek = { label: "All weeks", number: 0 };
    weeks.push(placeholderWeek);

    while (weekStart.getFullYear() === currentYear) {
      const week: Week = { label: weekLabel, number: weekNumber };
      weeks.push(week);

      weekStart.setDate(weekStart.getDate() + 7);
      weekEnd = new Date(weekStart.getTime());
      weekEnd.setDate(weekEnd.getDate() + 6);
      weekLabel = `${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)}`;

      weekNumber++;
    }

    this.weeksMailingList = weeks;

    // Set the default selected week based on the parameter
    this.selectedWeekForMailingList = placeholderWeek

  }




  /**
   * Retrieve the list of weeks and set the current week.
   */
  getWeeks() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const weekStart = new Date(currentYear, 0, 1);
    const weeks: { label: string; number: number }[] = [];

    // Find the first Monday of the year
    while (weekStart.getDay() !== 1) {
      weekStart.setDate(weekStart.getDate() + 1);
    }

    let weekNumber = 1;
    let weekEnd = new Date(weekStart.getTime());
    weekEnd.setDate(weekEnd.getDate() + 6);
    let weekLabel = `${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)}`;

    const placeholderWeek = { label: "All weeks", number: 0 };
    weeks.push(placeholderWeek);

    while (weekStart.getFullYear() === currentYear) {
      const week = { label: weekLabel, number: weekNumber };
      weeks.push(week);

      weekStart.setDate(weekStart.getDate() + 7);
      weekEnd = new Date(weekStart.getTime());
      weekEnd.setDate(weekEnd.getDate() + 6);
      weekLabel = `${this.formatDate(weekStart)} - ${this.formatDate(weekEnd)}`;

      weekNumber++;
    }

    this.weeks = weeks;

    const currentWeek = this.getWeekNumber(currentDate).toString();
    this.selectedWeek = placeholderWeek;

    setTimeout(() => {
      const dropdownToggle = document.getElementById("dropdownWeek");
      if (dropdownToggle) {
        dropdownToggle.addEventListener("click", () => {
          const dropdownMenu = document.querySelector(".week-dropdown-menu");
          if (dropdownMenu) {
            const activeItem = dropdownMenu.querySelector(".active");
            if (activeItem) {
              const dropdownHeight = dropdownMenu.clientHeight;
              const itemHeight = activeItem.clientHeight;
              const scrollOffset = Math.floor((dropdownHeight - itemHeight) / 2);
              dropdownMenu.scrollTop = (activeItem as HTMLElement).offsetTop - scrollOffset;

              // Remove the 'chosen' class from all items
              const items = dropdownMenu.querySelectorAll(".week-dropdown-item");
              items.forEach((item) => {
                item.classList.remove("chosen");
              });

              if (activeItem.textContent?.trim() === "All weeks") {
                // Placeholder "All weeks" is selected, focus on current week
                const currentWeekItem = Array.from(items).find((item) => {
                  const weekText = item.textContent?.split("-")[0]?.trim();
                  return weekText === currentWeek.toString();
                });
                if (currentWeekItem) {
                  currentWeekItem.classList.add("chosen");
                  dropdownMenu.scrollTop = (currentWeekItem as HTMLElement).offsetTop - scrollOffset;
                }
              } else {
                activeItem.classList.add("chosen");
              }
            }
          }
        });
      }
    }, 0);

  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
  }

  getWeekNumber(date: Date): number {
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const firstDayOfWeek = 1;

    let firstMonday = new Date(yearStart);
    while (firstMonday.getDay() !== firstDayOfWeek) {
      firstMonday.setDate(firstMonday.getDate() + 1);
    }

    const days = Math.floor((date.getTime() - firstMonday.getTime()) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((days + 1) / 7);
    return weekNumber;
  }

  downloadCallingCSVByWeekAndProjects() {
    const week = this.selectedWeekForMailingList?.label;
    const wnum = this.selectedWeekForMailingList?.number.toString();
    const startDate = week?.split(' - ')[0];
    const endDate = week?.split(' - ')[1];
    let projects: number[];
    if (this.selectedProjects[0].id === 'allprojects') {
      projects = this.projects
        ?.filter(project => project.id !== 'allprojects')
        .map(project => +project.id!) as number[];

    } else {
      projects = this.selectedProjects?.map(project => +project.id!) as number[];
    }

    if (week !== undefined && startDate !== undefined && endDate !== undefined && wnum != undefined) {
      const formattedStartDate = this.formatDateToEng(startDate, 'yyyy-MM-dd', 'en');
      const formattedEndDate = this.formatDateToEng(endDate, 'yyyy-MM-dd', 'en');
      this.calendarsService.getCalendarsByWeekAndProjects(projects, formattedStartDate, formattedEndDate)
        .subscribe({
          next: (response: any) => {
            const calendarIds = response.calendarIds;
            this.downloadCallingCSV(calendarIds, wnum)
          },
          error: (error: any) => {
            if (error.status === 404) {
              if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.calendar_not_found'));
            } else if (error['message'].includes('400 Bad Request')) {
              if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
            } else {
              if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
            }
          }
        });
    }
  }


  downloadCallingListByWeekAndProjects() {
    const week = this.selectedWeekForMailingList?.label;
    const wnum = this.selectedWeekForMailingList?.number.toString();
    let projects: number[];

    if (this.selectedProjects[0].id === 'allprojects') {
      projects = this.projects
        ?.filter(project => project.id !== 'allprojects')
        .map(project => +project.id!) as number[];
    } else {
      projects = this.selectedProjects?.map(project => +project.id!) as number[];
    }

    if (week !== undefined && wnum !== undefined) {
      if (week === "All weeks") {
        // Handle the case when week is "All weeks" without startDate and endDate
        this.calendarsService.getCalendarsByWeekAndProjects(projects, null, null)
          .subscribe({
            next: (response: any) => {
              const calendarIds = response.calendarIds;
              this.fetchCallingListData(calendarIds);
            },
            error: (error: any) => {
              if (error.status === 404) {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.calendar_not_found'));
              } else if (error['message'].includes('400 Bad Request')) {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
              } else {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
              }
            }
          });
      } else {
        const startDate = week.split(' - ')[0];
        const endDate = week.split(' - ')[1];
        const formattedStartDate = this.formatDateToEng(startDate, 'yyyy-MM-dd', 'en');
        const formattedEndDate = this.formatDateToEng(endDate, 'yyyy-MM-dd', 'en');

        this.calendarsService.getCalendarsByWeekAndProjects(projects, formattedStartDate, formattedEndDate)
          .subscribe({
            next: (response: any) => {
              const calendarIds = response.calendarIds;
              this.fetchCallingListData(calendarIds);
            },
            error: (error: any) => {
              if (error.status === 404) {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.calendar_not_found'));
              } else if (error['message'].includes('400 Bad Request')) {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
              } else {
                if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
              }
            }
          });
      }
    }
  }



  fetchCallingListData(calendarIds: any) {
    this.calendarsService.getMultipleCallingList(calendarIds, true)
      .subscribe({
        next: (response: any) => {
          this.allCallingListData = response.data;
          this.populateCallingLists(this.allCallingListData);
          this.sortCallingLists(this.mandatoryCallingList, this.callingList, this.timePassedCallingList)
          let item = localStorage.getItem('call-service-state')
          if (item) this.applyState(item)
        },
        error: (error: any) => {
          if (error.status === 404) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.calendar_not_found'));
          } else if (error['message'].includes('400 Bad Request')) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
          } else {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
          }
        }
      })
  }

  sortCallingLists(mandatoryCallingList: any[], callingList: any[], timePassedCallingList: any[]) {
    // Sort mandatoryCallingList based on time_window_start
    mandatoryCallingList.sort((a, b) => {
      const timeA = new Date(a.time_window_start).getTime();
      const timeB = new Date(b.time_window_start).getTime();
      return timeA - timeB;
    });

    // Sort callingList based on time_window_start
    callingList.sort((a, b) => {
      const timeA = new Date(a.time_window_start).getTime();
      const timeB = new Date(b.time_window_start).getTime();
      return timeA - timeB;
    });

    // Sort timePassedCallingList based on time_window_start
    timePassedCallingList.sort((a, b) => {
      const timeA = new Date(a.time_window_start).getTime();
      const timeB = new Date(b.time_window_start).getTime();
      return timeA - timeB;
    });
  }



  formatDateToEng(dateString: string, format: string, locale: string): string {
    const [day, month, year] = dateString.split('.');
    return `${year}-${month}-${day}`;
  }

  downloadCallingCSV(ids: number[], fileName: string) {
    this.calendarsService.getNotConfirmedCallingCSV(ids)
      .subscribe({
        next: (data: any) => {
          exportCSV(data, "Callinglist_multiple_w" + fileName);
        },
        error: (error: any) => {
          if (error.status === 404) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.calendar_not_found'));
          } else if (error['message'].includes('400 Bad Request')) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
          } else {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
          }
        }
      });
  }

  downloadCallingCSVForAll() {
    // Fetch all projects
    const projects = this.projects
      ?.filter(project => project.id !== 'allprojects')
      .map(project => +project.id!) as number[];

    // No need to loop through weeks for this case
    // Fetch all calendars without specifying a week or date range
    this.calendarsService.getCalendarsByWeekAndProjects(projects, null, null)
      .subscribe({
        next: (response: any) => {
          const calendarIds = response.calendarIds;
          this.downloadCallingCSV(calendarIds, 'all');
        },
        error: (error: any) => {
          // Handle errors as needed
        }
      });
  }



  calculateDaysUntilDate(targetDate: string): number {
    const targetDateObj = new Date(targetDate);
    targetDateObj.setHours(0, 0, 0, 0)
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0)
    const timeDifferenceInDays = Math.floor((+targetDateObj - +currentDate) / (1000 * 60 * 60 * 24));
    return timeDifferenceInDays;
  }

  calculateDaysUntilDateAbs(targetDate: string): number {
    const targetDateObj = new Date(targetDate);
    targetDateObj.setHours(0, 0, 0, 0)
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0)
    const timeDifferenceInDays = Math.floor((+targetDateObj - +currentDate) / (1000 * 60 * 60 * 24));
    return Math.abs(timeDifferenceInDays);
  }

  formatTime(time: string): string {
    if (!time) return time;
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  }

  populateCallingLists(allCallingListData) {
    const currentDate = new Date()
    for (const item of allCallingListData) {
      // Works only properly if MSA can be at one project only. If that is changed, remake logic.
      if (item.msa_id) {
        let found = this.msaFilterGroup.find(msa => msa.id === item.msa_id)
        if (found === undefined && item.msa_name) this.msaFilterGroup.push({name: item.msa_name, id: item.msa_id, project_id: item.project_id})
      }
      if (item.access_type && item.project_id) {
        let accessTypeElement = this.accessTypeFilterGroup.find(at => at.id === item.access_type)
        if (accessTypeElement === undefined) this.accessTypeFilterGroup.push({name: this.translateService.instant('accessTypes.' + item.access_type), id: item.access_type, projectIds: [item.project_id]})
        else {
          let doesHaveProjectId = accessTypeElement.projectIds.some(pid => pid === item.project_id)
          if (!doesHaveProjectId) accessTypeElement.projectIds = [...accessTypeElement.projectIds, item.project_id]
        }
      }
      item.show = true
      // Assuming item.time_window_end = "2024-03-20" and item.time_end = "11:00:00"
      const datePart = item.time_window_end;
      const timePart = item.time_end;

      // Combine the date and time parts into a single string
      const dateTimeString = `${datePart}T${timePart}`;

      // Create the Date object
      const dateTime = new Date(dateTimeString);
      if (dateTime.getTime() < currentDate.getTime()) {
        this.timePassedCallingList.push(item);
      } else if (item.call_count >= 3) {
        this.mandatoryCallingList.push(item);
      } else {
        this.callingList.push(item);
      }
    }
  }

  copyToClipboard(text: string) {
    if (this.clipboardService.isSupported) {
      this.clipboardService.copy(text);
      alert(this.translateService.instant('planner.endUserCommunications.copiedToClipboard') + text);
    } else {
      // Handle clipboard not supported
      alert(this.translateService.instant('planner.endUserCommunications.notCopiedToClipboard'));
    }
  }

  callPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }

  getPhoneCommunicatedValue(phoneCommunicatedValue) {
    if (!phoneCommunicatedValue) return null
    let objects = JSON.parse(phoneCommunicatedValue)
    if (objects) {
      // Get today's date in "YYYY-MM-DD" format
      const today = new Date().toISOString().slice(0, 10);
      
      // Check if any object has a date matching today and is not answered
      const boolean = objects.some(element => element.date === today);
    
      // Return 'gray-bg' if an unanswered call today is found, null otherwise
      return boolean ? 'gray-bg' : null;
    } else return null
  }
  

  /**
   * Called from HTML, first run filter through all options, (Project, MSA, access type, enduser type).
   * Then filter with name field, if present. 
   * Filter is done with element.show attribute. True if shown, false if hidden.
   * @param selectedType type of filter used to call the function
   * @param filterCriterion what was selected in current type of filter
   */
  filterCallingList(selectedType: string | null, filterCriterion: any, save: boolean) {
    // Set the filter based on the selected type
    if (selectedType === 'project') {
        this.selectedProjectFilter = filterCriterion;
        // Reset MSA and Access Type filters
        this.selectedMSAFilter = this.msaFilterGroup[0];
        this.selectedAccessTypeFilter = this.accessTypeFilterGroup[0];
    } else if (selectedType === 'msa') {
        this.selectedMSAFilter = filterCriterion;
    } else if (selectedType === 'access-type') {
        this.selectedAccessTypeFilter = filterCriterion;
    } else if (selectedType === 'enduser-type') {
        this.selectedEnduserTypeFilter = filterCriterion;
    }

    const lists = [this.callingList, this.timePassedCallingList, this.mandatoryCallingList];

    const filterByNameList: any[] = []
    lists.forEach(list => list.forEach(element => {
        element.show = this.matchesFilters(element);  // Set the `show` property directly based on filter match
        if (element.show) filterByNameList.push(element)
    }));
    if (filterByNameList.length > 0) this.filterByName(filterByNameList)
    if (save) this.saveFilterState()
}

saveFilterState() {
  let state = {
    project: this.selectedProjectFilter,
    msa: this.selectedMSAFilter,
    accessType: this.selectedAccessTypeFilter,
    enduserType: this.selectedEnduserTypeFilter,
    name: this.filterName
  }

  localStorage.setItem('call-service-state', JSON.stringify(state))
}

applyState(item) {
  let state = JSON.parse(item)
  this.selectedProjectFilter = state.project
  this.selectedMSAFilter = state.msa
  this.selectedAccessTypeFilter = state.accessType
  this.selectedEnduserTypeFilter = state.enduserType
  this.filterName = state.name
  this.filterCallingList(null, null, false)
}

/**
 * Match calling list entry to all the filters, except name checking. Refactored using GPT for cleaner look.
 * First get number of matches for the element. Meaning what filters it does match. For example 
 * if element is meant to be shown with the project filter then matchesProject is true.
 * Secondly get number of active filter, for example if project and msa is selected => active filters === 2.
 * Then compare number of active filters to matches of the filters. If they match return true or else return false.
 * @param element calling list entry
 * @returns boolean depending should we show element or not.
 */
matchesFilters(element: any): boolean {
    const matchesProject = this.selectedProjectFilter.id !== 0 && element.project_id === this.selectedProjectFilter.id;
    const matchesMsa = this.selectedMSAFilter.id !== 0 && element.msa_id === this.selectedMSAFilter.id;
    const matchesAccessType = this.selectedAccessTypeFilter.id !== 0 && element.access_type === this.selectedAccessTypeFilter.id;
    const matchesEnduserType = this.selectedEnduserTypeFilter.id !== undefined && element.person_type === this.selectedEnduserTypeFilter.id;

    // Determine the number of active filters
    const activeFilters = [
        this.selectedProjectFilter.id !== 0,
        this.selectedMSAFilter.id !== 0,
        this.selectedAccessTypeFilter.id !== 0,
        this.selectedEnduserTypeFilter.id !== undefined
    ].filter(Boolean).length;

    const matchCounts = [matchesProject, matchesMsa, matchesAccessType, matchesEnduserType].filter(Boolean).length;

    // Element should be shown if it matches all active filters or if no filters are active (i.e., show all)
    return matchCounts === activeFilters || activeFilters === 0;
  }


  filterByName(list) {
    if (this.filterName) {
      list.forEach(row => {
        if (!row.contact_name.toLowerCase().includes(this.filterName.toLowerCase()) && this.filterName) row.show = false
        else row.show = true
      });
    }
  } 
}

