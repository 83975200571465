import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from '@environments/environment';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
     ) { }

  /**
   * Perform get api call to api/users to get users information
   * @returns all data for user that is logged in
   */
   getUserInfo(): Observable<any> {
    return this.http.get<any>(`${baseUrl}/api/me`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  /**
   * Get data for user with id, used if we want to get different
   * data than current user data
   * @param id users id
   * @returns all data for user with the id
   */
  getUserInfoWithId(id): Observable<any> {
    return this.http.get<any>(`${baseUrl}/api/users/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }


  getUsers(role, all = 0) {
        return this.http.get(`${baseUrl}/api/users/`,
            {
                params: {
                    'allUsers': all,
                    'role': role,
                }
            })
            .pipe(
                map((data: any) => {
                    return data['message'];
                }),
                catchError(error => {
                    return of(false);
                })
            )
  }


    createUser(
        firstname,
        lastname,
        email,
        username,
        password,
        language,
        role
    ) {
        let data = {
            "firstname": firstname,
            "lastname": lastname,
            "username": username,
            "email": email,
            "password": password,
            "language": language,
            "role": role,
            "returnid": 1
        }

        return this.http.post<any>(`${baseUrl}/api/register`, data)
            .pipe(
                map((data: any) => {
                    return data['message'];

                }),
                catchError(error => {
                    Swal.fire(error.statusText)
                    return of(false)
                })
            )
    }

  /**
   * Send patch api call that updates data in database
   * @param userId User that has logged in. Used to define correct project to update
   * @param currentProjectId What to set to users current project
   */
  updateCurrentProject(userId: number, currentProjectId: number): Observable<any>{
    let newdataJSON = {
      "current_project": currentProjectId
    }
    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/users/${userId}`, { newdata });
  }

  /**
   * Link project and user
   */
  setProjectToUser(userId: number, projectId: number): Observable<any> {
    return this.http.post<any>(`${baseUrl}/api/users/${userId}/${projectId}`, {})
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }
  /**
   * Remove link between project and user
   */
  removeProjectFromUser(userId: number, projectId: number): Observable<any> {
    return this.http.delete<any>(`${baseUrl}/api/users/${userId}/${projectId}`, {})
      .pipe(
        map((data: any) => {
          return data['message'];
        })
      );
  }

  registerUser(name, username, email, password) {

    let newdataJSON = {
      "name": name,
      "username": username,
      "email": email,
      "password": password,
      "role": 2,
      "disabled": 0,
      "returnid": 1
    }
    return this.http.post(`${baseUrl}/api/register`, newdataJSON)
    .pipe(
      map((data) => {
        return data['message']
      }),
      catchError(this.handleError('userRegister'))
   );


  }

  updateUserEmail(id, email) {
    let newdataJSON = {
      "email": email,
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/users/${id}`, {newdata})
    .toPromise()
    .then(response => {
      // this.toastService.sendToast(true, 'Updated email')
      return response
    })
    .catch(err => {

      this.toastService.sendToast(false, err)
    });

  }

  updateUserUsernamePassword(id, username, password) {
    let newdataJSON;
    if (username != null && password != null) {
      newdataJSON = {
        "username": username,
        "password": password,
        "disabled": 0
      }
    } else if (username == null) {
      newdataJSON = {
        "password": password
      }
    } else if (password == null) {
      newdataJSON = {
        "username": username
      }
    }


    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/users/${id}`, {newdata})
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.updatedLoginDetails'))
      return response
    })
    .catch(err => {
      this.toastService.sendToast(false, err)
    });

  }

  updateUserTags(id, tags) {
    let newdataJSON = {
      "tags": tags
    }
    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/users/${id}`, {newdata})
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.updatedTags'))
      return response
    })
    .catch(err => {
      this.toastService.sendToast(false, err)
    });

  }

  /**
   * 1.11.2022
   * Update users language.
   * Send correct toast message.
   * @param userId
   * @param language
   * @author Jesse Lindholm
   */
  updateUserLanguage(userId, language) {
    let newdataJSON = {
      "language": language,
    }
    let newdata = JSON.stringify(newdataJSON)
    this.http.patch(`${baseUrl}/api/users/${userId}`, {newdata}).subscribe({
      error: (e) => this.toastService.sendToast(false, this.translateService.instant('services.updateLanguageFailed')),
      complete: () => this.toastService.sendToast(true, this.translateService.instant('services.updateLanguageSuccess'))
    })
  }


    updateUser(id, country, address, phone, region) {

        let newdataJSON = {
            "address": address,
            "country": country,
            "phone": phone,
            "region": region,

        }

        let newdata = JSON.stringify(newdataJSON)

        this.http.patch(`${baseUrl}/api/users/${id}`, {
            newdata
        })
            .toPromise()
            .then(response => {
                this.toastService.sendToast(true, this.translateService.instant('services.updateUser'))
                return response
            })
            .catch(err => {
                this.toastService.sendToast(false, err)
            });
    }


    checkUsername(username) {
        return this.http.post(`${baseUrl}/api/isusernameinuse/${username}`, null)
            .pipe(
                map((data) => {
                    if (data['message'] != 'username_not_found') this.toastService.sendToast(false, this.translateService.instant('services.usernameInUse'))
                    return data['message']
                }),
                catchError(this.handleError(this.translateService.instant('services.checkUsername')))
            );
    }

    changeDisabledStatus(disabledValue, userId) {
        let data = {
            disabled: disabledValue
        }
        let disabledText = ""
        if (disabledValue == 1) disabledText = 'disabled'
        else disabledText = 'activated'
        let newdata = JSON.stringify(data)

        return this.http.patch(`${baseUrl}/api/users/${userId}`, {newdata})
            .pipe(
                map((data: any) => {
                    this.toastService.sendToast(true, this.translateService.instant('services.disabledStatusSwitched') + disabledText)
                    return data['message'];
                }),
                catchError(error => {
                    //alert(error.error)
                    return of(false)
                })
            )
    }

    /**
     * Get all customer projects
     */
    getAllProjects(): Observable<any> {
        return this.http.get<any>(`${baseUrl}/api/allprojects`)
            .pipe(
                map((data: any) => {
                    return data['message'];
                })
            );
    }


    /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  // Use only for Register user
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.toastService.sendToast(false, this.translateService.instant('services.userRegister'))


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
