import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-work-order',
  template: `
    <div class="p-2">
        <div (click)="onWorkOrderClick()" class="cursor-pointer"
        (mousedown)="onMouseDown()"
        (mouseup)="onMouseUp()"
        (mouseleave)="onMouseUp()"
        (touchstart)="onMouseDown()"
        (touchend)="onMouseUp()">
          <div class="dual-container p-1">
              <div class="left text-uppercase">
                <div *ngIf="workOrder.state !== 4">
                  <div *ngIf="workOrder.time_confirmed == 1" >
                      <img class="dp24" src="assets/icons/Time confirmed.svg">
                      <span class="green-text">{{'fieldReporting.dashboard.timeConfirmed' | translate }}</span>
                  </div>
                  <div *ngIf="workOrder.time_confirmed != 1">
                      <img class="dp24" src="assets/icons/Time not confirmed.svg">
                      <span class="red-text">{{'fieldReporting.dashboard.timeNotConfirmed' | translate }}</span>
                  </div>
                </div>
                <div *ngIf="workOrder.state === 4" class="interrupt-reason">
                  <img class="dp24" src="assets/icons/warning_red.svg">
                  <span class="interrupt-reason-text" *ngIf="workOrder.interrupt_reason">{{workOrder.interrupt_reason}}</span>
                  <span class="interrupt-reason-text" *ngIf="!workOrder.interrupt_reason">{{'fieldReporting.dashboard.massInterrupted' | translate}}</span>
                </div>
                <div *ngIf="workOrder.state === 4">
                  <span *ngIf="workOrder.interrupt_code" class="red-text">{{workOrder.interrupt_code}} - </span>
                  <span class="interruptDefinition" *ngIf="workOrder.interrupt_definition">{{workOrder.interrupt_definition.definition}}</span>
                  <span *ngIf="workOrder.modified" class="red-text"> - {{workOrder.modified}}</span>
                </div>
                <div class="text-lowercase">
                    <span class="text-capitalize">{{'basic.meter' | translate }}</span>
                    <span class="ml-1" *ngIf="workOrder.old_device_id">{{workOrder.old_device_id}}</span>
                    <span class="ml-1" *ngIf="workOrder.location_number">({{workOrder.location_number}})</span>
                  <span class="ml-1" *ngIf="!workOrder.old_device_id && !workOrder.location_number">-</span>
                </div>
              </div>
              <div class="right">
                  <img class="corner-icon" [src]=setCornerIcon(workOrder.access_type)>
              </div>
          </div>
          <div class="row p-1">
              <div class="col-4 d-flex">
                  <img class="card-icon dp24" src="assets/icons/schedule_blue_24dp.svg">
                  <div *ngIf="workOrder.time_window_start">{{timeWindowToHours(workOrder.time_window_start)}}</div>
                  <div *ngIf="!workOrder.time_window_start" class="red-text">XX:XX</div>
                  -
                  <div *ngIf="workOrder.time_window_end">{{timeWindowToHours(workOrder.time_window_end)}}</div>
                  <div *ngIf="!workOrder.time_window_end" class="red-text">XX:XX</div>
              </div>
              <div class="col-7 d-flex ml-5">
                  <img class="card-icon dp24" src="assets/icons/today_blue_24dp.svg">
                  <div *ngIf="workOrder.time_window_start">{{timeWindowToDate(workOrder.time_window_start)}}</div>
                  <div *ngIf="!workOrder.time_window_start" class="red-text">{{'fieldReporting.shared.missingDate' | translate }}</div>
              </div>
          </div>
          <div class="p-1 d-flex">
              <img class="dp24" src="assets/icons/location_on_blue_24dp.svg">
              <div class="mt-1">{{workOrder.address}}</div>
          </div>
          <div class="p-1 d-flex" *ngIf="workOrder.distance">
              <img class="dp24" src="assets/icons/location.svg">
              <div class="mt-1">{{workOrder.distance < 1 ? (workOrder.distance * 1000).toFixed(0) : workOrder.distance.toFixed(2)}} {{workOrder.distance < 1 ? ('basic.metersShort' | translate) : 'basic.kilometersShort' | translate}}</div>
          </div>
          <div class="display-flex" *ngIf="workOrder.endusers">
              <div class="d-flex flex-wrap mt-3" *ngFor="let user of workOrder.endusers">
                  <div class="tagbox">{{user.contact_person_last}} {{user.contact_person_first}}</div>
                  <div class="tagbox" *ngIf="user.phone">{{user.phone}}</div>
              </div>
          </div>
          <hr>
          <div>
              <div *ngFor="let estimate of workOrder.time_table | keyvalue">
                <div><b class="text-primary">{{estimate.key}}:</b> {{estimate.value}}</div>
              </div>
          </div>
          <hr *ngIf="workOrder.reserver">
          <div class="display-flex" *ngIf="workOrder.reserver">
              <div class="tagbox-green" *ngIf="workOrder.reserver_id == this.userid "><img id="me2Img" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedToMe' | translate }}</div>
              <div class="tagbox-grey"*ngIf="workOrder.reserver_id != this.userid"><img id="else2Img" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedTo' | translate }} {{workOrder.reserver}}</div>
              <div class="tagbox-grey"*ngIf="this.projectType != 2 && workOrder.reserver_id == this.userid && workOrder.workerteam_id != this.currentWorkerteam.id"> {{'fieldReporting.dashboard.notInWorkerteam' | translate }}</div>
          </div>
        </div> 
        <hr *ngIf="workOrder.worker_notes">
        <div *ngIf="workOrder.worker_notes">
            <div class="blue-text dual-container mb-2">
                <details class="w-100" (toggle)="workOrder.expand = !workOrder.expand">
                    <summary class="dual-container">
                        <div class="left d-flex">
                            <img class="card-icon" src="assets/icons/info_blue_24dp.svg">
                            <h6 class="m-0 lh-body">{{'fieldReporting.dashboard.extraRemarks' | translate }}</h6>
                        </div>
                        <img *ngIf="!workOrder.expand" class="right" src="assets/icons/expand_more_blue_24dp.svg">
                        <img *ngIf="workOrder.expand" class="right" src="assets/icons/expand_less_blue_24dp.svg">
                    </summary>
                    <div class="ms-4 mt-2">
                        <div [hidden]="!workOrder.worker_notes">
                          <div>{{'fieldReporting.shared.extraInformationPlanner' | translate }}</div>
                            <p class="gray-text">{{workOrder.worker_notes}}</p>
                        </div>
                        <!-- <div [hidden]="!item.notes">
                          <div>{{'fieldReporting.shared.extraInformationCallingService' | translate }}</d
                            <p class="gray-text">{{item.notes}}</p>
                        </div> -->
                    </div>
                </details>
            </div>
        </div>
    </div>
  `,
  styleUrls: ['./dashboard.component.scss']
})
export class WorkOrderComponent {
  @Input() workOrder: any;
  @Input() userid: any;
  @Input() projectType: any;
  @Input() currentWorkerteam: any;
  @Input() search: boolean = false
  @Output() workOrderClicked = new EventEmitter<any>();
  @Output() mouseDownEmit = new EventEmitter<any>()
  @Output() mouseUpEmit = new EventEmitter<any>()

  onWorkOrderClick() {
    this.workOrderClicked.emit(this.workOrder)
  }

  setCornerIcon(accessType) {
    if (accessType == 3) return "assets/icons/key_blue_24dp.svg"
    else if (accessType == 2) return "assets/icons/person_lock_blue_24dp.svg"
    else if (accessType == 1) return "assets/icons/lock_unlocked_blue_24dp.svg"
    else return null
  }

  timeWindowToHours(time) {
    time = time.split(" ")[1]
    let hours = time.split(":")[0]
    let minutes = time.split(":")[1]

    if (hours == '0') {
      hours = '00'
    }
    if (minutes == '0') {
      minutes = '00'
    }

    return hours + ':' + minutes
  }

  timeWindowToDate(time) {
    time = time.split(" ")[0]
    time = time.replaceAll("/", "-")
    var newDate = new Date(time);
    var date = newDate.getDate() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getFullYear()
    return date
  }

  onMouseDown() {
    if (this.search) this.mouseDownEmit.emit(this.workOrder)
  }

  onMouseUp() {
    if (this.search) this.mouseUpEmit.emit()
  }
}
