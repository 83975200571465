import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { HeaderSidebarService } from '@shared/services/header/headerSidebarService';
import { UserService } from '@shared/services/user/user.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

/**
 * This is component for header in application. This component fetches data
 * with different api calls to make a display of current project user is using
 * and shows all other projects user has. User can change project from list
 * @author Jesse Lindholm
 * @editedDate 23.7.2021
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {




  projects
  currentProjectName;
  currentProjectNumber;
  currentProjectId
  initials;
  firstName;
  userInformation;
  currentUser;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let element = document.getElementById('main')
    const hasClass = element?.classList.contains('media-query-enabled');
    if (element && !hasClass) this.renderer.addClass(element, 'media-query-enabled')

    let elementProject = document.getElementById('project-dropdown')
    const hasClassProject = elementProject?.classList.contains('media-query-enabled-project-dropdown');
    if (elementProject && !hasClassProject) this.renderer.addClass(elementProject, 'media-query-enabled-project-dropdown')
  }

  routerSubscription = new Subscription()
  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private renderer: Renderer2,
    private headerSidebarService: HeaderSidebarService
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
          if (event.url == '/planner/project/newproject') {
            this.getCurrentProject()
          }
      }
    });
    this.headerSidebarService.toggleSidebar$.subscribe(() => document.getElementById('burger-btn-toggle')?.click());
   }

  /**
   * Initializes header component by setting current project name and list of projects
   */
  ngOnInit()  {
    this.getCurrentProject();
  }

  /**
   * Destroy subcriptions to prevent memory leaks
   */
  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  ngAfterViewInit() {
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const isDev = window.location.hostname.includes('dev');

    const navbarElement = document.getElementById('top-bar');
    if (navbarElement) {
      if (isLocal) {
        this.renderer.setStyle(navbarElement, 'background-color', 'yellow');
      } else if (isDev) {
        this.renderer.setStyle(navbarElement, 'background-color', 'gray');
      }
    }
  }

  logout() {
    this.authService.logout()
  }

  /**
   * Set data from api call api/me to userInformation
   * Then use current project from gotten data to call getProjectById
   * @author Jesse Lindholm
   * @editedDate 23.7.2021
   */
  getCurrentProject() {
    this.userService.getUserInfo()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        this.projects = data.projects
        for (let i = 0; i < Object.keys(data.projects).length; i++) {
          if (Object.keys(data.projects)[i] == data.current_project) {
            this.currentProjectName = Object.values(data.projects)[i]
          }
        }
        this.currentProjectId = data.current_project
        this.currentUser = data.id;
        this.firstName = data.firstname;
        this.initials = data.firstname.charAt(0).toUpperCase() + data.lastname.charAt(0).toUpperCase();
      }
    );
  }



  /**
   * Go through a for loop to define correct name for current project to show in component
   * Send a patch api call to users/userId to update new current project
   * Initialize projects variable again and remove current project from list to show correct list
   * @param projectId Use this variable as correct project id for new current project name
   */
   switchProject(projectId) {
    // Update database with new project as current project then reload page with new information
    this.userService.updateCurrentProject(this.currentUser, projectId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      () => {
        this.router.navigate(['planner/dashboard'])
        .then(() => {
          window.location.reload();
        });
      }
    );
  }

   /**
   * Checks that fullname has more than one space meaning it has 2 words
   * Sets firstName variable that contains first name of user
   * Sets initials variable that contains initials in uppercase of user
   * @param fullName String that contains fullname of user
   */
  setFirstNameAndInitials(fullName) {
    if (fullName.trim().indexOf(' ') != -1) {
      let finalFirstName = fullName.split(' ').slice(0, -1).join(' ');
      let finalLastName = fullName.split(' ').slice(-1).join(' ');

      let firstNameInitial = finalFirstName.charAt(0).toUpperCase();
      let lastNameInitial = finalLastName.charAt(0).toUpperCase();

      this.firstName = finalFirstName;
      this.initials = firstNameInitial + lastNameInitial;
    } else {
      // String has less than one space meaning its one word
      this.initials = fullName.charAt(0).toUpperCase();
      this.firstName = fullName;
    }

  }

  confirmNewProject() {
    this.router.navigate(['/planner/new-project'])
  }

  toggleSidebar() {
    let element = document.getElementById('main')
    const hasClass = element?.classList.contains('media-query-enabled');

    if (element && hasClass) this.renderer.removeClass(element, 'media-query-enabled')
    else if (element && !hasClass) this.renderer.addClass(element, 'media-query-enabled')

    let elementProject = document.getElementById('project-dropdown')
    const hasClassProject = elementProject?.classList.contains('media-query-enabled-project-dropdown');

    if (elementProject && hasClassProject) this.renderer.removeClass(elementProject, 'media-query-enabled-project-dropdown')
    else if (elementProject && !hasClassProject) this.renderer.addClass(elementProject, 'media-query-enabled-project-dropdown')
  }

}
