import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ActivatedRoute } from '@testing/activated-route.stub';
import { Subject, takeUntil } from 'rxjs';
import { Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { icon, Icon } from 'leaflet';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ReportType } from '@shared/models/reportType';
import imageCompression from 'browser-image-compression';
import { DomSanitizer } from '@angular/platform-browser';


declare const L: any;
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  tabindex: number = 1
  id: string | null = null

  // First tab variables
  // Global variables of meter tab
  meterTabOpacity: number = 0.4
  isMeterChecked: boolean = false
  saveOldMeter: boolean = false
  showFuse: boolean = false;
  fuseType: string | null = null
  // Meter number
  meterNumberSpinner: boolean = true;
  meterNumber: number | null = null
  meterNumberCheckbox = false;
  meterNumberNotCheckedOnSave = false;
  // Final reading
  finalReadingValue: number = 0
  // Defaults if no reading is given
  finalReadingRangeTop: number = 10000;
  finalReadingRangeBottom: number = 0;
  rangeIsWrong = false;
  outOfRangeReasons: Array<ReportType> = [
    {id: 0, name: 'No reason', openCustom: false},
    {id: 1, name: 'Theft', openCustom: false},
    {id: 2, name: 'Malfunction', openCustom: false},
    {id: 3, name: 'Other', openCustom: true}
  ]
  outOfRangeReason: ReportType = this.outOfRangeReasons[0]
  reasonSelected: boolean = false;
  showReasonsCustom: boolean = false
  customReason: string = ""
  // Fuse
  fuseNotAvailable: boolean = false
  hasFuseRange: boolean = false
  fuseTypes: Array<ReportType> = [
    {id: 0, name: 'Cartridges', openCustom: false},
    {id: 1, name: 'Circuit-breaker', openCustom: false},
    {id: 2, name: 'Other', openCustom: true}
  ]
  selectedFuseType: ReportType | null = null
  isFuseTypeSelected: boolean = false
  fuseTypeNotSelectedOnSave: boolean = false
  // Slider
  sliderValue: number = 40
  sliderMaxValue: number = 81

  map: any
  topIcon = 0
  story = 0
  accessTypeInfo = ''
  accessType: number | null = null
  placementText = ''
  latitude: any
  longitude: any
  location = true

  // Extra work type
  extraWorkTypes: Array<ReportType> = [
    {id: 0, name: 'None', openCustom: false}
  ]
  extraWorkType: ReportType | null = null
  extraTime = 0
  extraWorkImgCheck: boolean = false

  newMeterNr: any
  newMeterReading: any
  installationTime: any
  installationDate: Date = new Date()
  installationDateToApi: any
  installHours: any
  installMinutes: any
  additionalInfo: String = ""
  switchedDate: any
  switchedTime: any
  dirtyTime = false
  timeUntouched = true
  newMeterOnSave = false;
  saveAdditional = false;
  secondTabDisabled = true;
  thirdTabDisabled = true;
  showOtherWorkType: boolean = false
  otherWorkType: string | null = null

  firstTabImgCheck = false
  secondTabImgCheck = false
  thirdTabImgCheck = false


  // Antenna stuff
  antenna = false
  antModel: any
  antManufacturer: any
  antModelNr: any
  antSerialNr: any
  antSignal: any

  /**
   * 0 = surroundings before starting
   * 1 = wiring before installation
   * 2 = old meter and its reading
   * 3 = New wiring after the installation
   * 4 = New meter with electricity on
   * 5 = New meters consumption register.
   * 6 = Antenna installation and wiring
   * 7 = Picture of extra work
   * 8 = Meter surroundings before leaving
   */
  images: Array<any> = [
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null},
    {img: null, data: null}
  ]



  defaultMarker: Icon = icon({
    iconUrl: 'assets/leaflet/location.svg',
    shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [50, 50],
    shadowSize: [20, 20],
    iconAnchor: [25, 45],
    shadowAnchor: [10, 10]
  });

  reporterId: number | null = null

  constructor(
    private workorderService: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fieldReportingService: FieldReportingService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) { }

  // Get meter id from url
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap =>  {
      this.id = pmap.get('id')
      this.getMeterInformation(this.id)
      document.addEventListener('DOMContentLoaded', (event) => {
        if (this.tabindex === 1) this.selectFuseType(null)
      })
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }



  /**
   * 15.12.2022
   * Set limitations for image compression.
   * Then call imageCompression api from browser-image-compression library.
   * Use end result in images array.
   * @param event Allows to select file with catching event parameter
   * @param index What index of image we are using
   * @author Jesse Lindholm
   */
  async handleImageUpload(event, index: number) {
    const imageFile = event.target.files[0]
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 800,
      useWebWorker: true
    }
    try {
      imageCompression(imageFile, options).then(x => {
        let output = x;
        this.images[index].data = output
        const downloadLink = URL.createObjectURL(output);
        this.images[index].img = this.sanitizer.bypassSecurityTrustUrl(downloadLink)
      })
    } catch (error) {
    }

  }

  /**
   * Handles the access type selection
   * @param icon
   */
  topIconSwitch(icon) {
    if (icon == this.topIcon) this.topIcon = 0
    else this.topIcon = icon
    if(this.topIcon == 1) this.accessType = 3
    if(this.topIcon == 2) this.accessType = 2
    if(this.topIcon == 3) this.accessType = 1
  }

  /**
   * Handles tab switching in the UI
   * @param tab
   */
  tabSwitch(tab) {
    this.tabindex = tab
    if (tab == 1) {
      this.secondTabDisabled = true
      this.thirdTabDisabled = true
    } else if (tab == 2) {
      this.secondTabDisabled = false
      this.thirdTabDisabled = true
    } else if (tab == 3) {
      this.secondTabDisabled = false
      this.thirdTabDisabled = false
      this.initMap()
    }
  }

  /**
   * Simple counter for story
   * @param direction
   */
  adjustToStory(direction) {
    if (direction == 'up') {
      this.story++
    } else if (direction == 'down') {
      this.story--
    }
  }

  /**
   * Counter for extra work time
   * @param operation
   */
  adjustExtraWork(operation) {
    if (operation == 'inc') {
      this.extraTime++
    } else if (operation == 'dec' && this.extraTime > 0) {
      this.extraTime--
    }
  }

  /**
   * Initializes the map with custom settings
   *
   * TODO: Reverse geocoding to get location name
   */
  initMap() {
    // Sets initial canvas
    this.map = L.map('reportmap', {
      editable: true,
      dragging: false,
      scrollWheelZoom: false
    })

    // Sets the zoom +/- controller to topright corner of the map
    this.map.zoomControl.setPosition('topright')

    // OpenStreetMap integration
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 19,
      minZoom: 5,
      detectRetina: true,
    }).addTo(this.map)

    // Center on user on default, disable location if user doesn't give permission.
    this.map.locate({
      setView : true,
      maxZoom: 15
    }).on('locationfound', (e) => {
      var marker = L.marker([e.latitude, e.longitude], {icon: this.defaultMarker});
      this.latitude = e.latitude
      this.longitude = e.longitude
      this.map.addLayer(marker);
    }).on('locationerror', () => {
          alert(this.translateService.instant('fieldReporting.shared.locationAccessError'));
          this.location = false
    });


  }

  // Get meter information with correct id
  getMeterInformation(meterId) {
    this.workorderService.getWorkorderWithOptions(meterId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        if (data.extra_work_options) {
          JSON.parse(data.extra_work_options).forEach(workOption => {
            this.extraWorkTypes.push({id: this.extraWorkTypes.length, name: workOption, openCustom: true})
          })
        }
        this.extraWorkTypes.push({id: this.extraWorkTypes.length, name: 'Other', openCustom: true})
        this.meterNumber = data.old_device_id
        if (data.range_min) this.finalReadingRangeBottom = data.range_min
        if (data.range_max) this.finalReadingRangeTop = data.range_max
        if (data.range_min && data.range_max) this.hasFuseRange = true

        if(data.access_type) {
          if(data.access_type == 1) this.topIconSwitch(3)
          if(data.access_type == 2) this.topIconSwitch(2)
          if(data.access_type == 3) this.topIconSwitch(1)
        }

        if(data.notes) this.additionalInfo = data.notes

        if (data.time_window_start) {
          this.installationDateToApi = new Date(data.time_window_start)
          let starttime = data.time_window_start
          starttime = starttime.replace(' ', 'T')
          this.installationDateToApi = new Date(starttime)

          this.installHours = this.datePipe.transform(this.installationDateToApi, 'HH')
          this.installMinutes = this.datePipe.transform(this.installationDateToApi, 'mm')
        } else {
          this.installHours = '00'
          this.installMinutes = '00'
        }


        this.meterNumberSpinner = false;

        if (data.access_type_notes) {
          this.accessTypeInfo = data.access_type_notes;
        }
        if (data.story) {
          this.story = Number(data.story);
        }
        if (data.placement) {
          this.accessTypeInfo = data.placement;
        }
      }
    )
  }

  // Check the values of user selections in meter tab. If final reading is out of range give user message in interface
  // telling that he needs to adjust values or select reason for wrong reading
  checkValuesOldMeter() {
    this.saveOldMeter = true
    let areValuesSet = true;
    // Check if meter number is different than null
    if (this.meterNumber != null) {
      // Check that meter number's checkbox has been checked meaning meter number matches from software and site
      // IF does not match run this, setting areValuesSet to false
      if (this.meterNumberCheckbox != true) {
        areValuesSet = false;
        this.meterNumberNotCheckedOnSave = true;
      } else {
        this.meterNumberNotCheckedOnSave = false;
      }
    }
    // If reason selected is false or final reading's reason is no reason run this
    if (this.reasonSelected === false) {
      // Check if final reading value is out of range
      if (this.finalReadingValue > this.finalReadingRangeTop || this.finalReadingValue < this.finalReadingRangeBottom) {
        this.rangeIsWrong = true;
        areValuesSet = false;
      } else {
        this.rangeIsWrong = false
      }
    } else if (this.outOfRangeReason.openCustom === true && this.customReason === "") {
      areValuesSet = false
    }
    if (!this.fuseNotAvailable) {
      if (this.selectedFuseType == null) {
        areValuesSet = false
        this.fuseTypeNotSelectedOnSave = true;
      }
      else {
        this.fuseTypeNotSelectedOnSave = false
      }
      if (this.sliderValue == 81) {
        areValuesSet = false
      }
      if (this.selectedFuseType) {
        if (this.selectedFuseType.id === 2 && !this.fuseType) areValuesSet = false
      } else areValuesSet = false
    }

    if (!this.images[0].data || !this.images[1].data || !this.images[2].data ) {
      areValuesSet = false
      this.firstTabImgCheck = true
    }

    // Check if areValuesSet and spinner is not spinning, meaning we can move to next tab
    if (areValuesSet == true && this.meterNumberSpinner == false) {
      if (this.reasonSelected == false || (this.finalReadingValue <= this.finalReadingRangeTop && this.finalReadingValue >= this.finalReadingRangeBottom)) {
        this.rangeIsWrong = false
      }
      // Move to next tab
      this.tabSwitch(2)
    }
  }

  /**
   * Check values for new meter tab (2) and if everything passes move to next tab. If fails, show red borders around failed fields.
   */
  checkValuesNewMeter() {
    this.newMeterOnSave = true;
    let areValuesSet = true
    if (!this.newMeterNr) areValuesSet = false
    if (!this.newMeterReading && this.newMeterReading != 0) areValuesSet = false
    if (!this.images[3].img || !this.images[4].img || !this.images[5].img ) {
      areValuesSet = false
      this.secondTabImgCheck = true
    }
    if (this.antenna) {
      if (!this.antModel) areValuesSet = false
      if (!this.antManufacturer) areValuesSet = false
      if (!this.antModelNr) areValuesSet = false
      if (!this.antSerialNr) areValuesSet = false
      if (!this.antSignal) areValuesSet = false
      if (!this.images[6].data) {
        areValuesSet = false
        this.secondTabImgCheck
      }
    }
    if (areValuesSet) {
      this.newMeterOnSave = false
      this.tabSwitch(3)
    }
  }

  /**
   * Check values for additional tab. Make saveAdditional true so we can show error messages and red borders for user if
   * information is missing. Make big save api call if all checks pass
   */
  checkValuesAdditional() {
    this.saveAdditional = true;
    let areValuesSet = true;
    if (!this.accessTypeInfo) areValuesSet = false
    if (this.location && (this.latitude == undefined || this.longitude == undefined)) areValuesSet = false

    // Check for extra works
    if (this.extraWorkType) {
      if (this.extraWorkType.openCustom === true)
        if (this.extraTime === 0 || !this.otherWorkType || !this.images[7].data) {
          areValuesSet = false
          this.extraWorkImgCheck = true
        }
    }


    if (!this.images[8].data ) {
      areValuesSet = false
      this.thirdTabImgCheck = true
    }

    // We check if installation time is untouched, if untouched this will be skipped.
    if(!this.timeUntouched) {
      if(!this.switchedTime) {
        Swal.fire(
          this.translateService.instant('fieldReporting.report.swal.installationTimeSpecified'),
          '',
          'warning'
        )
        areValuesSet = false
        return
      }
      // const date variable is the new datetime for installation time if installation time is "dirty"
      const date = new Date(this.switchedDate)
      let time = this.switchedTime.split(':')
      let hours = time[0]
      let minutes = time[1]

      date.setHours(0,0,0,0)
      date.setHours(hours, minutes, 0)

      this.installationDateToApi = date

    }
    if (areValuesSet) {
      this.saveAdditional = false
      this.saveReport()
      //this.backToDashboard()
    }
  }

  /**
   * Update extra work type, also show custom extra work input if needed
   * @param id extra work type's id that is clicked in UI
   */
   updateExtraWorkType(id) {
    const currentWorkType = this.extraWorkTypes.find(workType => workType.id === id)
    if (currentWorkType) {
      this.extraWorkType = currentWorkType
      if (currentWorkType.openCustom === true) this.showOtherWorkType = true
      else this.showOtherWorkType = false
    }
  }

  /**
   * Find correct reason from out of range reasons array
   * @param id id of selected reason
   */
  selectReason(id) {
    this.reasonSelected = true
    const reason = this.outOfRangeReasons.find(reason => reason.id == id)
    if (reason !== undefined) {
      this.outOfRangeReason = reason
      if (reason.openCustom === true) this.showReasonsCustom = true
      else this.showReasonsCustom = false
    }

  }

  /**
   * Logic to change opacity and disabled status of certain elements in meter tab window.
   */
  changeOpacity() {
    if (this.meterTabOpacity == 0.4) {
      this.meterTabOpacity = 1.0
      this.isMeterChecked = true;
    }
    else {
      this.meterTabOpacity = 0.4
      this.isMeterChecked = false
    }
  }

  // Back to dashboard function that we use in first tab if user presses back button.
  // Set meter id to queryParams so we can access it in dashboard component directly from url
  backToDashboard() {
    const queryParamsValue: Params = { meterId: this.id };
    this.router.navigate(['field-reporting/dashboard'],{
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }

  /**
   * Find current fuse type and update selectedFuseType variable. If openCustom property is true for
   * selected fuseType show custom fuse type input field.
   * @param id selected fuse type id
   */
  selectFuseType(id) {
    const currentFuseType = this.fuseTypes.find(fuseType => fuseType.id === id)
    if (currentFuseType !== undefined) {
      this.selectedFuseType = currentFuseType
      this.isFuseTypeSelected = true
      if (currentFuseType.openCustom === true) this.showFuse = true
      else this.showFuse = false
    }
  }

  // Handle clicking not available checkbox
  // Hide warning message of fues type selection
  notAvailableFuse() {
    if (this.fuseNotAvailable == false) {
      this.selectedFuseType = null
      this.fuseTypeNotSelectedOnSave = false;
    }
  }

  interrupt() {
    let url = '/field-reporting/interrupt/' + this.id
    this.router.navigate([url])
  }


  /**
   * Prepares formData from jsonObjects and sends it to backend
   *
   * @edit 9.12.2022
   * Added new logic to pictures
   * Now uses "images" array instead of single "img0data" and "img1data"
   * @author Jesse Lindholm
   */
  saveReport() {

    const formData = new FormData()

    let sendingFuseType: string | null = null
    if (this.selectedFuseType) {
      if (this.selectedFuseType.id === 2) sendingFuseType = this.fuseType
      else sendingFuseType = this.selectedFuseType!.name
    }

    // Put status according to extra work or no extra work is selected
    let status
    if (this.extraTime && this.images[7].img) status = 5
    else status = 4
    // TODO: Korjaa status vastaamaan todellisuutta. Tällä hetkellä 4 vastaa valmista, mutta käyttäjä voi määritellä statuksia. Eli
    // status ei saisi olla kovakoodattu, vaan se pitäisi tulla jostain esim. state-taulukosta.

    let jsonData = {
      "fuse_reading": this.sliderValue,
      "fuse_limit": this.sliderMaxValue,
      "fuse_type": sendingFuseType,

      "old_device_reading": this.finalReadingValue,

      "new_device_id": this.newMeterNr,
      "new_device_reading": this.newMeterReading,

      "access_type": this.accessType,
      "access_type_notes": this.accessTypeInfo,
      "story": this.story,
      "placement": this.placementText,
      "status": status,
      "notes": this.additionalInfo,
      "attend_time": this.datePipe.transform(this.installationDateToApi, 'yyyy-MM-dd HH:mm', 'UTC'),
      "attendee": this.reporterId

    }

    let imageData = [
      {
      "image_place": 0,
      "title": this.images[0].data.name,
      "image_key": 'img0'
    },
    {
      "image_place": 1,
      "title": this.images[1].data.name,
      "image_key": 'img1'
    },
    {
      "image_place": 2,
      "title": this.images[2].data.name,
      "image_key": 'img2'
    },
    {
      "image_place": 3,
      "title": this.images[3].data.name,
      "image_key": 'img3'
    },
    {
      "image_place": 4,
      "title": this.images[4].data.name,
      "image_key": 'img4'
    },
    {
      "image_place": 5,
      "title": this.images[5].data.name,
      "image_key": 'img5'
    },
    {
      "image_place": 8,
      "title": this.images[8].data.name,
      "image_key": 'img8'
    }]

    // First tab required images
    formData.append('img0', this.images[0].data as any)
    formData.append('img1', this.images[1].data as any)
    formData.append('img2', this.images[2].data as any)

    // Second tab required images
    formData.append('img3', this.images[3].data as any)
    formData.append('img4', this.images[4].data as any)
    formData.append('img5', this.images[5].data as any)
    formData.append('img8', this.images[8].data as any)

    if(this.location) {
      let coordinateData =  {
        'coordinates': `{ "lat": ${this.latitude}, "lon": ${this.longitude} }`
      }
      jsonData = Object.assign(jsonData, coordinateData)

    }

    /**
     * If extra time has been set, add extra time data to reportdata and extraImage to imageData
     */
    if(this.extraTime && this.images[7].data && this.extraWorkType?.openCustom === true) {
      let extraWorkData = {
        'extra_work': `{ "type": ${this.extraWorkType.name}, "time": ${this.extraTime}, "name": ${this.otherWorkType} }`

      }

      let extraImg = {
        "image_place": 7,
        "title": this.images[7].data.name,
        "image_key": 'img7'
      }

      jsonData = Object.assign(jsonData, extraWorkData)
      imageData.push(extraImg)

      formData.append('img7', this.images[7].data as any)

    }

    // sekä additional info
    //this.additionalInfo

    if(this.antenna && this.images[6].data) {
      let antennaImg = {
        "image_place": 6,
        "title": this.images[6].data.name,
        "image_key": 'img6'
      }
      imageData.push(antennaImg)
      formData.append('img6', this.images[6].data as any)

      let antennaData = {
        "antenna_model": this.antModelNr,
        "antenna_manufacturer": this.antManufacturer,
        "antenna_model_number": this.antModelNr,
        "antenna_serial_number": this.antSerialNr,
        "antenna_signal": this.antSignal
      }

      jsonData = Object.assign(jsonData, antennaData)
    }

    formData.append('images', JSON.stringify(imageData) as any)
    formData.append('reportdata',  JSON.stringify(jsonData) as any)

    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          if(result) {
            Swal.fire({
              title: this.translateService.instant('fieldReporting.shared.swal.success'),
              text: this.translateService.instant('fieldReporting.report.swal.reportSuccessText'),
              icon: 'success',
              confirmButtonColor: '#3085d6',

            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                this.backToDashboard()
              }
            })
          } else {
            Swal.fire(
              this.translateService.instant('fieldReporting.report.swal.error'),
              this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
              'error'
            )
          }
        }
      );



  }

  /**
   * This function is called when the user wants to make changes to installation time.
   * The time is "dirty" if it has been altered and this leaves a mark.
   */
  makeTimeDirty() {
    Swal.fire({
      title: this.translateService.instant('fieldReporting.shared.swal.adjust'),
      text: this.translateService.instant('fieldReporting.shared.swal.adjustText'),
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
      denyButtonText: this.translateService.instant('basic.no')
    }).then((result) => {
      if (result.isConfirmed) {
        this.timeUntouched = false
        const date = this.installationDate
        this.switchedDate = new Date(date).toISOString().split('T')[0]
        this.switchedTime = this.installHours+':'+this.installMinutes
      }
    })
  }

/**
 * This function prompts user geolocation and acts on has a callback on success to set marker to his position
 */
  locationCheck() {
    // We return dont prompt geolocation if the user disables the location tab
    if(this.location) return
    this.map.locate({
      setView : true,
      maxZoom: 15
    }).on('locationfound', (e) => {
      var marker = L.marker([e.latitude, e.longitude], {icon: this.defaultMarker});
      this.latitude = e.latitude
      this.longitude = e.longitude
      this.map.addLayer(marker);
    }).on('locationerror', () => {
          alert(this.translateService.instant('fieldReporting.shared.locationAccessError'))
          this.location = false
    });
  }
}