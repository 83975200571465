<div class="container wider-container">
  <div class="row">
    <div class="col-md-12 offset-md-0">
      <div class="bg-white p-4">
        <div class="d-flex justify-content-between">
          <h5 [hidden]="tab != 1">{{ 'call-service.shared.searchCustomers' | translate }}</h5>
          <h5 [hidden]="tab != 2">{{ 'call-service.shared.callingList' | translate }}</h5>
          <h5 [hidden]="tab != 3">{{ 'basic.logOut' | translate }}</h5>

          <div class="d-flex">
            <div [ngClass]="tab == 1 ? 'blue-select' : 'gray-select'" (click)="switchTab(1)">
              {{ 'call-service.shared.searchCustomers' | translate }}
            </div>
            <div [ngClass]="tab == 2 ? 'blue-select' : 'gray-select'" class="ms-2" (click)="switchTab(2)">
              {{ 'call-service.shared.callingList' | translate }}
            </div>
            <div [ngClass]="tab == 3 ? 'blue-select' : 'gray-select'" class="ms-2" (click)="switchTab(3)">
              {{ 'basic.logOut' | translate }}
            </div>
          </div>
        </div>
        <div [hidden]="tab != 1">
          <div class="form-group mt-4">
            <div class="d-flex flex-column">
              <div class="control-label">{{ 'call-service.dashboard.searchCustomer' | translate }}</div>
              <div class="d-flex flex-column">
                <div class="mt-1">
                  <input type="text" id="search-input"
                  placeholder="{{ 'call-service.dashboard.searchCustomer' | translate }}" [(ngModel)]="searchWord"
                  class="form-control max-width-50">
                </div>
                <div ngbDropdown class="mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color wrap" id="dropdownInputType"
                    ngbDropdownToggle>{{ selectedInputType.text }}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownInputType">
                    <div *ngFor="let inputType of inputTypes">
                      <button ngbDropdownItem type="button" (click)="changeInputType(inputType)">{{ inputType.text
                        }}</button>
                    </div>
                  </div>
                </div>
                <!-- Project dropdown -->
                <div ngbDropdown class="mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color wrap" id="dropdownProjectTab1"
                    ngbDropdownToggle>
                    {{ getSelectedProjectNames() || 'Select Projects' }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownProjectTab1">
                    <div *ngFor="let project of projects">
                      <label class="dropdown-item">
                        <input type="checkbox" [ngModel]="isProjectSelected(project)"
                          (ngModelChange)="toggleProjectSelection(project)">
                        {{ project.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Week dropdown -->
                <div ngbDropdown class="mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color wrap" id="dropdownWeek"
                    ngbDropdownToggle>
                    {{ selectedWeek && selectedWeek.number !== 0 ? selectedWeek.number + ' - ' + selectedWeek.label :
                    'All weeks' }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownWeek" class="week-dropdown-menu">
                    <div class="week-dropdown-item" *ngFor="let week of weeks"
                      [class.active]="week.label === selectedWeek?.label">
                      <button ngbDropdownItem type="button" (click)="selectWeek(week)">
                        {{ week.number !== 0 ? week.number + ' - ' + week.label : 'All weeks' }}
                      </button>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="searchCustomer()">{{
                'call-service.dashboard.search' | translate }}</button>
              </div>
            </div>
          </div>
          <load-spinner [hidden]="!listSpinner"></load-spinner>
          <div class="shadow max-width-enduser-list-tab1" [hidden]="listSpinner || searchResults.length == 0">
            <div>
              <div class="border-all d-flex p-2 item-hover">
                <div class="col-3"><b>{{ 'basic.name' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.address' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.meteringPointAddress' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.phoneNumber' | translate }}</b></div>
              </div>
            </div>
            <div *ngFor="let item of searchResults; let i = index">
              <a (click)="moveToEnduserPage(item.id, item.workorder_id)">
                <div [ngClass]="i == 0 ? 'border-all' : 'border-no-top'" class="d-flex p-2 item-hover">
                  <div class="col-3 wrap">{{ item.contact_person_first }} {{ item.contact_person_last }}</div>
                  <div class="col-3 wrap">{{ item.address }}</div>
                  <div class="col-3 wrap">{{ item.woaddress }}</div>
                  <div class="col-3 wrap" type="tel">{{ item.phone }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div [hidden]="tab != 2">
          <div class="mt-3">
            <button class="btn btn-primary" (click)="downloadCallingCSVForAll()">{{
              'call-service.dashboard.downloadCSV' | translate }}</button>
          </div>
          <div class="mt-2">
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownProject"
                ngbDropdownToggle>{{ selectedProjectFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownProject">
                <div *ngFor="let project of projectsFilterGroup">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('project', project, true)">{{ project.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block mx-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownMSA"
                ngbDropdownToggle>{{ selectedMSAFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownMSA">
                <div *ngFor="let msa of msaFilterGroup | filterMsaByProject:selectedProjectFilter.id">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('msa', msa, true)">{{ msa.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block mx-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownAccessType"
                ngbDropdownToggle>{{ selectedAccessTypeFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAccessType">
                <div *ngFor="let accessType of accessTypeFilterGroup | filterAccessTypeByProject:selectedProjectFilter.id">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('access-type', accessType, true)">{{ accessType.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block mx-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownEnduserType"
                ngbDropdownToggle>{{ selectedEnduserTypeFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAccessType">
                <div *ngFor="let enduserType of enduserTypes">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('enduser-type', enduserType, true)">{{ enduserType.name}}</button>
                </div>
              </div>
            </div>
            <div class="mt-2 d-flex">
              <input class="form-control w-max" placeholder="{{'call-service.dashboard.filterByName' | translate}}" [(ngModel)]="filterName" (change)="filterCallingList(null, null, true)">
              <button class="btn btn-primary ms-1" (click)="filterCallingList(null, null, true)">{{'call-service.dashboard.filterByName' | translate}}</button>
            </div>
          </div>
          <app-call-log
          [title]="('call-service.dashboard.mandatoryCalls' | translate )"
          [callingList]="mandatoryCallingList"
          ></app-call-log>
          <app-call-log
          [title]="('call-service.dashboard.callingList' | translate )"
          [callingList]="callingList"
          ></app-call-log>
          <app-call-log
          [title]="('call-service.dashboard.timePassed' | translate)"
          [callingList]="timePassedCallingList"
          ></app-call-log>
        </div>
        <button class="btn btn-primary" [hidden]="tab != 3" (click)="logout()">{{ 'basic.logOut' | translate }}</button>
      </div>
    </div>
  </div>
</div>
