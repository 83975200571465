import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EndUserService } from '@modules/end-user/services/end-user.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Logger } from "sass";

@Component({
  selector: 'app-confirm-slot',
  templateUrl: './confirm-slot.component.html',
  styleUrls: ['./confirm-slot.component.scss']
})

/**
 * This class handless all the stuff for the enduser after he logs in.
 * This is the main component for end-user.
 * This component does not render and returns to login view if no location number or password has been exported from end-user-login component.
 */
export class ConfirmSlotComponent implements OnInit {

  userValues: any
  workOrderId: any
  startTime: any
  endTime: any
  locationNr: any
  address: any
  phoneNr: string = ""
  calendarStart: any
  calendarEnd: any
  changeTime = false
  confirmed = false
  time_estimate: any
  slots: any
  chosenSlot: any
  currentCalendar: any
  currentSlot: any
  invalidPhoneNumber: boolean = false
  project_type: number = 0

  slotDays = {}

  // checking phone number
  countryCodesList = [
    '93', '355', '213', '1-684', '376', '244', '1-264', '672', '1-268', '54', '374', '297', '61', '43', '994', '1-242', '973', '880', '1-246', '375', '32', '501', '229', '1-441', '975', '591', '387', '267', '55', '246', '1-284', '673', '359', '226', '257', '855', '237', '1', '238', '1-345', '236', '235', '56', '86', '61', '61', '57', '269', '682', '506', '385', '53', '599', '357', '420', '243', '45', '253', '1-767', '1-809, 1-829, 1-849', '670', '593', '20', '503', '240', '291', '372', '251', '500', '298', '679', '358', '33', '689', '241', '220', '995', '49', '233', '350', '30', '299', '1-473', '1-671', '502', '44-1481', '224', '245', '592', '509', '504', '852', '36', '354', '91', '62', '98', '964', '353', '44-1624', '972', '39', '225', '1-876', '81', '44-1534', '962', '7', '254', '686', '383', '965', '996', '856', '371', '961', '266', '231', '218', '423', '370', '352', '853', '389', '261', '265', '60', '960', '223', '356', '692', '222', '230', '262', '52', '691', '373', '377', '976', '382', '1-664', '212', '258', '95', '264', '674', '977', '31', '599', '687', '64', '505', '227', '234', '683', '850', '1-670', '47', '968', '92', '680', '970', '507', '675', '595', '51', '63', '64', '48', '351', '1-787, 1-939', '974', '242', '262', '40', '7', '250', '590', '290', '1-869', '1-758', '590', '508', '1-784', '685', '378', '239', '966', '221', '381', '248', '232', '65', '1-721', '421', '386', '677', '252', '27', '82', '211', '34', '94', '249', '597', '47', '268', '46', '41', '963', '886', '992', '255', '66', '228', '690', '676', '1-868', '216', '90', '993', '1-649', '688', '1-340', '256', '380', '971', '44', '1', '598', '998', '678', '379', '58', '84', '681', '212', '967', '260', '263'
  ]

  constructor(
    private router: Router,
    private endUserService: EndUserService,
    private translateService: TranslateService
  ) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation()!.extras.state!) {
      this.userValues = this.router.getCurrentNavigation()!.extras.state!.userValues
      this.workOrderId = this.router.getCurrentNavigation()!.extras.state!.id
      this.locationNr = this.userValues.location_number
    }
  }

  ngOnInit(): void {
    if (!this.userValues) {
      this.router.navigate(['/confirm'])
    } else {

      this.endUserService.getWorkOrder(this.workOrderId, this.userValues).subscribe(data => {
        this.confirmed = data.time_confirmed!
        this.phoneNr = data.contact_info
        this.startTime = data.time_window_start
        this.endTime = data.time_window_end
        this.project_type = data.project_type
        this.time_estimate = data.time_estimate
        this.setAddress(data.enduser_id)


      }).add(() => {
        this.endUserService.getCalendarByWorkOrder(this.workOrderId, this.userValues).subscribe(d => {

          this.slots = d.calendar.slots
          this.currentCalendar = d.calendar
          this.currentSlot = d.currentslot
          this.calendarStart = new Date(d.calendar.startdate).toDateString()
          this.calendarEnd = new Date(d.calendar.enddate).toDateString()

          this.setupDateArray()
        })
      })
    }
  }

  /**
   * This function is called when the user wants to confirm the original time slot that he/she was given.
   */
  // @ts-ignore
  confirmWorkOrder() {

    if (this.invalidPhoneNumber || this.phoneNr === '') {
      Swal.fire({
        title: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberTitle'),
        text: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberText'),
        confirmButtonText: this.translateService.instant('basic.ok'),
        showCancelButton: false
      })
      return false
    }

    Swal.fire({
      title: this.translateService.instant('endUserConf.confirmTime'),
      text: this.returnDate(this.startTime) + ' ' + this.returnTime(this.startTime) + '-' + this.returnTime(this.endTime),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.confirm')
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.endUserService.confirmWorkOrder(this.workOrderId, this.phoneNr, this.userValues)
      }
    })
  }

  /**
   * This function is called when the user wants to change the original time that has been given to him/her
   */
  // @ts-ignore
  confirmWorkOrderWithChange() {

    if (this.invalidPhoneNumber) {
      Swal.fire({
        title: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberTitle'),
        text: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberText'),
        confirmButtonText: this.translateService.instant('basic.ok'),
        showCancelButton: false
      })
      return false
    }

    if (this.chosenSlot) {
      Swal.fire({
        title: this.translateService.instant('endUserConf.confirmSelection'),
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('basic.confirm'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.endUserService.confirmWithChange(this.chosenSlot, this.workOrderId, this.phoneNr, this.userValues).subscribe(data => {
          }).add(() => {
            this.endUserService.confirmWorkOrder(this.workOrderId, this.phoneNr, this.userValues)
          })
        }
      })
    }
  }

  /**
   * Goes through users array and sets the address according to the first user.
   * @param users
   */
  setAddress(users) {
    if (users.length > 0) {
      const element = users[0];
      if (element.address) {
        this.address = element.address
      }
    }
  }

  backToConfirm() {
    this.changeTime = false
  }

  changeSlot() {
    this.changeTime = true
    this.selectSlot(this.currentSlot)
  }

  /**
   * Selects the right slot (visually) when the user clicks on any unselected slot.
   * @param id
   */
  selectSlot(id) {

    const oldElem = document.getElementsByClassName('chosen-slot');

    for (let i = 0; i < oldElem.length; i++) {
      const element = oldElem[i];
      element.classList.remove('chosen-slot')
    }

    const elem = document.getElementById(id);
    elem!.classList.add('chosen-slot')

    this.chosenSlot = id
  }

  /**
   * This function builds the slotDay object of arrays that is rendered with ngFor
   */
  setupDateArray() {

    // First for loop builds the keys for the slotDays object and fills empty arrays as values.
    for (const key in this.slots) {
      if (Object.prototype.hasOwnProperty.call(this.slots, key)) {
        const element = this.slots[key];

        let slotLength = (new Date(element.endtime).getTime() - new Date(element.starttime).getTime()) / 1000 / 60
        let workerscount = this.currentCalendar?.workerscount !== undefined ? this.currentCalendar.workerscount : 1
        let teamEfficiency = this.currentCalendar?.teamefficiency !== undefined ? this.currentCalendar.teamefficiency : 1
        slotLength = slotLength * (workerscount * teamEfficiency)
        let slotMax = element.slot_max !== undefined && element.slot_max != null ? element.slot_max : this.currentCalendar?.slot_max_default
        if (!slotMax) { // In case of NaN
          slotMax = 100
        }
        slotLength = slotLength / 100 * slotMax
        slotLength = slotLength - element.fill
        // Disable if time estimate is greater than slot remaining time...
        // @ts-ignore
        if (this.time_estimate > slotLength) {
          // ...unless it's the active slot
          if (this.currentSlot != element.id) {
            element.disabled = 1
          }
        }
        // If disabled, don't add
        if (element.disabled != 1) {
          let starttime = new Date(element.starttime)
          starttime.setHours(0, 0, 0, 0)
          this.slotDays[starttime.getTime()] = []
        }
      }
    }

    this.slotDays = Object.keys(this.slotDays)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = this.slotDays[key];

        return accumulator;
      }, {});

    // This loop populates the empty arrays that were created in the first loop
    for (const key in this.slots) {
      if (Object.prototype.hasOwnProperty.call(this.slots, key)) {
        const slot = this.slots[key];
        let starttime = new Date(slot.starttime)
        starttime.setHours(0, 0, 0, 0)
        let startString = starttime.getTime().toString()

        for (const dateKey in this.slotDays) {
          if (Object.prototype.hasOwnProperty.call(this.slotDays, dateKey)) {
            if (slot.disabled != 1 && dateKey == startString) {
              this.slotDays[dateKey].push(slot)
            }
          }
        }
      }
    }
  }

  /**
   * Takes date as string and returns toDateString()
   * This is just to make sure every date has the same structure as we use them in slotDays object as keys.
   * @param dateString
   * @returns
   */
  returnDateString(dateString) {
    let date = new Date(parseInt(dateString))
    return date.toDateString()
  }

  /**
   * Takes date as string and returns a full date.
   * example return
   * ->   20.6.2022
   * @param dateString
   * @returns
   */
  returnDate(dateString) {

    let dateObject = new Date(dateString)

    let date = dateObject.getDate() + '.' + (dateObject.getMonth() + 1) + '.' + dateObject.getFullYear()

    return date
  }

  /**
   * Takes date as string and returns time of date.
   * example return
   * ->   08:55
   * @param dateString
   * @returns
   */
  returnTime(dateString) {
    let dateObject = new Date(dateString)

    let hours = dateObject.getHours().toString()
    let minutes = dateObject.getMinutes().toString()

    if (dateObject.getHours() < 10) {
      hours = '0' + hours
    }
    if (dateObject.getMinutes() < 10) {
      minutes = '0' + minutes
    }


    let time = hours + ":" + minutes

    return time;
  }

  checkPhoneNumber() {
    let phoneNumberCheck = this.phoneNr
    if (this.phoneNr.charAt(0) == '+') {
      phoneNumberCheck = this.phoneNr.substring(1)
    }
    this.invalidPhoneNumber = true
    if (this.phoneNr != "") {
      if (this.countryCodesList.some(countryCode => phoneNumberCheck.startsWith(countryCode))) {
        this.invalidPhoneNumber = false
      }
    } else {
      this.invalidPhoneNumber = false
    }
  }


  isSlotActive(slot: any): boolean {
    const now = new Date();
    const slotDate = new Date(slot.starttime);
    const cutoff = new Date(slotDate);
    cutoff.setDate(cutoff.getDate() - 1);
    cutoff.setHours(20, 0, 0, 0); // set to 8pm of the day before the slot

    const ret = now.getTime() < cutoff.getTime();
    return ret;
  }
}

