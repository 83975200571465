import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { baseUrl } from '@environments/environment';
import Swal from 'sweetalert2';
import { Language } from '@shared/models/languages';
import { TranslateService } from '@ngx-translate/core';
import languages from 'assets/language-list/language-list.json'


@Component({
  selector: 'app-end-user-login',
  templateUrl: './end-user-login.component.html',
  styleUrls: ['./end-user-login.component.scss']
})
/**
 * This class handles enduser login and password/location number export to end-user main component (confirm-slot.component)
 */
export class EndUserLoginComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  formGroup!: UntypedFormGroup
  role!: string
  passwordShow = false
  authFail = false

  // Interface language
  selectedLanguage: Language = {databaseLanguage: "", displayName: ""}

  interfaceLanguages: Array<Language> = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {
    this.initForm()
    this.translateService.get('endUser.defaultLang').subscribe(
      () => this.getLanguages()
    )
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * 9.1.2022
   * Set display names for interfaceLanguages object
   * @author Jesse Lindholm
   *
   * 16.11.2022
   * Added logic for using JSON-languages.
   * Use "No translation (db-language)", if no translation is found.
   * @author Jesse Lindholm
   */
  getLanguages() {
    languages.languages.forEach(language => {
      if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
      else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
    })
    if(this.route.snapshot.data['language']) {
      let lan = this.route.snapshot.data['language']
      let found = this.interfaceLanguages.some(item => item.databaseLanguage === lan);
      if(found) {
        this.changeLanguage(lan)
      }
    }
  }

  /**
   * 9.1.2022
   * User selection for change language
   * Change displayed language
   * Change translation language
   * @param databaseLanguage
   */
  changeLanguage(databaseLanguage: string) {
    if (databaseLanguage !== this.selectedLanguage.databaseLanguage) {
      for (let i = 0; i < this.interfaceLanguages.length; i++) {
        const language = this.interfaceLanguages[i];
        if (language.databaseLanguage === databaseLanguage) {
          this.selectedLanguage = language
          this.translateService.use(language.databaseLanguage)
        }

      }
    }
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      location_number: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    })
  }

  /**
   * Handles the login process for end-users.
   * If login api call succeeds, it will navigate the user to confirm-slot component with userValues.
   * userValues export is 110% IMPORTANT for confirm-slot to render anything!!!!
   */
  loginProcess() {
    if(this.formGroup.valid){
      this.authService.endUserLogin(`${baseUrl}/api/confirm`, this.formGroup.value)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(result => {
        if(result) {
          if(this.selectedLanguage.databaseLanguage == 'da') {
            this.router.navigate(['/konfirmere/installation-time'], { state: { userValues: this.formGroup.value, id: result['message'] } })
          } else {
            this.router.navigate(['/confirm/installation-time'], { state: { userValues: this.formGroup.value, id: result['message'] } })
          }
        } else {
          //console.error(result)
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('endUser.tryAgain'),
            text: this.translateService.instant('endUser.errorSwal'),
          })
        }
      })
    }
  }

  /**
   * Logic for hide-show password eye.
   */
  hideShowPassword() {
    let passwordField = document.getElementById("login-password");

    this.passwordShow = !this.passwordShow

    const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
    passwordField!.setAttribute("type", type);

  }

}
