import { Injectable } from '@angular/core';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { TranslateService } from '@ngx-translate/core';
import { Interrupt } from '@shared/models/interrupt';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterruptService {

  constructor(private plannerProjectService: PlannerProjectService, private translateService: TranslateService) {}

  getInterruptOptions(): Observable<Interrupt[]> {
    let userlanguage = localStorage.getItem('userlanguage');

    return this.plannerProjectService.getInterruptOptions(null, null).pipe(
      map((interrupts: any) => {
        if (Array.isArray(interrupts)) {
          interrupts.forEach(interrupt => {
            let interrupt_definition;
            let interruptdef = interrupt.definition || {};
            if (userlanguage) {
              interrupt_definition = interruptdef[userlanguage] ||
                interruptdef['en'] ||
                undefined;
            } else {
              interrupt_definition = interruptdef['en'] || undefined;
            }

            interrupt.definition = interrupt_definition;
          });
        } else interrupts = []
        let otherInterrupt: Interrupt = {id: undefined,  definition: this.translateService.instant('basic.other'), interrupt_code: '00', comment_required: false, image_required: false};
        interrupts = [...interrupts, otherInterrupt];
        return interrupts;
      }),
      catchError(error => {
        console.error('Error fetching interrupt options', error);
        return of([]);  // Return an empty array in case of error
      })
    );
  }
}
