<div class="page-container">
    <div class="page-heading">
        <h3>
            <img src="assets/icons/person_black_24dp.svg">
            <span class="page-title">{{'basic.callServiceManagement' | translate}}</span>
        </h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-4 form-group max-content">
                            <label class="gray-text">{{'callService.search' | translate}}</label><br>
                            <input type="text" [(ngModel)]="searchInput" placeholder="Name" class="form-control">
                        </div>
                        <div class="col-8 form-group text-right">
                            <button class="btn btn-primary text-white mt-4" id="newCallService" data-bs-toggle="modal" data-bs-target="#callServiceModal">+ {{'callService.newCallService' | translate}}</button>
                        </div>
                    </div>
                    <div class="row">
                        <table class="mt-5 min-width">
                            <thead>
                            <tr>
                                <th role="button" scope="col" [appSort]="callServices" data-order="desc" data-name="name" class="col-3 gray-text font-weight-normal min-width">
                                    <span>{{'callService.nameAndUsername' | translate}}</span>
                                    <img src="assets/icons/unfold_more_gray_24dp.svg">
                                </th>
                                <th scope="col" class="col-3 gray-text font-weight-normal min-width">{{'basic.tags' | translate}}</th>
                                <th scope="col" class="col-3 gray-text font-weight-normal min-width">{{'basic.projects' | translate}}</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="callServices.length > 0" class="min-width">
                            <tr *ngFor="let item of callServices | searchFilter: searchInput; let i = index"  class="border-bottom border-top border-gray">
                                <td class="col-3">
                                    <input type="checkbox" #checkboxes class="projectchecker" value="{{item.id}}" name="projectchecker" (change)="onChange(item.id)">
                                    <a href='#' class="" (click)="goToCallServiceDetail($event, item.id)">
                                        <div class="bold-text black-text col-10">
                                            <img *ngIf="!item.disabled" src="assets/icons/active.svg">
                                            <img *ngIf="item.disabled" src="assets/icons/not_active.svg">
                                            {{item.name}}</div>
                                    </a>
                                </td>
                                <td class="col-3">
                                    <div class="same-line">
                                        <div *ngFor="let tag of item.tags" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{tag}}</div>
                                    </div>
                                </td>
                                <td class="col-3">
                                    <div class="same-line">
                                        <div *ngFor="let project of item.projects" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{project}}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="callServices.length == 0" class="min-width">
                            <tr>
                                <td *ngIf="loading">{{'basic.loading' | translate}}</td>
                                <td *ngIf="!loading">{{'callService.noCallService' | translate}}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <div *ngIf="projects.length>0">
                            <div>{{'callService.addProject' | translate}}</div>
                            <select #projectselect (change)="onSelected(projectselect.value)" class="form-control w-auto">
                                <option>-{{'callService.selectProject' | translate}}-</option>
                                <option *ngFor="let item of projects" value="{{item.id}}">{{item.name}} {{item.id == currentProjectId ? ('callService.currentProject' | translate) : '' }}</option>
                            </select>
                            <br>
                            <button (click)="addProject()" class="btn btn-primary col-3">{{'callService.add' | translate}}</button>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="callServiceModal" tabindex="-1" aria-labelledby="callServiceModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-body padding-modal">
                                    <img src="assets/icons/person_black_24dp.svg" class="top-position-image">
                                    <span class="title ms-2">{{'callService.newCallService' | translate}}</span>
                                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                    <form ngNativeValidate (ngSubmit)="addNewCallService()">

                                        <div class="form-group mt-3 row">
                                            <div class="col-6">
                                                <label class="gray-text"><span class="mt-1">*</span>{{'basic.firstName' | translate}}</label><br>
                                                <input type="text" [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}" class="form-control " required>
                                            </div>
                                            <div class="col-6">
                                                <label class="gray-text"><span class="mt-1">*</span>{{'basic.lastName' | translate}}</label><br>
                                                <input type="text" [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                            </div>

                                        </div>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.email' | translate}}</label><br>
                                                    <input type="email"  [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.username' | translate}}</label><br>
                                                    <input type="text"  [(ngModel)]="username" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.password' | translate}}</label><br>
                                                    <input type="password" minlength="6"  [(ngModel)]="password" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.passwordAgain' | translate}}</label><br>
                                                    <input type="password" minlength="6"  [(ngModel)]="password2" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                    <span [hidden]="password == password2" class="red-text">{{'basic.passwordsDontMatch' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 form-group row">
                                            <div class="col-6">
                                                <p class="gray-text">{{'basic.projects' | translate}}</p>
                                                <select class="form-select" (change)="selectProject($event.target.value)">
                                                    <option [value]="">{{'callService.selectProjects' | translate}}</option>
                                                    <option *ngFor="let project of unselectedProjects" [value]="project.id">{{project.name}} {{project.id == currentProjectId ? ('callService.currentProject' | translate) : '' }}</option>
                                                </select>

                                                <div class="same-line mt-3">
                                                    <div  *ngFor="let project of selectedProjects" (click)="unselectProject(project.id)" style="cursor: pointer;" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{project.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 form-group row">
                                            <div class="col-6">
                                                <p class="gray-text">{{'basic.interfaceLanguage' | translate}}</p>
                                                <select class="form-select" (change)="languageChange($event.target.value)">
                                                    <option *ngFor="let language of interfaceLanguages" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-5">
                                            <button type="submit" id="modalSaveButton" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                            <button type="button" id="modalClose" class="btn btn-secondary ms-3 col-3" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



