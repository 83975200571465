import { Component, OnDestroy, OnInit, ElementRef, Renderer2, ViewChildren, RendererFactory2, QueryList } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//import 'leaflet.markercluster';
import 'leaflet-draw';
import { MapService } from '@modules/planner/services/map/map.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { forkJoin, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MsasService } from '@modules/planner/services/msas/msas.service';
import Swal from 'sweetalert2';
import 'leaflet.markercluster.layersupport';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from '@modules/planner/services/teams/teams.service';
import { HeaderSidebarService } from '@shared/services/header/headerSidebarService';

declare const L: any;
const lightColor = '#F4F7FC'

@Component({
  selector: 'app-weekly-planning-map',
  templateUrl: './weekly-planning-map.component.html',
  styleUrls: ['./weekly-planning-map.component.scss']
})
export class WeeklyPlanningMapComponent implements OnInit, OnDestroy {

  @ViewChildren('detailsRef') detailsRefs!: QueryList<ElementRef>;

  componentDestroyed$: Subject<boolean> = new Subject()
  map: any
  allMsaEstimates: any
  projectEstimates: any
  allEstimates: any
  projectId: any
  markersExplanations = Array()
  showExplanations = true;
  infoBox = "assets/icons/category_gray_24dp.svg"
  mapInfoBox: string = "assets/icons/aspect_ratio_24.svg"
  showMapSizes: boolean = false
  mapMode: number = 3
  spinner = true;
  teamId
  teamSize: number = 0
  teamEfficiency: number = 1.00
  calendarId
  tableSpinner = true;
  defaultMarker: string = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot_1">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot_1" data-name="Element + dot – 1" clip-path="url(#clip-Element_dot_1)">    <g id="Ellipse_696" data-name="Ellipse 696" transform="translate(4 4)" fill="none" stroke="#a2a2a2" stroke-width="3">      <circle cx="12" cy="12" r="12" stroke="none"/>      <circle cx="12" cy="12" r="10.5" fill="none"/>    </g>  </g></svg>'
  moreMarkersRemoved = false
  markerNames: Array<any> = []
  markerIds: Array<any> = []

  //selected stuff
  selectedTime = '0'
  selectedMeters = Array()
  selectedSpinner = false
  timeSubscriptions: Subscription = new Subscription()

  //statuses
  selectSlotMode = false
  bypassMode = false
  showHideMarkers = Array()
  selectSlotMultiple: boolean = false

  //calendar
  calendarData: any
  calendarDays: any
  calendarToRender = new Map<string, Array<any>>();
  slotRows = Array();
  firstDay: any
  lastDay: any
  weekNr: any
  slots: any
  originalCalendarData: any
  allCalendars;
  allSlots = Array()

  // Leaflet groups
  workorderGroup: any
  ordergroupGroup: any
  layerGroups: any
  markerClusterGroup: any

  // Msas
  msaId: number | null = null

  // draw stuff
  editableLayers: any
  drawControl: any
  drawEnabled: any
  drawCreatedCalled: boolean = false;
  showCancelCreate: boolean = false;

  // Add calendar
  tempSelectedMeters = Array()
  addPreviousLayer: boolean = false

  defaultmax = 1000
  slotToEdit: number = 0
  oldSlotValue: null | number = null
  oldSlotI: number = 0
  oldSlotIndex: number = 0

  openedSlotId: string | null = null;
  private renderer!: Renderer2;
  private unsubscribeClickOutside?: () => void;

  workList: any = []
  showWorkList: boolean = false


  constructor(
    private router: Router,
    private mapService: MapService,
    private workOrderService: WorkOrderService,
    private toastService: ToastService,
    private userService: UserService,
    private plannerProjectService: PlannerProjectService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private calendars: CalendarsService,
    private msas: MsasService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private teamsService: TeamsService,
    private rendererFactory: RendererFactory2,
    private headerSidebarService: HeaderSidebarService
  ) { }

  ngOnInit(): void {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.route.paramMap.subscribe(pmap => {
      this.calendarId = pmap.get('id')
    })
    this.layerGroups = {}
    this.getMsaEstimates(() => {
      this.getProjectInfo()
    })
    this.initMap()
    this.mapMode = parseInt(localStorage.getItem('mapMode') || '3');
    if (this.mapMode === 1) {
      let sidebar = document.getElementById('sidebar');
      // Check if 'sidebar' exists and has the 'active' class
      if (sidebar && sidebar.classList.contains('active')) {
        this.headerSidebarService.toggle()
        this.renderer.removeClass(sidebar, 'active')
      }
    }
  }


  // Destroy subscriptions on this component when leaving page
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
    if (this.unsubscribeClickOutside) {
      this.unsubscribeClickOutside();
    }
  }

  /**
   * Gets all information of current project and inserts values to variables.
   * After the subscribe completes, calls other methods to run.
   */
  getProjectInfo() {
    this.getCurrentProjectId(() =>
      this.plannerProjectService.getProjectById(this.projectId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.projectEstimates = data.default_time_parameters
          this.allEstimates = data.all_estimates
          this.getCalendarById(this.calendarId, true)
        }
        )
    )
  }

  /**
   * Gets current project id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentProjectId(cb) {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.projectId = data.current_project
          // cb() = callback to let the other function know that this one is finished
          cb()
        }
      )
  }

  /**
   * Simple navigation back to dashboard page
   */
  backToDashboard() {
    this.router.navigate(['planner/weekly-planning'])
  }

  /**
   * Gets msaEstimates as an object
   */
  getMsaEstimates(cb) {
    let allMsaEstimates = {}
    this.workOrderService.getMsas()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            allMsaEstimates[data[i].id] = data[i].default_time_parameters
          }
          this.allMsaEstimates = allMsaEstimates
          cb()

        }
      )
  }

  /**
   * Initializes the map with custom settings
   */
  initMap() {
    // Sets initial canvas
    this.map = L.map('mapmap', { editable: true, })

    // Sets the zoom +/- controller to topright corner of the map
    this.map.zoomControl.setPosition('topright')

    // OpenStreetMap integration
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 19,
      minZoom: 5,
      detectRetina: true,
    }).addTo(this.map)

    // Centers on top of finland if user denies location permission
    this.map.on("locationerror", () => {
      this.map.panTo({ lat: 60.19, lon: 24.94 })
    });

    // Center on user on default
    this.map.locate({
      setView: true,
      maxZoom: 9
    });

    this.workorderGroup = L.markerClusterGroup().addTo(this.map)
    //this.layerGroups = L.markerClusterGroup().addTo(this.map)
    this.ordergroupGroup = L.featureGroup().addTo(this.map)
    this.markerClusterGroup = L.markerClusterGroup.layerSupport().addTo(this.map)

    this.editableLayers = new L.featureGroup();
    this.map.addLayer(this.editableLayers);

    let drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false,
        circlemarker: false
      },

      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: false,
        edit: false
      }

    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.drawControl = new L.Control.Draw(drawPluginOptions);
  }


  drawMarkersToSelection() {
    this.drawEnabled = new L.Draw.Polygon(this.map, this.drawControl.options.polygon);
    this.drawEnabled.enable();

    if (!this.drawCreatedCalled) {
      this.drawCreatedCalled = true;

      this.map.on('draw:created', (e) => {
        this.showCancelCreate = false;
        let layer = e.layer;
        this.editableLayers.addLayer(layer);
        let polygon = layer.getLatLngs();

        // Iterate over markers in the markerClusterGroup
        this.markerClusterGroup.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            // Handle individual marker
            let markerPosition = [layer.getLatLng().lat, layer.getLatLng().lng];
            if (this.inside(markerPosition, polygon) && layer.feature) {
              let fakeEvent = { target: layer };
              this.workorderClick(fakeEvent);
            }
          }
        });

        this.editableLayers.removeLayer(layer);
      });
    }
  }


  cancelDrawing() {
    this.drawEnabled.disable()
    this.showCancelCreate = false
  }


  /**
   * Function that is used to determine if a point resides inside given polygon
   * @param point location of point in map in coordinates
   * @param vs polygon area that we use to check if it has point inside it
   * @returns boolean value true/false depending is the point inside polygon
   */
  inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    let x = point[0], y = point[1];

    let inside = false;

    for (let i = 0, j = vs[0].length - 1; i < vs[0].length; j = i++) {
      let xi = vs[0][i].lat, yi = vs[0][i].lng;
      let xj = vs[0][j].lat, yj = vs[0][j].lng;

      let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

  /**
   * Activates clustergroup for workordergroup layer.
   */
  setLayersToClusterGroup() {
    for (const key in this.layerGroups) {
      if (Object.prototype.hasOwnProperty.call(this.layerGroups, key)) {
        const element = this.layerGroups[key];
        this.markerClusterGroup.addLayer(element)
      }
    }
  }

  /**
   * Handles the click event when clicking a workorder marker on map.
   * Builds an object consisting of icon(svg string), id and ordergroup_id
   * @param e
   */
  workorderClick = (e) => {

    let id = e.target.feature.properties.id
    let icon = e.target.options.icon.options.html
    let ordergroup_id = null
    if (e.target.feature.properties.groupId) ordergroup_id = e.target.feature.properties.groupId
    let oldDeviceId = e.target.feature.properties.old_device_id

    let object = {
      icon: icon.toString(),
      id: id,
      groupId: ordergroup_id,
      old_device_id: oldDeviceId
    }

    let alreadyInArray = false

    for (let i = 0; i < this.selectedMeters.length; i++) {
      const element = this.selectedMeters[i];

      if (element.id == id) {
        alreadyInArray = true
        this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.workorderSelected'))

      }

      if (element.workorders) {

        for (let x = 0; x < element.workorders.length; x++) {
          const workorder = element.workorders[x];

          if (workorder.id == id) {
            alreadyInArray = true
            this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.selectedOrdergroup'))
          }
        }
      }
    }

    // Run this if marker is not in selectedMeters array
    if (!alreadyInArray) {
      // Push object to selectMeters
      this.selectedMeters.push(object)
      // Loop through workorders group
      for (let key in this.layerGroups) {
        // Another loop for going through layers of workorders group
        for (let key2 in this.layerGroups[key]._layers) {
          // If id matches clicked elemenets id check does it exist in showHideMarkers array
          if (this.layerGroups[key]._layers[key2].feature.properties.id == id) {
            let boolean = false;

            for (let i = 0; i < this.showHideMarkers.length; i++) {
              if ("marker" in this.showHideMarkers[i]) {
                if (this.showHideMarkers[i].marker == this.layerGroups[key]._layers[key2]) {
                  boolean = true
                  break;
                }
              }
            }

            // if does not exist push it to showHIdeMarkers
            if (boolean == false) {
              let marker = { marker: this.layerGroups[key]._layers[key2], ordergroup: ordergroup_id }
              this.showHideMarkers.push(marker)
            }
            // Remove marker from map finally
            this.map.removeLayer(this.layerGroups[key]._layers[key2])
          }
        }
      }
    }

    this.countSelectedLength()
  }

  /**
   * Handles the click event when clicking an ordergroup marker on map.
   * Builds an object consisting of icon(img tag as string), id, ordergroup_id and a workorder object filled with workorders. (same type as in workorderClick)
   * Adds the ordergroup and its workorders to selectedMeters array.
   * @param e
   */
  orderGroupClick = (e) => {

    let id = e.target.feature.properties.id
    const identifier = e.target.feature.properties.identifier
    let iconUrl = '/' + e.target.options.icon.options.iconUrl

    let icon = '<img src="' + iconUrl + '">'

    let workorderArray = Array()

    let groupAlreadyInArray = false

    // Loops through workorders that belong to this ordergroup, gets the layer data from leaflet marker
    for (const key in this.layerGroups[id]._layers) {
      if (Object.prototype.hasOwnProperty.call(this.layerGroups[id]._layers, key) && this.layerGroups[id]._layers[key].feature.properties.inSlot == false) {
        const workorder = this.layerGroups[id]._layers[key];

        let workorderIcon = workorder.options.icon.options.html
        let workOrderId = workorder.feature.properties.id
        let alreadyInArray = false

        let workorderObject = {
          icon: workorderIcon.toString(),
          properties: e.target.feature.properties,
          id: workOrderId,
          groupId: id,
          identifier: identifier,
          old_device_id: workorder.feature.properties.old_device_id
        }

        for (let i = 0; i < this.selectedMeters.length; i++) {
          const element = this.selectedMeters[i];

          if (element.group && (element.groupId == id)) groupAlreadyInArray = true

          if (element.id == workOrderId) {
            this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.workorderSkipped1') + workOrderId + this.translateService.instant('planner.weeklyPlanningMap.workorderSkipped2') + id + this.translateService.instant('planner.weeklyPlanningMap.workorderSkipped3'))
            alreadyInArray = true

          }
        }
        if (!alreadyInArray) {
          workorderArray.push(workorderObject)

        }
      }
    }

    // Same object as in workorderClick, exept this has an array of workorder objects aswell
    let object = {
      icon: icon.toString(),
      groupId: id,
      workorders: workorderArray,
      identifier: identifier
    }

    if (groupAlreadyInArray) {
      this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.ordergroupSkipped1') + id + this.translateService.instant('planner.weeklyPlanningMap.ordergroupSkipped2'))
    } else {
      this.selectedMeters.push(object)
      // Loop through ordergroups
      // Another loop for going through layers of workorders group
      for (let key in this.ordergroupGroup._layers) {
        // If id matches clicked elemenets id check does it exist in showHideMarkers array
        if (this.ordergroupGroup._layers[key].feature.properties.id == id) {
          let boolean = false;

          for (let i = 0; i < this.showHideMarkers.length; i++) {
            if (this.showHideMarkers[i].ordergroupId == this.ordergroupGroup._layers[key]) {
              boolean = true
              break;
            }
          }

          // if does not exist push it to showHIdeMarkers
          if (boolean == false) {
            let ordergroup = { ordergroupId: this.ordergroupGroup._layers[key], group: object.groupId }
            this.showHideMarkers.push(ordergroup)
          }
          // Remove marker from map finally
          this.map.removeLayer(this.ordergroupGroup._layers[key])
          for (let key1 in this.layerGroups) {
            for (let key2 in this.layerGroups[key1]._layers) {
              if (this.layerGroups[key1]._layers[key2].feature.properties.groupId == object.groupId) {
                // Check if showHideMarkers contain marker that is added to
                let boolean2 = false

                for (let i = 0; i < this.showHideMarkers.length; i++) {
                  // If found make boolean true
                  if (this.showHideMarkers[i].marker == this.layerGroups[key1]._layers[key2]) {
                    boolean2 = true
                    break;
                  }
                }

                // If it does not exist add it to showHideMarkers array
                if (boolean2 == false) {
                  let marker = { marker: this.layerGroups[key1]._layers[key2], ordergroup: object.groupId, insideOrderGroup: true }
                  this.showHideMarkers.push(marker)
                }
                // Remove marker from map
                this.map.removeLayer(this.layerGroups[key1]._layers[key2])
              }
            }
          }
        }
      }

    }
    this.countSelectedLength()
  }

  /**
   * Removes specific workorder from selectedMeters
   * @param id
   */
  removeFromSelected(id) {
    for (let i = 0; i < this.selectedMeters.length; i++) {
      const element = this.selectedMeters[i];

      if (element.id == id) {
        this.selectedMeters.splice(i, 1)
      }
      if (element.groupId == id && element.id == null) {
        this.selectedMeters.splice(i, 1)
      }
    }

    // Go through showHideMarkers array to find correct marker or ordergroup and add it to map
    for (let i = 0; i < this.showHideMarkers.length; i++) {
      // If workorder run this
      if (this.showHideMarkers[i].marker) {
        if (this.showHideMarkers[i].marker.feature.properties.id == id) {
          this.layerGroups[-1].addLayer(this.showHideMarkers[i].marker)
          //this.showHideMarkers.splice(i, 1)
        }

      // If ordergroup run this
      // Need to add ordergroup a little different way than workorder
        let property = 'ordergroupId'
        if (this.showHideMarkers[i].hasOwnProperty(property)) {
          if (this.showHideMarkers[i].ordergroupId != null) {
            if (this.showHideMarkers[i].ordergroupId.feature.properties.id == id) {
              let markerLocation = new L.LatLng(this.showHideMarkers[i].ordergroupId._latlng.lat, this.showHideMarkers[i].ordergroupId._latlng.lng)
              let marker = new L.Marker(markerLocation, { icon: this.mapService.boltIcon }).on('click', this.orderGroupClick)

              // Adds custom feature to our marker
              marker.feature = {
                type: 'Feature',
                properties: {
                  id: this.showHideMarkers[i].ordergroupId.feature.properties.id,
                  msa: this.showHideMarkers[i].ordergroupId.feature.properties.msa,
                  tag: 'ordergroup'
                },
                geometry: undefined
              }

              this.ordergroupGroup.addLayer(marker)
              this.layerGroups[this.showHideMarkers[i].ordergroupId].addLayer(marker)
            }
          }


        }
        // If marker inside ordergroup
        if (this.showHideMarkers[i].marker && this.showHideMarkers[i].ordergroup == id && this.showHideMarkers[i].insideOrderGroup == true) {
          this.layerGroups[this.showHideMarkers[i].ordergroup].addLayer(this.showHideMarkers[i].marker)
        }

      }
    }
    this.countSelectedLength()
  }

  /**
   * Removes specific workorder from a selected ordergroup in selectedMeters
   * @param groupId
   * @param meterId
   */
  removeFromSelectedGroup(groupId, meterId) {

    for (let i = 0; i < this.selectedMeters.length; i++) {
      const element = this.selectedMeters[i];


      if (element.id == groupId) {
        for (let x = 0; x < this.selectedMeters[i].workorders.length; x++) {
          const meter = this.selectedMeters[i].workorders[x];

          if (meter.id == meterId) {
            this.selectedMeters[i].workorders.splice(x, 1)
            // Go through showHideMarkers array to add correct meter back to map after removing it from selected
            // Also remove it from showHideMarkers array so that we dont get duplicates
            for (let i = 0; i < this.showHideMarkers.length; i++) {
              if (this.showHideMarkers[i].marker) {
                if (this.showHideMarkers[i].marker.feature.properties.id == meterId) {
                  if (this.showHideMarkers[i].ordergroup) this.layerGroups[this.showHideMarkers[i].ordergroup].addLayer(this.showHideMarkers[i].marker)
                  else this.layerGroups[-1].addLayer(this.showHideMarkers[i].marker)
                  this.showHideMarkers.splice(i, 1)
                }
              }
            }
          }
        }
      }
    }

    this.countSelectedLength()
  }

  /**
   * Handles the show meters toggle dropdown icon on a selected ordergroup in selectedMeters
   * @param id
   */
  expandArrowListener(id) {
    var img = document.getElementById('arrowImg-' + id) as HTMLImageElement

    if (img.src.includes('expand_more_gray_24dp.svg')) {
      img.src = 'assets/icons/expand_less_gray_24dp.svg'

    } else if (img.src.includes('expand_less_gray_24dp.svg')) {
      img.src = 'assets/icons/expand_more_gray_24dp.svg'
    }
  }

  /**
   * Toggle marker explanation box on and off. Also change picture of button that toggles them
   */
  toggleMarkersExplanations() {
    // If clicked when not open apply this
    if (this.showExplanations) {
      this.showExplanations = false
      this.infoBox = "assets/icons/close_gray_24dp.svg"
    }
    // If clicked when open apply this
    else {
      this.showExplanations = true
      this.infoBox = "assets/icons/category_gray_24dp.svg"
    }
  }


  /**
   * Gets the calendar by id
   */
  getCalendars() {
    this.calendars.getCalendars()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.allCalendars = data
          this.getCalendarById(this.calendarId, true)
        }
      )
  }

  /**
   * Sets a calendars data by id to calendarData.
   * Sets teamId to be correct for weekly-planning-teams
   * @param id Calendar ID
   *
   * @edit 2.12.2022
   * Added api-call to get teams efficiency.
   * Added completeCalendarById function call.
   * @param value Boolean value, depends do we reset calendar or not
   */
  getCalendarById(id: number, value: boolean) {
    this.calendars.getCalendarById(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.clearMap()
          this.calendarData = data
          this.originalCalendarData = JSON.parse(JSON.stringify(data))
          this.msaId = data.msa_id
          // Get worklist data
          if (data.worklist) {
          this.workList = data.worklist.workorders
            if (this.workList.length > 0) {
              this.workList.forEach(element => {
                if (element.time_parameters) element.icon = this.getSvgIcon(element.time_parameters)
              });
            }
          } else this.workList = []
          if (data.teams) {
            if (data.teams.length > 0) {
              this.teamId = data.teams[0]
              this.teamsService.getTeamById(this.teamId).subscribe(
                data => {
                  if (data.efficiency) this.teamEfficiency = parseFloat(data.efficiency)
                  this.completeCalendarById(value)
                }
              )
            } else {
              this.teamId = null
              this.completeCalendarById(value)
            }
          } else {
            this.teamId = null
            this.completeCalendarById(value)
          }
        }
      )
  }

    /**
     * Complete another function called getCalendarById
     * @param value boolean value that represents do we reset calendar fully before getting new data or not
     */
    completeCalendarById(value: boolean) {
      if (!value) {
        this.calendarDays = null
        this.calendarToRender = new Map<string, Array<any>>()
        this.slotRows = Array()
        // Called in teamSizeHandler so that we have a teamsize before setting up the calendar
        this.setupCalendar(new Date(), this.calendarData.slots)
        this.getFreeMsaOrders()
      } else if (value) {
        this.setupCalendar(new Date(), this.calendarData.slots)
        this.getFreeMsaOrders()
      }
      this.tableSpinner = false;
    }

  clearMap() {
    if (this.workorderGroup && this.layerGroups) {
      this.workorderGroup.clearLayers()
      for (const key in this.layerGroups) {
        if (Object.prototype.hasOwnProperty.call(this.layerGroups, key)) {
          const element = this.layerGroups[key];
          element.clearLayers()
        }
      }
    }
    if (this.ordergroupGroup) this.ordergroupGroup.clearLayers()
  }

  teamSizeHandler(count: number) {
    if (this.teamSize) {
      this.teamSize = count;
      this.setupCalendar(new Date(), this.calendarData.slots)
    } else this.teamSize = count

  }

  /**
   * Initialize markers on map, both workorders and ordergroups. Ordergroups we get from data.ordergroups and workorders
   * from data.wprkorders.
   * Initialize SVG help window in bottom right corner of map.
   */
  getFreeMsaOrders() {
    // Reset explanations
    this.markersExplanations = []
    this.markerNames = []
    this.markerIds = []

    this.msas.getFreeOrdersMsa(this.msaId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          // Used by workorders without ordergroup
          this.layerGroups[-1] = L.layerGroup().addTo(this.map)

          // START OF ORDERGROUPS
          if (data.ordergroups) {
            Object.keys(data.ordergroups).forEach(key => {
              this.layerGroups[key] = L.layerGroup().addTo(this.map);
              let lon = ''
              let lat = ''
              // Try catch coordinates, every point in data might not have these
              try {
                lon = JSON.parse(data.ordergroups[key].coordinates).lon
                lat = JSON.parse(data.ordergroups[key].coordinates).lat
              } catch (error) {
                return
              }
              let markerId = key;
              const identifier = data.ordergroups[key].identifier
              let markerLocation = new L.LatLng(lat, lon);
              let marker
              let msaId = this.msaId;
              marker = new L.Marker(markerLocation, { icon: this.mapService.boltIcon }).on('click', this.orderGroupClick)
              // Adds custom feature to our marker
              marker.feature = {
                type: 'Feature',
                properties: {
                  id: markerId,
                  msa: msaId,
                  tag: 'ordergroup',
                  identifier: identifier
                },
                geometry: undefined
              }
              this.ordergroupGroup.addLayer(marker)
            })
          }
          // START OF WORKORDERS
          if (data.workorders.length === 0) {
            this.spinner = false
            this.markersExplanations.push({ marker: null, explanations: this.translateService.instant('planner.weeklyPlanningMap.empty') })
          }
          for (let i = 0; i < data.workorders.length; i++) {
            if(data.workorders[i]['state'] == 5) {
              continue // do not show on map
            }

            let lon = ""
            let lat = ""
            // Try catch coordinates, every point in data might not have these
            try {
              lon = JSON.parse(data.workorders[i].coordinates).lon
              lat = JSON.parse(data.workorders[i].coordinates).lat
            } catch (error) {
              continue
            }
            let timeEstim = []
            let msaTimeEstim = []
            if (data.workorders[i].time_parameters) {
              timeEstim = JSON.parse(data.workorders[i].time_parameters!)
            }
            if (this.msaId) {
              if (this.allMsaEstimates[this.msaId]) {
                msaTimeEstim = JSON.parse(this.allMsaEstimates[this.msaId])
                msaTimeEstim = msaTimeEstim[0]
              }
            }
            let projectDefaultEstimates
            try {
              if (this.projectEstimates) {
                projectDefaultEstimates = JSON.parse(this.projectEstimates)
              }
            } catch (error) {
              continue
            }
            let markerShape = null
            let markerColor = null
            let markerDots = null
            // This loop goes through project default time estimates to get possible shapes/colors of the marker
            for (let i in timeEstim) {
              const categoryid = timeEstim[i]['categoryid']
              const estimateid = timeEstim[i]['estimateid']
              for (let x in projectDefaultEstimates) {
                const element = projectDefaultEstimates[x];
                if (element['categoryid'] == categoryid) {
                  const highlighted = projectDefaultEstimates[x]['highlighted']
                  for (let index = 0; index < projectDefaultEstimates[x]['estimates'].length; index++) {
                    const timeEstimate = projectDefaultEstimates[x]['estimates'][index];
                    if (timeEstimate['estimateid'] == estimateid) {
                      // Push correct category id and estimate id to markerNames array
                      if (!this.markerIds.some(e => e.categoryId === element['categoryid'] && e.estimateid === timeEstimate['estimateid'])) {
                        this.markerIds.push({ categoryid: element['categoryid'], estimateid: timeEstimate['estimateid'] })
                      }
                      if (highlighted == 'shape') {
                        markerShape = timeEstimate['attribute']
                      } else if (highlighted == 'color') {
                        markerColor = timeEstimate['attribute']
                      } else if (highlighted == 'dots') {
                        markerDots = timeEstimate['attribute']
                      }
                    }
                  }
                }
              }
            }
            // This  goes through msa time estimates to get possible shapes/colors of the marker, and overwrites any shapes and colors that we set above
            if (this.msaId && msaTimeEstim) {
              for (let i in timeEstim) {
                const categoryid = timeEstim[i]['categoryid']
                const estimateid = timeEstim[i]['estimateid']
                if (msaTimeEstim['categoryid'] == categoryid) {
                  const highlighted = msaTimeEstim['highlighted']
                  if (msaTimeEstim['estimates']) {
                    for (let index = 0; index < msaTimeEstim['estimates'].length; index++) {
                      const timeEstimate = msaTimeEstim['estimates'][index];
                      if (timeEstimate['estimateid'] == estimateid) {
                        // Check if marker exist in marker names and push it to there if not
                        if (!this.markerIds.some(e => e.categoryId === msaTimeEstim['categoryid'] && e.estimateid === timeEstimate['estimateid'])) {
                          this.markerIds.push({ categoryid: msaTimeEstim['categoryid'], estimateid: timeEstimate['estimateid'] })
                        }
                        if (highlighted == 'shape') {
                          markerShape = timeEstimate['attribute']
                        } else if (highlighted == 'color') {
                          markerColor = timeEstimate['attribute']
                        } else if (highlighted == 'dots') {
                          markerDots = timeEstimate['attribute']
                        }
                      }
                    }
                  }
                }
              }
            }
            // Gets our marker icon from getGeneratedSvg method, returns default icon if null values are passed
            let svgString = this.mapService.getGeneratedSvg(markerShape, markerColor, markerDots)
            this.setMarkerExplanations(svgString)
            const markerIcon = L.divIcon({
              html: svgString,
              className: "",
              iconSize: [this.mapService.orderSize, this.mapService.orderSize],
              iconAnchor: [this.mapService.orderAnchor, this.mapService.orderAnchor],
              shadowSize: [this.mapService.orderShadowSize, this.mapService.orderShadowSize]
            });
            let markerId = data.workorders[i].id;
            let markerLocation = new L.LatLng(lat, lon)
            let marker = new L.Marker(markerLocation, { icon: markerIcon }).on('click', this.workorderClick)
            marker.feature = {
              type: 'Feature',
              properties: {
                id: markerId,
                groupId: data.workorders[i].ordergroup_id,
                tag: 'workorder',
                time_estimate: timeEstim,
                msaId: this.msaId,
                inSlot: false,
                old_device_id: data.workorders[i].old_device_id,
                status: data.workorders[i].status_id
              },
            }
            if (this.addPreviousLayer) {
              const existsComparison = (element) => element.id === markerId
              let exists = this.tempSelectedMeters.some(existsComparison)
              if (exists) marker.fire('click')
              else if (data.workorders[i].ordergroup_id) this.layerGroups[data.workorders[i].ordergroup_id].addLayer(marker)
              else this.layerGroups[-1].addLayer(marker)
            }
            if (!this.addPreviousLayer) {
              if (data.workorders[i].ordergroup_id) this.layerGroups[data.workorders[i].ordergroup_id].addLayer(marker)
              else this.layerGroups[-1].addLayer(marker)
            }
            // Adds custom feature to our marker
          } // For loop ends for workorder
          this.spinner = false
          // Sets clusters active. Not working with layergroups for some reason...
          this.setLayersToClusterGroup()
        }
      )
  }

  setMarkerExplanations(svgString) {
    let estimatesJSON = JSON.parse(this.allEstimates)
    let namesString = ""
    // Loop through category id's and estimate id's
    for (let l = 0; l < this.markerIds.length; l++) {
      for (let z = 0; z < estimatesJSON.length; z++) {
        // Compare category id's
        if (this.markerIds[l].categoryid == estimatesJSON[z].categoryid) {
          // After getting correct category id we loop through estimate id's for correct estimate to get
          // a name for estimate
          for (let j = 0; j < estimatesJSON[z].estimates.length; j++) {
            // Compare estimate id's to correct estimate id
            // If found push to names array the name of estimate
            if (estimatesJSON[z].estimates[j].estimateid == this.markerIds[l].estimateid) {
              this.markerNames.push(estimatesJSON[z].estimates[j].name)
              namesString = estimatesJSON[z].estimates[j].name
            }
          }
        }
      }
    }

    // Apply logic to markerNames array checking for correct category id's and estimate id's and pushing
    // correct names to names array that is used in markersExplanations array which is used in HTML to
    // display svg images and names of time estimates

    // Check if svg exists in array and add it to array if does not exist. Array is used to show pictures
    // of svg in interface
    let boolean = false
    if (this.markersExplanations.length > 0) {
      if (this.markersExplanations[0].explanations !== this.translateService.instant('planner.weeklyPlanningMap.empty')) {
        for (let x = 0; x < this.markersExplanations.length; x++) {
          // Compare marker and svgString also compare that marker is not default
          if (this.markersExplanations[x].marker.changingThisBreaksApplicationSecurity == svgString ||
            svgString === this.defaultMarker) {
            boolean = true
          }
        }
        // Push to new marker to array with explanation
        if (boolean === false) this.markersExplanations.push({ marker: this.sanitizer.bypassSecurityTrustHtml(svgString), explanations: namesString })

      }
    } else {
      if (namesString !== "") this.markersExplanations.push({ marker: this.sanitizer.bypassSecurityTrustHtml(svgString), explanations: namesString })
      else this.markersExplanations.push({ marker: this.sanitizer.bypassSecurityTrustHtml(svgString), explanations: "Default marker" })
    }

  }

  /**
   * Builds the stuff which we need to render the calendar week.
   * slotRows is the array that is used to render the slots.
   * @param startDate
   * @param endDate
   * @param slots
   */
  setupCalendar(startDate, slots) {
    this.slotRows = []
    this.calendarToRender.clear()

    let start = new Date(startDate);

    //check if startdate is a monday
    this.firstDay = this.getFirstDayOfWeek(start)
    let weekBegin = new Date(this.firstDay)
    this.lastDay = this.getLastDayOfWeek(start)

    this.setWeekFromDate(this.firstDay)

    let allDays = Array()

    // We use start so that we dont mutate the firstDay Date
    while (weekBegin <= this.lastDay) {
      let x = new Date(weekBegin.getTime())
      allDays.push(x)
      weekBegin.setDate(weekBegin.getDate() + 1)
    }
    // calendarDays holds all the days that contain a slot.
    this.calendarDays = allDays

    for (let i = 0; i < allDays.length; i++) {
      const element = allDays[i]

      let dateString = element.toLocaleDateString()

      let rowObject = Array()

      for (const slot in slots) {
        if (slots[slot].disabled === 0) slots[slot].color = 'white'
        let date = slots[slot].starttime
        let slotDateString = new Date(date).toLocaleDateString()

        if (slotDateString == dateString) {
          rowObject.push(slots[slot])
        }
      }
      rowObject.sort(this.compareSlots);
      // Sets data to map
      this.calendarToRender.set(dateString, rowObject)
    }

    // max slots is the maximum number of slots a day in a week can have
    let maxSlotsPerDay = 0
    this.calendarToRender.forEach(day => {
      if (day.length > maxSlotsPerDay) {
        maxSlotsPerDay = day.length
      }
    })


    // We fill all slots using maxSlots as a boundary, slots that dont have data appear empty
    for (let index = 0; index < maxSlotsPerDay; index++) {
      const slotRow = Array()

      this.calendarToRender.forEach(day => {
        if (day[index]) {
          if (day[index].workorders.length != 0) {
            this.putMarkersOnSlot(day[index])
          }

          slotRow.push(day[index])
        } else {
          slotRow.push(this.translateService.instant('planner.weeklyPlanningMap.emptySlot'))
        }

      })

      this.slotRows.push(slotRow)
    }
  }

  /**
  * 2.11.2022
  * Compare function for slots.
  * Compares starttime and sorts according to them calendar slots.
  * Inside try block, because if we can't split starttime for some reason.
  * Error in try block return 0, so it won't affect sort.
  * @param a first element
  * @param b second element
  * @returns sort order
  * @author Jesse Lindholm
  */
  compareSlots(a, b) {
    try {
      let aStartTimeMinutes: number = parseInt(a.starttime.split(" ")[1].split(":")[0]) * 60 + parseInt(a.starttime.split(" ")[1].split(":")[1])
      let bStartTimeMinutes: number = parseInt(b.starttime.split(" ")[1].split(":")[0]) * 60 + parseInt(b.starttime.split(" ")[1].split(":")[1])
      if (aStartTimeMinutes < bStartTimeMinutes) return -1
      else if (aStartTimeMinutes > bStartTimeMinutes) return 1
      else return 0
    } catch (err) {
      return 0
    }
  }

  /**
   * Returns the name of given date in short form.
   * @param date
   * @returns
   *
   * Update: Configured to return local version day name.
   * Defaults to English version
   */
  getDayName(date) {
    const language = this.translateService.currentLang
    if (language === 'en') return date.toLocaleDateString('en-GB', { weekday: 'short' })
    else if (language === 'de') return date.toLocaleDateString('de-DE', { weekday: 'short' });
    else return date.toLocaleDateString('en-GB', { weekday: 'short' })
  }

  /**
   * Returns hours and minutes of startTime and endTime of a slot as string to render in calendar.
   *
   * example return: '07:00 - 10:00'
   * @param startString
   * @param endString
   * @returns
   */
  getTime(startString, endString) {

    if (!startString && !endString) return ''

    startString = new Date(startString)

    endString = new Date(endString)

    let startHours = startString.getHours()
    let startMinutes = startString.getMinutes()
    let endHours = endString.getHours()
    let endMinutes = endString.getMinutes()

    if (startHours < 10) startHours = '0' + startHours;
    if (startMinutes < 10) startMinutes = '0' + startMinutes;
    if (endHours < 10) endHours = '0' + endHours;
    if (endMinutes < 10) endMinutes = '0' + endMinutes;

    let startTime = startHours + ':' + startMinutes

    let endTime = endHours + ':' + endMinutes

    let totalTime = startTime + ' - ' + endTime

    return totalTime

  }

  calendarChanged(calendar) {
    this.calendarId = calendar.calendarId;
    this.selectedMeters = []
    this.getCalendarById(calendar.calendarId, false)
    this.router.navigate(['planner/weekly-planning-map', calendar.calendarId]);
  }

  setProgress(progress) {

    let color = "#FF9F0A"
    if (progress >= 90 && progress <= 100) {
      color = "#10A231"
    } else if (progress > 100) {
      color = "#0058FF"
    }

    let styles = {
      'width': progress + '%',
      'background-color': color
    };

    return styles
  }

  /**
   * Loops through a day and locks all slots in that day
   * index = day
   * @param index
   */
  lockDay(index) {
    for (let i = 0; i < this.slotRows.length; i++) {

      if (this.slotRows[i][index].locked == null || this.slotRows[i][index].locked == false) {

        this.calendars.lockSlot(this.slotRows[i][index].id, true)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => {
            this.slotRows[i][index].locked = true
          })

      }

    }
  }

  /**
   * Loops through a day and unlocks all slots in that day
   * index = day
   * @param index
   */
  unlockDay(index) {

    for (let i = 0; i < this.slotRows.length; i++) {

      if (this.slotRows[i][index].locked == null || this.slotRows[i][index].locked == true) {

        this.calendars.lockSlot(this.slotRows[i][index].id, false)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(() => {
            this.slotRows[i][index].locked = false
          })

      }

    }
  }

  /**
   * Checks slot status of slots in a day and locks the day visually if all slots in a day are locked
   * @param index
   * @returns
   */
  isDayLocked(index) {
    let lockedStatus = false

    for (let i = 0; i < this.slotRows.length; i++) {

      // We check if the slot is a string, empty slots are strings wiht text 'Empty slot!'
      if (this.slotRows[i][index] != String) {
        if (this.slotRows[i][index].locked) {
          lockedStatus = true
        } else {
          lockedStatus = false
          break
        }
      }
    }

    return lockedStatus

  }

  /**
   * Unlocks a slot visually after getting a success message from backend.
   * Visual lock is stated by slotrows[x] slot object
   * @param id
   * @param i
   * @param index
   */
  lockSlot(id, i, index) {
    this.calendars.lockSlot(id, true)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.slotRows[i][index].locked = true
      })
  }

  /**
   * Locks a slot visually after getting a success message from backend.
   * Visual lock is stated by slotrows[x] slot object
   * @param id
   * @param i
   * @param index
   */
  unlockSlot(id, i, index) {
    this.calendars.lockSlot(id, false)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.slotRows[i][index].locked = false
      })
  }

  /**
   * Handles the stuff that happens when the user presses the + button on a slot after selecting markers.
   * @param id
   * @param index
   *
   * @edit 28.11.2022
   * Added Add to calendar function, for adding multiple markers to calendar at once
   * @author Jesse Lindholm
   */
  selectSlot(id, index) {
    // Reset add previous layer
    this.addPreviousLayer = false
    if (this.selectSlotMode) {
      this.tableSpinner = true
      let selectedSlot: any = null
      let dayIndex: number = 0
      for (let i = 0; i < this.slotRows[index].length; i++) {
        const slot = this.slotRows[index][i]
        if (slot.id == id) {
          if (typeof (slot) === 'string' || slot.disabled === 1) {
            this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.selectValidSlot'))
            this.tableSpinner = false
            return
          }
          selectedSlot = slot
          dayIndex = i
        }
      }

      const counts = {}

      // We check if the selected meter/meters are already in the slot
      if (selectedSlot.markers) {
        for (let x = 0; x < this.selectedMeters.length; x++) {
          const meter = this.selectedMeters[x];
          for (let i = 0; i < selectedSlot.markers.length; i++) {
            const slotMeter = selectedSlot.markers[i];
            if (meter.id == slotMeter.id) {
              selectedSlot.markers.splice(i, 1)
              this.toastService.sendToast(false, meter.id + this.translateService.instant('planner.weeklyPlanningMap.slotSkipped'))
            }
          }
        }
      }

      // Go through selected meters, checking if there is ordergroup.
      for (let k = 0; k < this.selectedMeters.length; k++) {
        // Set property inSlot for layers that has been selected
        let boolean = false;
        for (const key in this.layerGroups) {
          for (const key2 in this.layerGroups[key]._layers) {
            if (this.layerGroups[key]._layers[key2].feature.properties.id == this.selectedMeters[k].id) {
              this.layerGroups[key]._layers[key2].feature.properties.inSlot = true;
              boolean = true;
              break;
            }
          }
          if (boolean == true) break;
        }
        // If there is ordergroup add its markers to slot instead of ordergroup
        if (this.selectedMeters[k].workorders) {
          for (let j = 0; j < this.selectedMeters[k].workorders.length; j++) {
            this.selectedMeters.push(this.selectedMeters[k].workorders[j])
          }
          this.selectedMeters.splice(k, 1)
        }
      }

      if (selectedSlot.markers) {
        selectedSlot.markers.forEach(previousMarker => {
          previousMarker.pushed = true
        });
      }
      this.selectedMeters.forEach(addedMarker => {
        addedMarker.pushed = false
      });
      // Ternary operation to add two arrays together if the slot already has some meters
      const meters = selectedSlot.markers ? this.selectedMeters.concat(selectedSlot.markers) : this.selectedMeters

      // Checks if there are multiple meters of the same 'icon' and couns how many there are.
      let meterIds = Array()
      meters.forEach((x) => {
        counts[x.icon] = (counts[x.icon] || 0) + 1
        meterIds.push(x.id)
      });

      let countsArray = Array()

      // We break the counts object and form an array from that data
      for (const key in counts) {
        if (Object.prototype.hasOwnProperty.call(counts, key)) {
          const element = counts[key];

          countsArray.push({
            icon: key,
            count: element
          })
        }
      }
      if (!this.selectSlotMultiple) {
        selectedSlot.markers = meters
        selectedSlot.counts = countsArray
        this.selectedMeters = []
        // Finalize
        this.selectSlotMode = false
        this.saveCalendar()
      } else {
        let pushedMeters = Array()
        // Initialize days for week. Index tells which day it is. Monday is 0 index.
        let daysSlots = {
          "0": [],
          "1": [],
          "2": [],
          "3": [],
          "4": [],
          "5": [],
          "6": []
        }
        for (let i = 0; i < this.slotRows.length; i++) {
          for (let x = 0; x < this.slotRows[i].length; x++) {
            const slot = this.slotRows[i][x];
            if (slot.disabled === 0) {
              if (x > dayIndex && typeof (slot) !== typeof ('String example')) daysSlots[x].push(slot)
              else if (x === dayIndex) {
                const comparedResult = this.compareSlots(slot, selectedSlot)
                if (comparedResult !== -1) daysSlots[x].push(slot)
              }
            }
          }
        }
        // Go through all days in calendar week
        for (let key in daysSlots) {
          let day = daysSlots[key]
          for (let k = 0; k < day.length; k++) {
            const slot = day[k]
            // Make slot duration original value so that slot's don't get overbooked
            if (!slot.durationOriginal) slot.durationOriginal = slot.duration
            // Set slot duration to equal normal slot duration * team's size * team's efficiency

            // let maxFill = slot.slot_max ? slot.slot_max : (this.calendarData.slot_max_default ? this.calendarData.slot_max_default : 100)
            // let dur = slot.durationOriginal / 100 * maxFill
            let dur = slot.durationOriginal
            // ^- If the planner drops workorders to calendar, use 100%. If the slot_max or slot_max_default is different, it relates only to end users.
            // For example, if slot_max is 75, slot length is 60min, and 1 workorder takes 15 min, planner wants to drop 4 workorders (60/15 = 4) to that slot before moving to next
            // Then endusers can't change into that slot unless at least 2 workorders have moved out of the slot.

            if (this.teamSize ) dur = dur * this.teamSize
            if (this.teamEfficiency ) dur = dur * this.teamEfficiency
            slot.duration = Math.round(dur)

            let slotDuration: number = 0
            // Set slot duration to correct time from workorders times
            if (slot.workorders) {
              for (const key in slot.workorders) {
                if (Object.prototype.hasOwnProperty.call(slot.workorders, key)) {
                  const element = slot.workorders[key];
                  if (element) slotDuration = slotDuration + element.time
                }
              }
            }
            let allMeters = Array()
            // Go through all meters
            for (let z = 0; z < meters.length; z++) {
              const meter = meters[z];
              // Check if meter can be added to slot
              if (slot.duration >= slotDuration + meter.time && meter.pushed === false) {
                allMeters.push(meter)
                pushedMeters.push(meter)
                meter.pushed = true
                slotDuration += meter.time
                if (z === meters.length - 1) {
                  this.finalizeSlot(allMeters, slot)
                  break
                }
              } else if (slot.duration === slotDuration || z === meters.length - 1) {
                this.finalizeSlot(allMeters, slot)
                break
              }
            }
          }
        }
        pushedMeters.forEach(meter => {
          let index = this.selectedMeters.map(m => m.id).indexOf(meter.id);
          this.selectedMeters.splice(index, 1)
        })
        this.selectSlotMode = false
        this.tempSelectedMeters = JSON.parse(JSON.stringify(this.selectedMeters))
        if (pushedMeters.length > 0) {
          this.addPreviousLayer = true
          this.selectedMeters = []
        }
        this.saveCalendar()
      }
    }
  }

  /**
   * 30.12.2022
   * Set markers and counts to slot
   * @param allMeters Array of meters
   * @param slot Slot of loop
   * @author Jesse Lindholm
   */
  finalizeSlot(allMeters, slot) {
    if (slot.markers) allMeters = slot.markers.concat(allMeters)
    let allCounts = Array()
    allMeters.forEach((x) => {
      allCounts[x.icon] = (allCounts[x.icon] || 0) + 1
    })

    let countsFinal = Array()
    // We break the counts object and form an array from that data
    for (const key in allCounts) {
      if (Object.prototype.hasOwnProperty.call(allCounts, key)) {
        const element = allCounts[key];
        countsFinal.push({
          icon: key,
          count: element
        })
      }
    }
    // Set markers and counts to slot
    slot.markers = allMeters
    slot.counts = countsFinal
  }

  activateSlotSelect(boolean, multiple) {
    this.selectSlotMode = boolean
    if (boolean === false) {
      for (let i = 0; i < this.slotRows.length; i++) {
        for (let x = 0; x < this.slotRows[i].length; x++) {
          const slot = this.slotRows[i][x];
          if (slot.disabled === 0) {
            slot.color = 'white'
            slot.startHere = false
          }
        }
      }
    }
    this.selectSlotMultiple = multiple
  }


  addToOneSlot() {
    this.activateSlotSelect(true, false)
  }

  /**
   * Function for placing markers to slot.counts and to slot.markers for showing them in interface.
   * Go through layerGroups to find markers with id that matches slots id. Then we save iconSVG for further usage.
   * In html we use SVG icon to generate icon for interface. Slot.counts show primary markers position and when opened
   * we show slot.markers as more detailed version.
   * @param slot slot that we get from getCalendars function
   */
  putMarkersOnSlot(slot) {
    let markers = Array();
    let counts = Array()
    // Go through every workorder
    for (let i = 0; i < slot.workorders.length; i++) {
      let workorderId = slot.workorders[i].workorder_id
      markers.push({
        icon: this.getSvgIcon(slot.workorders[i].time_parameters),
        id: workorderId,
        old_device_id: slot.workorders[i].old_device_id,
        time: slot.workorders[i].time,
        checked: false
      })
    }

    let meters = markers
    meters.forEach((x) => {
      counts[x.icon] = (counts[x.icon] || 0) + 1
    });

    let countsArray = Array()

    // We break the counts object and form an array from that data
    for (const key in counts) {
      if (Object.prototype.hasOwnProperty.call(counts, key)) {
        const element = counts[key];

        countsArray.push({
          icon: key,
          count: element
        })
      }
    }

    let freeAccess = (slot.fill_free_access / slot.duration) * 100

    let notFree = (slot.fill / slot.duration) * 100

    if (this.teamSize) {
      notFree = notFree / this.teamSize
      freeAccess = freeAccess / this.teamSize
    }
    if (this.teamEfficiency) {
      notFree = notFree / this.teamEfficiency
      freeAccess = freeAccess / this.teamEfficiency

    }
    slot.notFree = Math.round(notFree)
    slot.freeAccess = Math.round(freeAccess)

    // Put markers to correct places
    slot.counts = countsArray;
    slot.markers = markers;
  }

  getSvgIcon(time_parameters) {
    let timeEstim = []
    let msaTimeEstim = []
    let projectDefaultEstimates

    if (time_parameters) {
      timeEstim = JSON.parse(time_parameters)
    }

    if (this.msaId) {
      if (this.allMsaEstimates[this.msaId]) {
        msaTimeEstim = JSON.parse(this.allMsaEstimates[this.msaId])
        msaTimeEstim = msaTimeEstim[0]
      }
    }

    if (this.projectEstimates) {
      projectDefaultEstimates = JSON.parse(this.projectEstimates)
    }

    let markerShape = null
    let markerColor = null
    let markerDots = null

    // This loop goes through project default time estimates to get possible shapes/colors of the marker

    for (let i in timeEstim) {
      const categoryid = timeEstim[i]['categoryid']
      const estimateid = timeEstim[i]['estimateid']

      for (let x in projectDefaultEstimates) {
        const element = projectDefaultEstimates[x];


        if (element['categoryid'] == categoryid) {
          const highlighted = projectDefaultEstimates[x]['highlighted']
          if (projectDefaultEstimates[x]['estimates']) {
            for (let index = 0; index < projectDefaultEstimates[x]['estimates'].length; index++) {
              const timeEstimate = projectDefaultEstimates[x]['estimates'][index];

              if (timeEstimate['estimateid'] == estimateid) {
                if (highlighted == 'shape') {
                  markerShape = timeEstimate['attribute']

                } else if (highlighted == 'color') {
                  markerColor = timeEstimate['attribute']

                } else if (highlighted == 'dots') {
                  markerDots = timeEstimate['attribute']
                }
              }
            }
          }
        }
      }
    }

    // This  goes through msa time estimates to get possible shapes/colors of the marker, and overwrites any shapes and colors that we set above

    if (this.msaId && msaTimeEstim) {
      for (let i in timeEstim) {
        const categoryid = timeEstim[i]['categoryid']
        const estimateid = timeEstim[i]['estimateid']

        if (msaTimeEstim['categoryid'] == categoryid) {
          const highlighted = msaTimeEstim['highlighted']
          if (msaTimeEstim['estimates']) {
            for (let index = 0; index < msaTimeEstim['estimates'].length; index++) {
              const timeEstimate = msaTimeEstim['estimates'][index];

              if (timeEstimate['estimateid'] == estimateid) {

                if (highlighted == 'shape') {
                  markerShape = timeEstimate['attribute']

                } else if (highlighted == 'color') {
                  markerColor = timeEstimate['attribute']

                } else if (highlighted == 'dots') {
                  markerDots = timeEstimate['attribute']
                }
              }
            }
          }
        }
      }

    }
    let svgString = this.mapService.getGeneratedSvg(markerShape, markerColor, markerDots)
    // Gets our marker icon from getGeneratedSvg method, returns default icon if null values are passed
    return svgString
  }

  /**
   * Remove marker from both slot.markers and slot.counts. Only remove it from slot.counts if it has count of 1
   * otherwise remove one from counter.
   * @param id id of marker
   * @param slot slot of current slots
   *
   * @edit 28.12.2022
   * Removed ID parameter
   * @author Jesse Lindholm
   */
  removeMarkers(slot: any) {
    this.moreMarkersRemoved = true
    this.tableSpinner = true
    if (this.selectedMeters.length > 0) {
      Swal.fire(this.translateService.instant('planner.weeklyPlanningMap.cantRemove'))
      this.tableSpinner = false
    } else {
      for (let i = slot.markers.length - 1; i >= 0; i--) {
        if (slot.markers[i].checked === true) {
          // Go through showHideMarkers and find correct marker, then add it to map
          for (let z = 0; z < this.showHideMarkers.length; z++) {
            if (this.showHideMarkers[z].marker) {
              if (this.showHideMarkers[z].marker.feature.properties.id == slot.markers[i].id) {
                this.showHideMarkers[z].marker.feature.properties.inSlot = false;
                this.layerGroups[this.showHideMarkers[z].ordergroup].addLayer(this.showHideMarkers[z].marker)
                break
              }
            }
          }
          for (let j = slot.counts.length - 1; j >= 0; j--) {
            if (slot.counts[j].icon == slot.markers[i].icon) {
              if (slot.counts[j].count > 1) slot.counts[j].count--;
              else slot.counts.splice(j, 1)
            }
          }
          slot.markers.splice(i, 1)
        }

      }

      this.saveCalendar()
      //this.clearMap()
      this.showHideMarkers = Array()
    }
    //this.getCalendarById(this.calendarId, false)
  }
  /** Function for button inside an open slot to close it
  * @param id
  */
  closeDetailsWithId(id) {
    if (document.getElementById(id)) {
      document.getElementById(id)?.removeAttribute("open");
    }
  }


  /**
   * Called by week change arrow, handles change to previous week
   */
  moveWeek(value) {
    if (value) {
      const monday = new Date(value)
      this.setupCalendar(monday, this.calendarData.slots)
    }
  }

  /**
   * Gets first day of current week
   * @param d
   * @returns
   */
  getFirstDayOfWeek(d) {
    // clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // get day of week

    // day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  /**
   * Gets last day of current week
   * @param d
   * @returns
   */
  getLastDayOfWeek(d) {

    // clone date object, so we don't mutate it
    const date = new Date(d)
    const day = date.getDay() // get day of week

    const diff = date.getDate() - (day - 1) + 6;

    return new Date(date.setDate(diff));

  }

  /**
   * Sets the weeknumber from given date
   * @param d
   */
  setWeekFromDate(d) {

    let startDate = new Date(d.getFullYear(), 0, 1)
    let days = Math.floor((Number(d) - Number(startDate)) / (24 * 60 * 60 * 1000))

    this.weekNr = Math.ceil((d.getDay() + 1 + days) / 7)
  }

  /**
   * Returns first day of last week
   * @param d
   * @returns
   */
  getFirstDayOfLastWeek(d) {

    const lastWeek = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);

    return lastWeek;
  }

  /**
   * Returns first day of next week
   * @param d
   * @returns
   */
  getFirstDayOfNextWeek(d) {

    const nextWeek = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 7);

    return nextWeek;
  }

  saveCalendar() {
    let changes = false
    let observables: Observable<any>[] = []
    // Go through update calendar slots
    for (const key in this.calendarData.slots) {
      let slots = Array()
      // If does have markers in slot run this
      if (this.calendarData.slots[key].markers) {
        for (let j = 0; j < this.calendarData.slots[key].markers.length; j++) {
          slots.push(parseInt(this.calendarData.slots[key].markers[j].id))
        }
        // Go through original calendars data to check if slots has different data
        // meaning there was added or removed something from markers array
        let addedOrDeleted = false;
        // Run this to check for deleted marker
        for (let q = 0; q < this.originalCalendarData.slots[key].workorders.length; q++) {
          let key5 = this.originalCalendarData.slots[key].workorders[q].workorder_id
          if (slots.includes(parseInt(key5)) == false) addedOrDeleted = true
        }
        // Run this for added marker
        for (let i = 0; i < slots.length; i++) {
          let boolean = false;
          for (let key5 in this.originalCalendarData.slots[key].workorders) {
            if (key5 == slots[i]) boolean = true
          }
          if (boolean == false) addedOrDeleted = true;
        }


        // Run this if there has been changes to calendarData
        if (addedOrDeleted == true) {
          // Run this to check if there has been deleted marker from slots and send it
          // backend as api call if there has been deletion
          for (let a = 0; a < this.originalCalendarData.slots[key].workorders.length; a++) {
            let element = this.originalCalendarData.slots[key].workorders[a]
            if (slots.includes(element.workorder_id) == false) {
              observables.push(this.calendars.updateCalendarSlots(key, element.workorder_id, 'delete'))
              changes = true
              delete this.calendarData.slots[key].workorders[element.workorder_id]
            }
          }
          // Run this if there has been added marker to slots and make
          // new api call adding marker to slot
          for (let x = 0; x < slots.length; x++) {
            let boolean = false;
            for (let b = 0; b < this.originalCalendarData.slots[key].workorders.length; b++) {
              let key3 = this.originalCalendarData.slots[key].workorders[b].workorder_id
              if (key3 == slots[x]) {
                boolean = true;
              }
            }
            if (boolean == false) {
              changes = true;
              observables.push(this.calendars.updateCalendarSlots(key, slots[x], 'add'))
              if (this.calendarData.slots[key].workorders.length != 0) {
                let jsonKey = slots[x]
                this.calendarData.slots[key].workorders[jsonKey] = null
              } else {
                let jsonKey = slots[x]
                this.calendarData.slots[key].workorders = { [jsonKey]: null }
              }
            }

          }

        }
      }
    }
    this.moreMarkersRemoved = false
    forkJoin(observables)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          if (this.moreMarkersRemoved == false) {
            this.getCalendarById(this.calendarId, true)
          }
        }
      )
    if (changes == true) {
      this.originalCalendarData = JSON.parse(JSON.stringify(this.calendarData))
    }
    else {
      this.toastService.sendToast(false, this.translateService.instant('planner.weeklyPlanningMap.noChanges'))
      this.tableSpinner = false
    }

  }

  /**
   * Sets selected spinner spinning while calling the api and getting a time from there.
   *
   * @edit 04.01.2023
   * Added logic for handling ordergroups also. Added subscription that can be unsubscribed so installation time stays correct.
   * @author Jesse Lindholm
   */
  countSelectedLength() {
    this.selectedSpinner = true
    let selectedArray = Array()
    for (let i = 0; i < this.selectedMeters.length; i++) {
      const element = this.selectedMeters[i];
      if (element.hasOwnProperty('old_device_id')) selectedArray.push(element)
      else {
        element.workorders.forEach(workorder => {
          selectedArray.push(workorder)
        });
      }
    }
    if (selectedArray.length > 0) {
      const idsArray = selectedArray.map(workorder => workorder.id)
      this.timeSubscriptions.add(this.workOrderService.getWorkordersTime(idsArray)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          data => {
            this.selectedTime = this.workOrderService.minutesToHoursAndMinutes(data.total)
            for (const key in data) {
              if (Object.prototype.hasOwnProperty.call(data, key)) {
                const time = data[key];
                if (key !== 'total') {
                  let meterIndex = selectedArray.findIndex(m => m.id == key)
                  selectedArray[meterIndex].time = time
                }
              }
            }
            this.selectedSpinner = false
          }
        ))
    } else {
      this.timeSubscriptions.unsubscribe()
      this.timeSubscriptions = new Subscription();
      this.selectedTime = '0 h 0 min'
      this.selectedSpinner = false
    }
  }

  /**
   * Calculates slot time from slot workorders.
   * @param workorders is a key value pair object with id as key and time as value
   * @returns
   *
   * @edit 2.12.2022
   * Logic for new api-call api/calendars/ID
   * @author Jesse Lindholm
   */
  getSlotTime(workorders) {
    let time = 0

    for (const key in workorders) {
      if (Object.prototype.hasOwnProperty.call(workorders, key)) {
        const element = workorders[key];
        if (element) time = time + element.time
      }
    }

    return this.workOrderService.minutesToHoursAndMinutes(time)
  }

  editCalendar() {
    let url = 'planner/weekly-planning-detail/' + this.teamId
    const queryParamsValue: Params = { mode: 'update', calendarId: this.calendarId };
    this.router.navigate([url], {
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }

  /**
   * Move to current day with clicking icon on top of calendars
   */
  moveToToday() {
    let date = new Date();
    let day = date.getDay(),

      diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    this.firstDay = new Date(date.setDate(diff));
    this.lastDay = new Date()
    this.lastDay.setDate(this.firstDay.getDate() + 6)
    this.weekNr = this.datePipe.transform(this.firstDay, 'w')
    this.setupCalendar(this.firstDay, this.calendarData.slots)

  }

  massAddMeters() {
    this.activateSlotSelect(true, true)
  }

  /**
   * 12.01.2023
   * Clear slots grey color, then set grey color to slots which are going to be filled with meters.
   * Listens (mouseenter) on slot + sign.
   * @param selectedSlot Slot that is being hovered
   * @param dayIndex Index of slot
   * @author Jesse Lindholm
   */
  toggleFill(selectedSlot, dayIndex) {

    if (!this.selectSlotMultiple) {
      for (let i = 0; i < this.slotRows.length; i++) {
        for (let x = 0; x < this.slotRows[i].length; x++) {
          const slot = this.slotRows[i][x];
          // Empty slot's have typeof "string", disabled slot's have disabled set to 1 (skip them)
          if (slot.disabled === 0 && typeof (slot) !== 'string') {
            slot.color = 'white'
            slot.startHere = false
          }
        }
      }
      if (selectedSlot.disabled === 0) {
        selectedSlot.color = lightColor
        selectedSlot.startHere = true
      }

    } else {
      const meters = Array()
      // Go through selected meters, checking if there is ordergroup.
      for (let k = 0; k < this.selectedMeters.length; k++) {
        // If there is ordergroup add its markers to slot instead of ordergroup
        if (this.selectedMeters[k].workorders) {
          for (let j = 0; j < this.selectedMeters[k].workorders.length; j++) {
            meters.push(this.selectedMeters[k].workorders[j])
          }
          //this.selectedMeters.splice(k, 1)
        } else meters.push(this.selectedMeters[k])
      }
      // Might be needed (not in use now)
      // Ternary operation to add two arrays together if the slot already has some meters
      // const meters = selectedSlot.markers ? this.selectedMeters.concat(selectedSlot.markers) : this.selectedMeters
      // Line 2042 gets old meters

      let slots = {
        "0": [],
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": []
      }
      // Go through all slots of week to get slots that are after clicked slot
      for (let i = 0; i < this.slotRows.length; i++) {
        for (let x = 0; x < this.slotRows[i].length; x++) {
          const slot = this.slotRows[i][x];
          // Empty slot's have typeof "string", disabled slot's have disabled set to 1 (skip them)
          if (slot.disabled === 0 && typeof (slot) !== 'string') {
            slot.color = 'white'
            slot.startHere = false
            if (x > dayIndex) slots[x].push(slot)
            else if (x === dayIndex) {
              const comparedResult = this.compareSlots(slot, selectedSlot)
              if (comparedResult !== -1) slots[x].push(slot)
            }
          }
        }
      }
      selectedSlot.startHere = true
      let allMeters = Array()
      let stopLoop: boolean = false
      for (let key in slots) {
        if (stopLoop === true) break
        let day = slots[key]
        for (let k = 0; k < day.length; k++) {
          if (stopLoop === true) break
          let slot = day[k]
          // Set slot duration original, so that calculations does not get messed up
          if (!slot.durationOriginal) slot.durationOriginal = slot.duration
          // Set slot duration to equal normal slot duration * team's size * team's efficiency
          let dur = slot.durationOriginal
          if (this.teamSize ) dur = dur * this.teamSize
          if (this.teamEfficiency ) dur = dur * this.teamEfficiency
          slot.duration = Math.round(dur)
          let slotDuration: number = 0
          // Set slot duration to correct time from workorders times
          if (slot.workorders) {
            for (const key in slot.workorders) {
              if (Object.prototype.hasOwnProperty.call(slot.workorders, key)) {
                const element = slot.workorders[key];
                if (element) slotDuration = slotDuration + element.time
              }
            }
          }

          // Go through all meters
          for (let z = 0; z < meters.length; z++) {
            const meter = meters[z];
            // Check if meter can be added to slot
            if (slot.duration >= slotDuration + meter.time) {
              allMeters.push(meter)
              if (allMeters.length === meters.length) stopLoop = true
              slotDuration += meter.time
              if (z === meters.length - 1) {
                slot.color = lightColor
                break
              }
            } else if (slot.duration === slotDuration || z === meters.length - 1) {
              if (allMeters.length === meters.length) stopLoop = true
              slot.color = lightColor
              break
            }
          }
        }
      }
    }
  }


  saveSingleSlot(slot) {
    if (slot.slot_max > this.defaultmax) {
      slot.slot_max = this.defaultmax
    }
    this.calendars.updateSlotMaxFill(slot.id, slot.slot_max)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.slotToEdit = 0
        this.oldSlotValue = null
        this.oldSlotI = 0
        this.oldSlotIndex = 0
      })
  }
  activateSingleSlot(id, i, index) {
    if (this.slotToEdit) {
      if (id == this.slotToEdit) {
        this.slotRows[i][index].slot_max = this.oldSlotValue
        this.slotToEdit = 0
        this.oldSlotValue = null
        this.oldSlotI = 0
        this.oldSlotIndex = 0
      } else {
        this.slotRows[this.oldSlotI][this.oldSlotIndex].slot_max = this.oldSlotValue
        this.slotToEdit = id
        this.oldSlotI = i
        this.oldSlotIndex = index
        this.oldSlotValue = this.slotRows[i][index].slot_max
      }
    } else {
      this.slotToEdit = id
      this.oldSlotI = i
      this.oldSlotIndex = index
      this.oldSlotValue = this.slotRows[i][index].slot_max
    }
  }


  ngAfterViewInit() {
    this.unsubscribeClickOutside = this.renderer.listen('document', 'click', (event) => {
      this.detailsRefs.forEach(detailsRef => {
        if (!detailsRef.nativeElement.contains(event.target) && detailsRef.nativeElement.open) {
          detailsRef.nativeElement.open = false;
        }
      });
    });
  }

  toggleMapSize() {
    // Not open
    if (!this.showMapSizes) {
      this.showMapSizes = true
      this.mapInfoBox = "assets/icons/close_gray_24dp.svg"
    }
    // Open
    else {
      this.showMapSizes = false
      this.mapInfoBox = "assets/icons/aspect_ratio_24.svg"
    }
  }

  selectMapSize(number: number) {
    if (number !== this.mapMode) {
      this.selectedMeters = []
      this.mapMode = number
      localStorage.setItem('mapMode', number.toString())
      this.showHideMarkers = []
      this.map.remove()
      this.ngOnInit()
      this.showMapSizes = false
      this.mapInfoBox = "assets/icons/aspect_ratio_24.svg"
      if (!this.showExplanations) this.toggleMarkersExplanations()

      const sidebar = document.getElementById('sidebar');

      if (sidebar) {
        if (number === 1 && sidebar.classList.contains('active')) {
          this.headerSidebarService.toggle()
          this.renderer.removeClass(sidebar, 'active')
        }
        else if ((number === 2 || number === 3) && !sidebar.classList.contains('active') && window.innerWidth > 1200) this.headerSidebarService.toggle();
      }
    }
  }

  addToWorkList() {
    let newWorkorders: any = []
    // Go through selected meters, checking if there is ordergroup.
    for (let k = 0; k < this.selectedMeters.length; k++) {
      // If there is ordergroup add its markers to slot instead of ordergroup
      if (this.selectedMeters[k].workorders) {
        for (let j = 0; j < this.selectedMeters[k].workorders.length; j++) {
          this.workList.push(this.selectedMeters[k].workorders[j])
          newWorkorders.push(this.selectedMeters[k].workorders[j])
        }
      } else {
        this.workList.push(this.selectedMeters[k])
        newWorkorders.push(this.selectedMeters[k])
      }
    }
    this.selectedMeters = []
    let observables: Observable<any>[] = [];
    for (let i = 0; i < newWorkorders.length; i++) {
      const element = newWorkorders[i];
      observables.push(this.workOrderService.addWorkorderToWorklist(this.calendarId, element.id));
    }

    forkJoin(observables).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.getCalendarById(this.calendarId, true);
        } else {
          console.error('Failed')
        }
      },
      error: (error) => {
        console.error(error)
      },
    });
  }

  deleteFromWorkList() {
    let deletedWorkorders = this.workList.filter(wo => wo.checked)
    let observables: Observable<any>[] = [];
    for (let i = 0; i < deletedWorkorders.length; i++) {
      const element = deletedWorkorders[i];
      observables.push(this.workOrderService.removeWorkorderFromWorklist(this.calendarId, element.workorder_id));
    }
    forkJoin(observables).subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.getCalendarById(this.calendarId, true);
        } else {
          console.error('Failed')
        }
      },
      error: (error) => {
        console.error(error)
      },
    });
    this.showWorkList = false
  }
}
