import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldReportingComponent } from '@layout/field-reporting/field-reporting.component';
import { SharedModule } from '@shared/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { SingleOrderDetailComponent } from './components/single-order-detail/single-order-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReportComponent } from './components/report/report.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InterruptComponent } from './components/interrupt/interrupt.component';
import { WiringReportComponent } from './components/wiring-report/wiring-report.component';
import { DanishWaterMeterReport } from './components/danish-water-meter-report/danish-water-meter-report.component';
import { DanishHeatMeterReport } from './components/danish-heat-meter-report/danish-heat-meter-report.component';
import { DanishHeatMeterReportSixValues } from './components/danish-heat-meter-report-six-values/danish-heat-meter-report-six-values.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { MatDialogModule } from '@angular/material/dialog';
import {GermanElectricMeterReport} from "@modules/field-reporting/components/german-electric-meter-report/german-electric-meter-report.component";
import { DanishHeatMeterReportTwelvePictures } from './components/danish-heat-meter-report-twelve-pictures/danish-heat-meter-report-twelve-pictures.component';
import { WorkOrderComponent } from './components/dashboard/work-order.component';



@NgModule({
  declarations: [
    FieldReportingComponent,
    DashboardComponent,
    SingleOrderDetailComponent,
    ReportComponent,
    InterruptComponent,
    WiringReportComponent,
    DanishWaterMeterReport,
    DanishHeatMeterReport,
    DanishHeatMeterReportSixValues,
    GermanElectricMeterReport,
    QrScannerComponent,
    DanishHeatMeterReportTwelvePictures,
    WorkOrderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    MatDialogModule

  ]
})
export class FieldReportingModule { }
