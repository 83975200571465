import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ActivatedRoute } from '@testing/activated-route.stub';
import { Subject, takeUntil } from 'rxjs';
import { Params, Router } from '@angular/router';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { DatePipe } from '@angular/common';
import imageCompression from 'browser-image-compression';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { QrScannerComponent } from '../../qr-scanner/qr-scanner.component';
import { UserService } from '@shared/services/user/user.service';
import { InterruptService } from '@shared/services/interrupt/interrupt.service';
import { Interrupt } from '@shared/models/interrupt';


declare const L: any;
@Component({
  selector: 'app-danish-heat-meter-report-twelve-pictures',
  templateUrl: './danish-heat-meter-report-twelve-pictures.component.html',
  styleUrls: ['./danish-heat-meter-report-twelve-pictures.component.scss']
})

export class DanishHeatMeterReportTwelvePictures implements OnInit, OnDestroy {
  //@ViewChild('qrReaderElement') qrReaderElement: ElementRef | undefined;

  clickedButtonId: number = 0
  saving = false;

  componentDestroyed$: Subject<boolean> = new Subject()

  installationTime: any
  installationDate: Date = new Date()
  installationDateToApi: any
  installHours: any
  installMinutes: any
  additionalInfo: String = ""
  switchedDate: any
  switchedTime: any
  dirtyTime = false
  timeUntouched = true
  newMeterOnSave = false;
  saveAdditional = false;
  secondTabDisabled = true;
  thirdTabDisabled = true;
  showOtherWorkType: boolean = false
  otherWorkType: string | null = null

  firstTabImgCheck = false
  secondTabImgCheck = false
  thirdTabImgCheck = false

  //danish stuff
  interruptCode: Interrupt | null = null
  interruptReason: string | null = null

  // Indicates if the textarea is mandatory
  isMandatory: boolean = false;
  // Indicates if the textarea is enabled
  textareaEnabled: boolean = false;
  isOptional: boolean = false;

  imageMandatory: boolean = false; // Indicates if the image upload is mandatory
  imageOptional: boolean = false; // Indicates if the image upload is optional

  id: string | null = null
  accessTypeInfo = ''
  accessType: number | null = null
  story = ''
  placementText = ''

  interruptImages: Array<any> = [];
  oldImages: Array<any> = [];
  newImages: Array<any> = [];

  //old meter content

  // old meter input fields
  oldMeterNumber;
  placementReport: string | undefined;
  oldkWh;
  oldm3Water;
  oldHours;
  oldOpenText;
  oldE8;
  oldE9;

  // new meter input fields
  newMeterNumber;
  newkWh;
  newM3Water;
  newHours;
  newE8;
  newE9;
  antenna;

  // control of new and old report
  showOldMeterReport: boolean = true;
  showNewMeterReport: boolean = false;


  // QR code stuff
  showQrScanner = false;

  //check if reporttime exists, no need for pictures then
  reportedPart = 'None';

  oldMeterReportSaved: boolean = false;

  isProcessing: boolean = false;


  placementReportOptions = [
    { value: '01', label: 'fieldReporting.danishHeatMeterReport.placement01' },
    { value: '02', label: 'fieldReporting.danishHeatMeterReport.placement02' },
    { value: '03', label: 'fieldReporting.danishHeatMeterReport.placement03' },
    { value: '04', label: 'fieldReporting.danishHeatMeterReport.placement04' },
    { value: '05', label: 'fieldReporting.danishHeatMeterReport.placement05' },
    { value: '06', label: 'fieldReporting.danishHeatMeterReport.placement06' },
  ];
  reporterId: number | null = null
  interruptOptions: Interrupt[] = []

  constructor(
    private workorderService: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fieldReportingService: FieldReportingService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    public  dialog: MatDialog,
    private userService: UserService,
    private interruptService: InterruptService
  ) { }

  // Get meter id from url
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap => {
      this.id = pmap.get('id')
      this.getMeterInformation(this.id)
      this.getUserInfo()
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  getUserInfo() {
    this.userService.getUserInfo().subscribe(
      data => {
        if (data.workers.length > 0) {
          this.reporterId = data.workers[0].userId
        }
      }
    )
  }


  /**
   * 15.12.2022
   * Set limitations for image compression.
   * Then call imageCompression api from browser-image-compression library.
   * Use end result in images array.
   * @param event Allows to select file with catching event parameter
   * @param index What index of image we are using
   * @author Jesse Lindholm
   */
  async handleImageUpload(event, placement) {
    const imageFile = event.target.files[0];
    if (!imageFile) return;

    const originalSizeMB = imageFile.size / 1024 / 1024;
    const compressionThresholdMB = 1.0;

    const addImage = (output) => {

        const imageObject = {
            data: output,
            img: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(output))
        };
        if (placement === 'interrupt') {
            this.interruptImages.push(imageObject);
        } else if (placement === 'old') {
            this.oldImages.push(imageObject);
        } else if (placement === 'new') {
            this.newImages.push(imageObject);
        }
    };

    if (originalSizeMB > compressionThresholdMB) {
        const options = {
            maxSizeMB: compressionThresholdMB * 1.0, // Adjust to ensure result is below the threshold.
            maxWidthOrHeight: 1024,
            useWebWorker: true
        };

        try {
            await imageCompression(imageFile, options).then(addImage);
        } catch (error) {
            console.error('Compression error:', error);
        }
    } else {
        // Add the image directly without compression if it's smaller than or equal to 5MB.
        addImage(imageFile);
    }

    event.target.value = ''; // Reset the input to allow for re-uploads.
}


  // Get meter information with correct id
  getMeterInformation(meterId) {
    this.workorderService.getWorkorderWithOptions(meterId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          // if (data.old_device_id) {
          //   this.oldMeterNumber = data.old_device_id;
          // }
          if (data.placement) {
            this.placementReport = data.placement;
          }
          if (data.old_device_reading_kWh) {
            this.oldkWh = data.old_device_reading_kWh;
          }
          if (data.old_device_reading_m3_water) {
            this.oldm3Water = data.old_device_reading_m3_water;
          }
          if (data.old_device_reading_hours) {
            this.oldHours = data.old_device_reading_hours;
          }
          if (data.old_device_open_text) {
            this.oldOpenText = data.old_device_open_text;
          }
          if (data.reported_part) {
            this.reportedPart = data.reported_part;
          }
          if (data.old_device_reading_E8) {
            this.oldE8 = data.old_device_reading_E8;
          }
          if (data.old_device_reading_E9) {
            this.oldE9 = data.old_device_reading_E9;
          }


          // populate the new meter report fields if they exist
          if (data.new_device_id) {
            this.newMeterNumber = data.new_device_id;
          }
          if (data.new_device_reading_kWh) {
            this.newkWh = data.new_device_reading_kWh;
          }
          if (data.new_device_reading_m3_water) {
            this.newM3Water = data.new_device_reading_m3_water;
          }
          if (data.new_device_reading_hours) {
            this.newHours = data.new_device_reading_hours;
          }
          if (data.new_device_reading_E8) {
            this.newE8 = data.new_device_reading_E8;
          }
          if (data.old_device_reading_E9) {
            this.newE9 = data.new_device_reading_E9;
          }
          if (data.antenna_installed) {
            this.antenna = data.antenna_installed;
          }

          if (data.interrupt_reason) {
            this.interruptReason = data.interrupt_reason;
          }

          this.getInterruptions(data.interrupt_code)
        }
      )
  }

  // Back to dashboard function that we use in first tab if user presses back button.
  // Set meter id to queryParams so we can access it in dashboard component directly from url
  backToDashboard() {
    const queryParamsValue: Params = { meterId: this.id };
    this.router.navigate(['field-reporting/dashboard'], {
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }

  prepareNewReportData() {
    const currentDateTime = new Date();

    let status = 4;
    // Prepare the JSON data for the new meter report
    return {
      "status": status,
      "attend_time": this.datePipe.transform(currentDateTime, 'yyyy-MM-dd HH:mm', 'UTC'),
      "new_device_id": this.newMeterNumber,
      "new_device_reading_kWh": this.commaToDot(this.newkWh),
      "new_device_reading_m3_water": this.commaToDot(this.newM3Water),
      "new_device_reading_hours": this.commaToDot(this.newHours),
      "new_device_reading_E8": this.newE8,
      "new_device_reading_E9": this.newE9,
      "antenna_installed": this.antenna,
      "reported_part": 'new_meter_report', // Set the reported part
      "attendee": this.reporterId
    };
  }

  saveOldMeterReport() {
    if (!this.canSaveOldReport()) {
      // Handle the case when the old report can't be saved (e.g., mandatory fields are missing)
      return;
    }
    this.saving = true;
    this.isProcessing = true; 
    const formData = new FormData();
    let jsonData = this.prepareOldReportData(); // Prepare only the old meter report data
    let imageData = this.handleImages(formData, this.oldImages, 'old'); // Handle only the old meter images

    formData.append('images', JSON.stringify(imageData) as any);
    formData.append('reportdata', JSON.stringify(jsonData) as any);

    // Call the backend service to save the old meter report
    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          this.saving = false;
          this.isProcessing = false;
          if (result) {
            // we dont want to update to old_meter_report if the new meter report has already been done
            if (this.reportedPart != 'new_meter_report') {
              this.reportedPart = 'old_meter_report';
              this.goToNewMeterReport();
            }
            this.oldMeterReportSaved = true;
          } else {
            this.oldMeterReportSaved = false;
          }
        }
      );
  }

  saveHeatMeterNewReport() {
    if (!this.canSaveNewReport()) {
      // Handle the case when the new report can't be saved (e.g., mandatory fields are missing)
      return;
    }
  
    this.saving = true;
    const formData = new FormData();
    let jsonData = this.prepareNewReportData(); // Prepare only the new meter report data
    let imageData = this.handleImages(formData, this.newImages, 'new'); // Handle only the new meter images
  
    formData.append('images', JSON.stringify(imageData) as any);
    formData.append('reportdata', JSON.stringify(jsonData) as any);
  
    // Call the backend service to save the new meter report
    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          this.saving = false;
          if (result) {
            // Update the reported_part to 'new_meter_report'
            // You may need to make another API call or update the local state
            this.reportedPart = 'new_meter_report';
            this.backToDashboard();
          } else {
          }
        }
      );
  }

  saveHeatMeterInterruptedReport() {
    if (!this.canSaveInterruptedReport()) {
      // Handle the case when the interrupted report can't be saved
      return;
    }
  
    this.saving = true;
    const formData = new FormData();
    let jsonData = this.prepareInterruptedReportData(); // Prepare the interrupted report data
    let imageData = this.handleImages(formData, this.interruptImages, 'interrupted'); // Handle the interrupted report images
  
    formData.append('images', JSON.stringify(imageData) as any);
    formData.append('reportdata', JSON.stringify(jsonData) as any);
  
    // Call the backend service to save the interrupted report
    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          this.saving = false;
          if (result) {
            // Update the reported_part to 'interrupted_report'
            this.reportedPart = 'interrupted_report';
            this.backToDashboard();
          } else {
          }
        }
      );
  }

  prepareInterruptedReportData() {
    const currentDateTime = new Date();
    // Prepare the JSON data for the interrupted report
    let interrupt: string | null = null
    if (this.interruptCode) interrupt = this.interruptCode.interrupt_code
    return {
      "access_type": this.accessType,
      "access_type_notes": this.accessTypeInfo,
      "story": this.story,
      "placement": this.placementText,
      "status": 6,
      "notes": this.additionalInfo,
      "attend_time": this.datePipe.transform(currentDateTime, 'yyyy-MM-dd HH:mm', 'UTC'),
      "interrupt_reason": this.interruptReason,
      "interrupt_code": interrupt,
      "reported_part": 'interrupted_report'
    };
  }

  handleImages(formData: FormData, images: Array<any>, prefix: string): ImageData[] {
    let imageData: ImageData[] = [];
    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].data && images[i].data.name) {
        const imagePlacePrefix = prefix + '_';
        const titleSuffix = '_' + prefix;

        imageData.push({
          "image_place": imagePlacePrefix + i,
          "title": images[i].data.name + titleSuffix,
          "image_key": 'img' + i
        });
        formData.append('img' + i, images[i].data as any);
      }
    }
    return imageData;
  }

  prepareOldReportData() {
    const currentDateTime = new Date();
    // Prepare the JSON data for the old meter report
    return {
      "access_type": this.accessType,
      "access_type_notes": this.accessTypeInfo,
      "story": this.story,
      "placement": this.placementReport,
      "notes": this.additionalInfo,
      "attend_time": this.datePipe.transform(currentDateTime, 'yyyy-MM-dd HH:mm', 'UTC'),
      "old_device_id": this.oldMeterNumber,
      "old_device_reading_kWh": this.commaToDot(this.oldkWh),
      "old_device_reading_m3_water": this.commaToDot(this.oldm3Water),
      "old_device_reading_hours": this.commaToDot(this.oldHours),
      "old_device_open_text": this.oldOpenText,
      "old_device_reading_E8": this.oldE8,
      "old_device_reading_E9": this.oldE9,
      "reported_part": 'old_meter_report',
      "attendee": this.reporterId
    };
  }

  commaToDot(value: string | number): string {
    return String(value).replace(',', '.');
  }

  canSaveOldReport(): boolean {
    if (this.saving) {
      return false;
    }

    // Check that all fields for the old report have a value
    const allFieldsFilledOld = this.oldMeterNumber && this.placementReport && this.oldkWh && this.oldm3Water && this.oldHours;
    if (this.reportedPart == 'old_meter_report' || this.reportedPart == 'new_meter_report') {
      // No report has yet been made
      // Check that there are 6 old images
      return allFieldsFilledOld;
    }
    const correctOldImageCount = this.oldImages && this.oldImages.length === 6;
    return allFieldsFilledOld && correctOldImageCount;

  }

  canSaveNewReport(): boolean {
    if (this.saving) {
      return false;
    }

    // Check that all fields for the new report have a value
    const allFieldsFilledNew = this.newMeterNumber && this.newkWh && this.newM3Water && this.newHours;
    if (this.reportedPart == 'new_meter_report') {
      return allFieldsFilledNew;


    }
    // Check that there are 6 new images
    const correctNewImageCount = this.newImages && this.newImages.length === 12;

    return allFieldsFilledNew && correctNewImageCount;
  }

  canSaveInterruptedReport(): boolean {
    if (this.saving) {
      return false;
    }

    // First check: if no interruptCode is selected, you can't save.
    if (this.interruptCode === null) {
      return false;
    }

    // Check if comment is mandatory and is filled out
    const isCommentFilled = !this.isMandatory ||
      (this.isMandatory && this.interruptReason && this.interruptReason.trim() !== '');
    // Convert to boolean explicitly
    if (this.reportedPart == 'interrupted_report') {
      return !!isCommentFilled;
    }

    // Check if image is mandatory and is uploaded
    const isImageUploaded = !this.imageMandatory ||
      (this.imageMandatory && this.interruptImages && this.interruptImages.length > 0 && this.interruptImages[0].img);

    return isCommentFilled && isImageUploaded;
  }



  changeButtonId(buttonId: number) {
    if (buttonId !== this.clickedButtonId) this.clickedButtonId = buttonId
    else this.clickedButtonId = 0
  }


  startScanning() {
    const dialogRef = this.dialog.open(QrScannerComponent, {
      panelClass: 'custom-qr-dialog',
      width: '80vw',
      height: 'auto',
    });

    dialogRef.componentInstance.codeScanned.subscribe((code: string) => {
      if (code) {
        this.newMeterNumber = code;
      }
      dialogRef.close();
    });
  }

  goToNewMeterReport() {
    this.showOldMeterReport = false;
    this.showNewMeterReport = true;
  }

  goToOldMeterReport() {
    this.showOldMeterReport = true;
    this.showNewMeterReport = false;
  }

  getInterruptions(interruptFromMeterInfo) {
    this.interruptService.getInterruptOptions().subscribe({
      next: data => {
        this.interruptOptions = data
        if (interruptFromMeterInfo) {
          let interrupt = this.interruptOptions.find(io => io.interrupt_code === interruptFromMeterInfo)
          if (interrupt) this.interruptCode = interrupt
        }
      }
    })
  }

  setInterruptCode(item) {
    this.interruptCode = item
    if (item.comment_required) {
      this.isMandatory = true
      this.isOptional = false
      this.textareaEnabled = true
    } else {
      this.isMandatory = false
      this.isOptional = true
      this.textareaEnabled = true
    }

    if (item.image_required) {
      this.imageMandatory = true
      this.imageOptional = false
    } else {
      this.imageMandatory = false
      this.imageOptional = true
    }
  }


}

interface ImageData {
  image_place: string;  // Note the change to string type here
  title: string;
  image_key: string;
}
