import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  getWorkers(all = 0) {
    return this.http.get(`${baseUrl}/api/workers/`,
      {
        params: {
          'allWorkers': all,
        }
      })
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          return of(false);
        })
      )
  }

  getWorkersWithProjectId(project_id) {
    return this.http.get(`${baseUrl}/api/workers/`,
      {
        params: {
          'project_id': project_id,
        }
      })
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          return of(false);
        })
      )
  }

  getWorker(id) {
    return this.http.get(`${baseUrl}/api/workers/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          if (error.statusText == 'Not Found') return of(true);
          else return of(false)
        })
      )
  }

  createWorker(userId, country, region, city, phoneNumber, address, postCode, workingHours, efficiency, subcontractor, qualifications) {

    let jsonWorkingHours;
    if (workingHours != null) jsonWorkingHours = JSON.stringify(workingHours)
    else jsonWorkingHours = null;

    let jsonQualifications;
    if (qualifications != null) jsonQualifications = JSON.stringify(qualifications)
    else jsonQualifications = null;

    let data = {
      "userid": userId,
      "efficiency": efficiency,
      "working_hours": jsonWorkingHours,
      "qualifications": jsonQualifications,
      "area": "test",
      "subcontractor": subcontractor,
      "address": address,
      "region": region,
      "city": city,
      "zip": postCode,
      "country": country,
      "phone": phoneNumber
    }


    return this.http.post<any>(`${baseUrl}/api/workers`, data)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.createWorkerSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )


  }

  setWorkerToProject(workerId, projectId) {
    return this.http.post(`${baseUrl}/api/projects/${projectId}/${workerId}`, null)
      .pipe(
        map((data: any) => {
          return { success: true, response: data['message'] };
        }),
        catchError(error => {
          return of({ success: false, response: error.error });
        })
      )
  }
  removeWorkerFromProject(workerId, projectId) {
    return this.http.delete(`${baseUrl}/api/projects/${projectId}/${workerId}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  updateWorker1(id, country, address, phone, region) {

    let newdataJSON = {
      "address": address,
      "country": country,
      "phone": phone,
      "region": region,

    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workers/${id}`, {
      newdata
    })
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.updateWorkerRegion1'))
      return response
    })
    .catch(err => {
      this.toastService.sendToast(false, err)
    });
  }

  updateWorker2(id, efficiency, qualifications, days, employmentRelationship) {

    let newdataJSON = {
      "efficiency": efficiency,
      "qualifications": qualifications,
      "working_hours": days,
      "employment_relationship": employmentRelationship
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workers/${id}`, {
      newdata
    })
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.updateWorkerRegion2'))
      return response
    })
    .catch(err => {
      this.toastService.sendToast(false, err)
    });
  }

  checkUsername(username) {
    return this.http.post(`${baseUrl}/api/isusernameinuse/${username}`, null)
    .pipe(
      map((data) => {
        if (data['message'] != 'username_not_found') this.toastService.sendToast(false, this.translateService.instant('services.usernameInUse'))
        return data['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.checkUsername')))
   );
  }

  getWorkerteam(workerteamId) {
    return this.http.get(`${baseUrl}/api/workerteams/${workerteamId}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          return of(false);
        })
      )
  }

  getWorkerteams() {
    return this.http.get(`${baseUrl}/api/workerteams`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          return of(false);
        })
      )
  }

  fullWorker(
    projectId,
    firstname,
    lastname,
    email,
    qualifications,
    workingHours,
    efficiency,
    address,
    region,
    city,
    country,
    phone,
    zip,
    subcontractor,
    language
    ) {

      let jsonWorkingHours;
      if (workingHours.length > 0) jsonWorkingHours = JSON.stringify(workingHours)
      else jsonWorkingHours = null;

      let jsonQualifications;
      if (qualifications.length > 0) jsonQualifications = JSON.stringify(qualifications)
      else jsonQualifications = null;

      let data = {
        "project_id": projectId,
        "efficiency": efficiency,
        "working_hours": jsonWorkingHours,
        "qualifications": jsonQualifications,
        "subcontractor": subcontractor,
        "address": address,
        "region": region,
        "city": city,
        "zip": zip,
        "country": country,
        "phone": phone,
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "role": 2,
        "disabled": 0,
        "area": "area",
        "language": language
      }

      return this.http.post(`${baseUrl}/api/fullworker`, data)
      .pipe(
        map((data) => {
          this.toastService.sendToast(true, this.translateService.instant('services.workerCreated'))
          return data['message']
        }),
        catchError(this.handleError(this.translateService.instant('services.checkUsername')))
      )

  }

  setWorkerToWorkerteam(workerId, workerteamId) {
    return this.http.post(`${baseUrl}/api/workers/${workerId}/${workerteamId}`, null)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  changeDisabledStatus(disabledValue, userId) {
    let data = {
      disabled: disabledValue
    }
    let disabledText = ""
    if (disabledValue == 1) disabledText = 'disabled'
    else disabledText = 'activated'
    let newdata = JSON.stringify(data)

    return this.http.patch(`${baseUrl}/api/users/${userId}`, {newdata})
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.disabledStatusSwitched') + disabledText)
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.toastService.sendToast(false, error.message)


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
