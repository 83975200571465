import { Component, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { CallServiceService } from '@modules/call-service/services/call-service.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '@shared/models/calendar';
import { Enduser } from '@shared/models/enduser';
import { WeekDay } from '@shared/models/week-day';
import { WorkOrder } from '@shared/models/work-order';
import { ToastService } from '@shared/services/toast/toast.service';
import { ActivatedRoute } from '@testing/activated-route.stub';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { Log } from '@shared/models/log';
import { CallingLog } from '@shared/models/calling-log';
import { UserService } from '@shared/services/user/user.service';
import {Interrupt} from "@shared/models/interrupt";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-single-enduser',
  templateUrl: './single-enduser.component.html',
  styleUrls: ['./single-enduser.component.scss']
})
export class SingleEnduserComponent implements OnInit, OnDestroy {

  // Global
  tab: number = 1
  activeTab: number = 1;
  componentDestroyed$: Subject<boolean> = new Subject()
  workorderId: number | null = null

  backTab: number = 1;

  done: boolean = false
  // Interruption
  interruptIcon: string = 'assets/icons/close_red_24dp.svg'
  interrupted: boolean = false
  interruptedReason: string | null = null
  interruptedReasonSelect: string | null = null
  interruptedDate: any = null
  interruptor: string | null = null
  interruptCode: string = "0"
  interruptCodeSelect: string = "0"
  interruptCodeDefinition: string = ""

  // Calendar
  currentWeek: Array<WeekDay> = [
    { day: 'Mon', date: null, slots: Array() },
    { day: 'Tue', date: null, slots: Array() },
    { day: 'Wed', date: null, slots: Array() },
    { day: 'Thu', date: null, slots: Array() },
    { day: 'Fri', date: null, slots: Array() },
    { day: 'Sat', date: null, slots: Array() },
    { day: 'Sun', date: null, slots: Array() },
  ]

  activeCalendar: number = 0
  // activeSlot: any = null
  activeSlot = { 'id': 0, 'active': false }
  activeSlotEnabled: boolean = false
  mainCalendar: Calendar | null = null
  cleanupCalendar: Calendar | null = null
  enableChangeWeekPicker: boolean = true
  onlyCleanupCalendar: boolean = false
  showMainCalendar: boolean = false
  showCleanupCalendar: boolean = false
  invalidPhoneNumber: boolean = false

  @Output() changeWeekPickerDate: Date | null = null

  // Spinners
  calendarSpinner: boolean = true
  workorderSpinner: boolean = true
  isSaving: boolean = false;
  currentWeekHasLoaded: boolean = false;

  // Customer information
  workorder: WorkOrder = {} as WorkOrder
  enduserList: Array<Enduser> = Array()
  enduserId: number | null = null
  additionalInformation: string = ""
  notesForInstaller: string = ""
  phoneNumber: string = ""
  originalAdditionalInformation: string = ""
  originalNotesForInstaller: string = ""
  originalPhoneNumber: string = ""

  // Slot
  workorderStartTime: string | Date = ""
  workorderEndTime: string | Date = ""
  weekHasSlots: boolean = false

  // checking phone number
  countryCodesList = [
    '93', '355', '213', '1-684', '376', '244', '1-264', '672', '1-268', '54', '374', '297', '61', '43', '994', '1-242', '973', '880', '1-246', '375', '32', '501', '229', '1-441', '975', '591', '387', '267', '55', '246', '1-284', '673', '359', '226', '257', '855', '237', '1', '238', '1-345', '236', '235', '56', '86', '61', '61', '57', '269', '682', '506', '385', '53', '599', '357', '420', '243', '45', '253', '1-767', '1-809, 1-829, 1-849', '670', '593', '20', '503', '240', '291', '372', '251', '500', '298', '679', '358', '33', '689', '241', '220', '995', '49', '233', '350', '30', '299', '1-473', '1-671', '502', '44-1481', '224', '245', '592', '509', '504', '852', '36', '354', '91', '62', '98', '964', '353', '44-1624', '972', '39', '225', '1-876', '81', '44-1534', '962', '7', '254', '686', '383', '965', '996', '856', '371', '961', '266', '231', '218', '423', '370', '352', '853', '389', '261', '265', '60', '960', '223', '356', '692', '222', '230', '262', '52', '691', '373', '377', '976', '382', '1-664', '212', '258', '95', '264', '674', '977', '31', '599', '687', '64', '505', '227', '234', '683', '850', '1-670', '47', '968', '92', '680', '970', '507', '675', '595', '51', '63', '64', '48', '351', '1-787, 1-939', '974', '242', '262', '40', '7', '250', '590', '290', '1-869', '1-758', '590', '508', '1-784', '685', '378', '239', '966', '221', '381', '248', '232', '65', '1-721', '421', '386', '677', '252', '27', '82', '211', '34', '94', '249', '597', '47', '268', '46', '41', '963', '886', '992', '255', '66', '228', '690', '676', '1-868', '216', '90', '993', '1-649', '688', '1-340', '256', '380', '971', '44', '1', '598', '998', '678', '379', '58', '84', '681', '212', '967', '260', '263'
  ]
  // Logs stuff
  userLang;
  workorderLogs: Array<Log> = Array()
  callStatus= false;
  callingLog: any[] | undefined;
  interruptOptions: Array<Interrupt> = []
  selectedInterrupt: string = "0"
  scheduledToSend: string = ''
  showMsgLog: boolean = false
  messageLogs: any[] = []

  constructor(
    private callService: CallServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private workorderService: WorkOrderService,
    private toastService: ToastService,
    private authService: AuthService,
    private translateService: TranslateService,
    private plannersService: PlannerProjectService,
    private userService: UserService,
    private datePipe: DatePipe
  ) { }

  /**
   * Init component. Showing information about enduser to call service. Call service can then modify information based on call they make
   * and save changes.
   * Get workorder id from url and make api calls according to it.
   * WARNING: There is a chance user modifies URL to get information about another workorder if he/she knows workorder id.
   */
  ngOnInit(): void {
    this.userLang = localStorage.getItem('userlanguage');
    this.route.queryParams.subscribe(params => {
      if (params['workorderId']) {
        this.workorderId = params['workorderId']
        this.getWorkorderInformation(this.workorderId)
        this.backTab = parseInt(params['backTab']);

        const id = this.route.snapshot.paramMap.get('id')
        if (id) this.enduserId = parseInt(id)
      }
    })
  }

  // Custom cleanup that destorys observables, preventing memory leaks.
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get workorder data and use it to show meter information
   * @param workorderId workorder id
   */
  getWorkorderInformation(workorderId) {
    this.workorderService.getWorkOrder(workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        workorderData => {
          if (workorderData.contact_info_communicated) {
            let contactInfoCommunicated = JSON.parse(workorderData.contact_info_communicated)
            if (contactInfoCommunicated.messages && contactInfoCommunicated.messages.length > 0) this.scheduledToSend = contactInfoCommunicated.messages["0"].scheduledtosend
          }
          if (workorderData.state == 3) {
            this.done = true
          } else if (workorderData.state == 4) {
            this.interrupted = true
            this.interruptedReason = workorderData.interrupt_reason
            this.interruptedReasonSelect = JSON.parse(JSON.stringify(workorderData.interrupt_reason))
            this.interruptedDate = workorderData.interrupt_date
            this.interruptor = workorderData.interruptor
            this.interruptCode = workorderData.interrupt_code
            this.interruptCodeSelect = JSON.parse(JSON.stringify(workorderData.interrupt_code))
          }
          this.workorder = workorderData
          for (let i = 0; i < workorderData.enduser_id.length; i++) {
            workorderData.enduser_id[i].person_type_string = "persontype." + workorderData.enduser_id[i].person_type
          }
          this.enduserList = workorderData.enduser_id

          // Customer information
          this.phoneNumber = workorderData.contact_info
          this.additionalInformation = workorderData.notes
          this.notesForInstaller = workorderData.worker_notes
          // Original values
          this.originalPhoneNumber = workorderData.contact_info
          this.originalAdditionalInformation = workorderData.notes
          this.originalNotesForInstaller = workorderData.worker_notes

          if (workorderData.time_window_start) this.workorderStartTime = workorderData.time_window_start
          if (workorderData.time_window_end) this.workorderEndTime = workorderData.time_window_end
          if (workorderData && workorderData.enduser_id.length > 0) {
            this.messageLogs = []
            workorderData.enduser_id.forEach(element => {
              if (element.text_communicated) {
                let logs = JSON.parse(element.text_communicated)
                if (logs) {
                  logs.forEach(item => {
                    if (item.date) {
                      item.date = this.formatDate(item.date)
                      item.date = item.date + " " + item.time
                      const dateString = item.date.endsWith('Z') ? item.date : item.date + 'Z';
                      const utcDate = new Date(dateString);
                      
                      // Transform the date to local time using DatePipe
                      item.date = this.datePipe.transform(utcDate, 'dd.MM.yyyy HH:mm') || '';
                    }
                    this.messageLogs.push(item)
                  });
                }
              }
            });
          }
          this.workorderSpinner = false
          this.getCalendars(this.workorderId)
          if (this.enduserList && this.enduserList.length > 0) {
            this.getFirstEndUserCallingLog();
          }

          this.getInterruptoptions();
          this.getFirstEndUserCallingLog()
          this.getLogs(this.workorderId);
        }
      )
  }

  formatDate(dateString: string): string {
    const parts = dateString.split('-');
    const year = parts[0];
    const day = parts[1].padStart(2, '0');
    const month = parts[2].padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Get cleanup and main calendar for workorder, returns 404 not found if calendars are not found for
   * given workorder. We show user Swal message stating to add calendars to workorder if calendars are not found.
   * @param workorderId workorder id
   */
  getCalendars(workorderId) {
    this.callService.getCalendarsByWorkorder(workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (data != undefined) {
            if (data.calendar.type == 1) {
              this.mainCalendar = data.calendar
              this.cleanupCalendar = data.cleanup
              this.activeCalendar = 1
            } else {
              this.cleanupCalendar = data.calendar
              this.activeCalendar = 2
              this.onlyCleanupCalendar = true
            }

          }
          if (this.mainCalendar || this.cleanupCalendar) { } this.checkForCalendarDates()


        }
      )
  }

  /**
   * Put correct calendar to active calendar.
   * Compare enddate and startdate and when we find correct if statement use it to shift weeks to correct week.
   */
  checkForCalendarDates() {
    let currentCalendar: Calendar | null = this.getCurrentCalendar()

    // day of the first slot
    if (currentCalendar && currentCalendar.slots && Object.keys(currentCalendar.slots).length > 0) {
      // @ts-ignore
      let mapped = Object.keys(currentCalendar.slots).map(key => currentCalendar.slots[key]['starttime']);
      mapped.sort(function (a, b) {
        return new Date(a).valueOf() - new Date(b).valueOf();
      });
      let firstDay = this.getFirstDay(new Date())

      this.setCurrentWeek(firstDay)
    }

    this.calendarSpinner = false
  }

  /**
   * Get correct calendar according to variables, is there no cleanup calendar or is active calendar equal to 1
   * @returns correct calendar
   */
  getCurrentCalendar() {
    if (this.activeCalendar == 1) return this.mainCalendar
    else return this.cleanupCalendar
  }

  /**
   * First set active calendar.
   * Sets slots to active calendar. Loops through every day of current week.
   * Then loops through all slots for every day. (Might be better some otherway.)
   * Assigns slot for correct day. Also sets activeSlot if found.
   */
  putSlotsToCurrentWeek() {
    // Set active calendar
    let currentCalendar: Calendar | null = this.getCurrentCalendar()
    if (currentCalendar != null) {
      // Loops through days of current week
      let hasSlots: boolean = false
      for (let i = 0; i < this.currentWeek.length; i++) {
        this.currentWeek[i].slots = Array()
        // Loops through every slot for current day in first for loop
        let keys = Object.keys(currentCalendar.slots)

        keys.forEach((key, index) => {
          let object = currentCalendar?.slots[key]
          let newDate = new Date(object.starttime)
          let active = false

          if (object.starttime == this.workorderStartTime &&
            object.endtime == this.workorderEndTime &&
            this.workorderStartTime != "" &&
            this.workorderEndTime != "" &&
            this.activeSlot.id == 0) {
            active = true
            this.activeSlot = object
          } else if (this.activeSlot.id == object.id) {
            active = true
          }
          newDate.setHours(0, 0, 0, 0)
          this.currentWeek[i].date?.setHours(0, 0, 0, 0)
          // Compare slot and current week's day time to fit the slot to correct day
          if (newDate.getTime() == this.currentWeek[i].date?.getTime()) {
            // If workorder can't fit, don't add
            let slotLength = (new Date(object.endtime).getTime() - new Date(object.starttime).getTime()) / 1000 / 60
            let workerscount = currentCalendar?.workerscount !== undefined ? currentCalendar.workerscount : 0
            if (workerscount !== 0) {
              slotLength = slotLength * workerscount
              let slotMax = object.slot_max !== undefined && object.slot_max != null ? object.slot_max : currentCalendar?.slot_max_default
              if (!slotMax) { // In case of NaN
                slotMax = 0
              }
              slotLength = slotLength / 100 * slotMax
              slotLength = slotLength - object.fill
              // Disable if time estimate is greater than slot remaining time...
              // @ts-ignore
              if (this.workorder.time_estimate && this.workorder.time_estimate > slotLength) {
                // ...unless it's the active slot
                if (this.activeSlot.id != object.id) {
                  object.disabled = 1
                }
              }
            }
            // If disabled, don't add
            if (object.disabled != 1) {
              hasSlots = true
              let dateStart = new Date(object.starttime)
              let dateEnd = new Date(object.endtime)

              // Calculate the difference in milliseconds
              let differenceInMs = dateEnd.getTime() - dateStart.getTime();             

              // Convert milliseconds to minutes
              let differenceInMinutes = Math.floor(differenceInMs / 1000 / 60);
              
              
              let slotMax = object.slot_max
              if (slotMax === 0 || slotMax === null) slotMax = currentCalendar.slot_max_default
              this.currentWeek[i].slots.push({
                slot_max: slotMax,
                starttime: dateStart,
                endtime: dateEnd,
                id: object.id,
                active: active,
                locked: null,
                disabled: null,
                fill: object.fill / differenceInMinutes * 100
              })
            }
          }
        })
        if (hasSlots) this.weekHasSlots = true
        else this.weekHasSlots = false
      }
      this.currentWeekHasLoaded = true
    }
  }

  /**
   * Gets date and returns first day of week for that date.
   * Also fires change event for weekpicker if enabled is true. It changes week to correct week in week picker component.
   * @param givenDate some kind of date object or string
   * @returns first day of week for given date.
   */
  getFirstDay(givenDate) {
    if (givenDate != null) {
      let date = new Date(givenDate);
      let day = date.getDay(),
        diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      let newDate = new Date(date.setDate(diff));
      if (this.enableChangeWeekPicker) {
        this.changeWeekPickerDate = newDate
      }
      return newDate
    }
    return null
  }

  /**
   * Switch tab between enduser page and calling list
   * @param number tab to switch to
   */
  switchTab(number: number) {
    if (this.tab !== number) {
      this.tab = number;
    }
  }

  /**
   * Switch between cleanup and main calendar with button in interface.
   * @param number number of calendar
   */
  switchCalendar(number: number) {
    if (this.activeCalendar != number) {
      this.activeCalendar = number
      this.checkForCalendarDates()
    }
  }

  /**
   * Modify current week to be correct according to given first day.
   * Fires when user clicks changeWeek button in interface meaning week picker component changes week.
   * @param firstDay first day of week
   */
  setCurrentWeek(firstDay) {
    if (firstDay != null) {
      for (let i = 0; i < this.currentWeek.length; i++) {
        let newDate = new Date(firstDay)
        newDate.setDate(newDate.getDate() + i)
        this.currentWeek[i].date = newDate
      }
      this.putSlotsToCurrentWeek()
    }
  }

  // datepicker loaded in the middle of setting current week and messed things up
  // so we enable it after we are done with the first round
  setCurrentWeekPicker(firstDay) {
    if (this.currentWeekHasLoaded) {
      this.setCurrentWeek(firstDay)
    }
  }

  /**
   * Back button click that takes us back to main page.
   */
  goToDashboard() {
    if (this.backTab === 1) {
      this.router.navigate(['call-service/dashboard']);
    } else if (this.backTab === 2) {
      // Navigate back to the "Soittolista" tab
      this.router.navigate(['call-service/dashboard'], { queryParams: { tab: 2 } });
    }
  }

  /**
   * Logic for changing active slot. Active slot needs to be tracked because we want to save it to database
   * when user decides to save it.
   */
  changeActiveSlot(id) {
    this.activeSlotEnabled = true
    if (this.activeSlot.id != id) {
      if (this.activeSlot) this.activeSlot.active = false
      for (let j = 0; j < this.currentWeek.length; j++) {
        for (let x = 0; x < this.currentWeek[j].slots.length; x++) {
          if (this.currentWeek[j].slots[x].id == id) {
            this.workorderStartTime = this.currentWeek[j].slots[x].starttime
            this.currentWeek[j].slots[x].active = true
            // Make true copy of slot. If only assigned without JSON functions makes only a reference.
            this.activeSlot = JSON.parse(JSON.stringify(this.currentWeek[j].slots[x]))
          } else {
            this.currentWeek[j].slots[x].active = false
          }
        }
      }
    } else {

    }
  }

  /**
   * Save input fields
   * Updates values after saving so pressing save again does not do anything.
   */
  saveInformation() {
    this.isSaving = true
    if (this.invalidPhoneNumber) {
      Swal.fire({
        title: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberTitle'),
        text: this.translateService.instant('call-service.singleEnduser.swal.invalidPhoneNumberText'),
        confirmButtonText: this.translateService.instant('basic.ok'),
        showCancelButton: false
      })
      this.isSaving = false
      return false
    }

    let changed = false

    if (this.originalNotesForInstaller != this.notesForInstaller ||
      this.originalAdditionalInformation != this.additionalInformation ||
      this.originalPhoneNumber != this.phoneNumber) {
      changed = true

      this.callService.saveInputFields(this.workorderId, this.phoneNumber, this.additionalInformation, this.notesForInstaller)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.isSaving = false
        })
      this.originalPhoneNumber = this.phoneNumber
      this.originalNotesForInstaller = this.notesForInstaller
      this.originalAdditionalInformation = this.additionalInformation
    }

    if (!changed) {
      this.toastService.sendToast(false, this.translateService.instant('basic.nothingToSave'))
      this.isSaving = false
    }
    return true
  }

  /**
   * Save slots information
   * Updates values after saving so pressing save again does not do anything.
   */
  saveSlot() {
    if (this.done) {
      return false
    }
    if (this.workorder.status === 7) {
      this.toastService.sendToast(false, this.translateService.instant('call-service.workorderCancelled'))
      return false
    }
    this.isSaving = true
    let changed = false

    if (this.activeSlot && this.activeSlotEnabled) {
      changed = true
      if (!this.onlyCleanupCalendar && this.activeCalendar == 2 && this.cleanupCalendar) {
        for (let i = 0; i < Object.keys(this.cleanupCalendar.slots).length; i++) {
          if (Object.values(this.cleanupCalendar.slots)[i].id == this.activeSlot.id) {
            Swal.fire({
              title: this.translateService.instant('call-service.singleEnduser.swal.saveConfirmation'),
              text: this.translateService.instant('call-service.singleEnduser.swal.mainCalendarAccess'),
              confirmButtonText: this.translateService.instant('basic.continue'),
              showCancelButton: true
            }).then((result) => {
              if (result.isConfirmed) {
                this.callService.saveSlot(this.workorderId, this.activeSlot.id)
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(
                    () => {
                      this.getWorkorderInformation(this.workorder.id)
                    }
                  )
                this.activeSlotEnabled = false
              }
              this.isSaving = false
            })
            break
          }

        }

      } else {
        this.callService.saveSlot(this.workorderId, this.activeSlot.id)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            () => {
              this.getWorkorderInformation(this.workorder.id)
              this.isSaving = false
            }
          )
        this.activeSlotEnabled = false
      }
    } else if (this.activeSlot) {
      changed = true
      Swal.fire({
        title: this.translateService.instant('basic.nothingToSave'),
        text: this.translateService.instant('call-service.singleEnduser.swal.emptySaveConfirmation'),
        confirmButtonText: this.translateService.instant('basic.yes'),
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.callService.saveSlot(this.workorderId, this.activeSlot.id)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              () => {
                this.getWorkorderInformation(this.workorder.id)
                this.isSaving = false
              }
            )
          this.activeSlotEnabled = false
        } else {
          this.isSaving = false
        }
      })
    }

    if (!changed) {
      this.toastService.sendToast(false, this.translateService.instant('basic.nothingToSave'))
      this.isSaving = false
    }
    return true
  }

  changeInterruptIcon(color) {
    if (color == 'white') this.interruptIcon = 'assets/icons/close_white_24dp.svg'
    else this.interruptIcon = 'assets/icons/close_red_24dp.svg'
  }

  saveInterruption() {
    let interruptCode = JSON.parse(JSON.stringify(this.selectedInterrupt))
    this.interruptedReason = JSON.parse(JSON.stringify(this.interruptedReasonSelect))
    if (!this.done) {
      this.callService.saveInterrupt(this.interruptedReason, this.workorderId, interruptCode)
      this.interrupted = true
      this.userService.getUserInfo().subscribe(
        data => {
          this.interruptor = data.firstname + " " + data.lastname
          const foundInterrupt = this.interruptOptions.find(option => option.interrupt_code == interruptCode);
          if(foundInterrupt && foundInterrupt.definition) {
            this.interruptCodeDefinition = foundInterrupt.definition
          } else if (interruptCode === '00') this.interruptCodeDefinition = this.translateService.instant('basic.other')
        }
      )
    }
    document.getElementById('closeModal')?.click()
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/login'])
  }

  checkPhoneNumber() {
    let phoneNumberCheck = this.phoneNumber
    if (this.phoneNumber.charAt(0) == '+') {
      phoneNumberCheck = this.phoneNumber.substring(1)
    }

    this.invalidPhoneNumber = true
    if (this.phoneNumber != "") {
      if (this.countryCodesList.some(countryCode => phoneNumberCheck.startsWith(countryCode))) {
        this.invalidPhoneNumber = false
      }
    } else {
      this.invalidPhoneNumber = false
    }
  }

  isSlotActive(slot: any): boolean {
    const now = new Date();
    const slotDate = new Date(slot.starttime);
    const cutoff = new Date(slotDate);
    cutoff.setDate(cutoff.getDate() - 1);
    cutoff.setHours(20, 0, 0, 0); // set to 8pm of the day before the slot

    const ret = now.getTime() < cutoff.getTime();
    return ret;
  }


  determineSlotClass(slot: any): string {
    let classes = ''
    // alkuperäinen slotti?
    if (slot.starttime.getTime() == new Date(this.workorder.time_window_start).getTime() &&
      slot.endtime.getTime() == new Date(this.workorder.time_window_end).getTime()) {
      classes += 'border-black '
    }

    if (slot.active) {
      if (this.activeSlotEnabled) {
        classes += 'bg-secondary text-white'
      }
    } else {
      if (!this.isSlotActive(slot)) {
        classes += 'disabled-slot'
      }
      classes += 'slot-hover'
    }
    return classes

  }

  changeSlotIfActive(slot: any): void {
    if (this.workorder.status === 7) return
    if (this.isSlotActive(slot)) {
      this.changeActiveSlot(slot.id);
    }
  }

  getLogs(workorderId) {
    this.workorderService.getWorkorderLogs(workorderId, this.enduserId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.created) {
              const dateString = element.created.endsWith('Z') ? element.created : element.created + 'Z';
              const utcDate = new Date(dateString);
              
              // Transform the date to local time using DatePipe
              element.created = this.datePipe.transform(utcDate, 'dd.MM.yyyy HH:mm') || '';
            }

            if (element.description == 'msg_workorder_modified') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.modified')
            else if (element.description == 'msg_workorder_started_working') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.startedWorking')
            else if (element.description == 'msg_workorder_not_started') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.notStarted')
            else if (element.description == 'msg_workorder_moved_to_calendar') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.movedToCalendar')
            else if (element.description == 'msg_workorder_marked_done') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.done')
            else if (element.description == 'msg_workorder_marked_done_with_additional_work') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.doneWithAdditional')
            else if (element.description == 'msg_workorder_interrupted') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.interrupted')
            else if (element.description == 'msg_time_confirmed') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed')
            else if (element.description == 'msg_time_confirmed_by_enduser') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_enduser')
            else if (element.description == 'msg_time_confirmed_by_enduser_with_modifications') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_enduser_with_modifications')
            else if (element.description == 'msg_time_confirmed_by_phoneservice_with_modifications') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_phoneservice_with_modifications')
            else if (element.description == 'msg_workorder_generated_coordinates') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_generated_coordinates')
            else if (element.description == 'msg_workorder_forwarded') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_forwarded')
            else if (element.description == 'msg_workorder_message_sent') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_message_created')
            else if (element.description == 'msg_workorder_mass_modified') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.interrupted')
            else if (element.type == 'call') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.call') + ', ' + (element.answered ? this.translateService.instant('planner.reportWorkorderDetail.logMessages.answered') : this.translateService.instant('planner.reportWorkorderDetail.logMessages.notAnswered'))
            else if (element.description == null) element.description = ''
            else if (element.description.startsWith('msg_workorder_status_updated, new id ')) {
              // msg_workorder_status_updated, new id 38
              let id = element.description.substring('msg_workorder_status_updated, new id '.length);
              this.plannersService.getStatus(id)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                  data => {
                    let definition
                    let defjson = JSON.parse(data.definition) || {}
                    if (this.userLang) {
                      definition = defjson[this.userLang] ||
                        defjson['en'] ||
                        undefined;
                    } else {
                      definition = defjson['en'] || undefined;
                    }

                    element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_status_updated') + definition
                  })
            }
            else element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.unknown')
          }
          if (this.scheduledToSend) {
            let newLog = { created: this.scheduledToSend, description: this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_message_sent'), user_name: null, user_id: null };
            let logs = [...data];  // Create a copy of the existing logs array
            logs.push(newLog);  // Add the new log to the logs array

            // Sort the logs array by the 'created' property
            logs.sort((a, b) => a.created.localeCompare(b.created));

            this.workorderLogs = logs;
          } else this.workorderLogs = data
        }
      )
  }

  onDidNotAnswerButtonClick() {
    this.callStatus = false;
    this.workorderService.addCallToEndUser(this.enduserId, false).subscribe(
      () => {
        this.toastService.sendToast(true, this.translateService.instant('planner.endUserCommunications.declinedCallSaved'));
        this.router.navigate(['call-service/dashboard'])
      },
      (error) => {
        console.error(this.translateService.instant('planner.endUserCommunications.errorMessages.answeredCallerror'), error);
      }
    );
  }

  // Function to handle "Log a Call" button click
  onLogACallClick() {
    this.callStatus = true;
  }

  // Function to handle "x" button click
  onCancelLogACallClick() {
    this.callStatus = false;
  }

  onAnsweredButtonClick() {
    this.callStatus = false;
    this.workorderService.addCallToEndUser(this.enduserId, true)
      .subscribe(
        () => {
          this.toastService.sendToast(true, this.translateService.instant('planner.endUserCommunications.answeredCallSaved'));
          this.getLogs(this.workorderId)
          this.getFirstEndUserCallingLog()
        },
        (error) => {
          console.error(this.translateService.instant('planner.endUserCommunications.errorMessages.answeredCallerror'), error);
          // Handle error here
        }
      );
  }
  getFirstEndUserCallingLog() {
    this.workorderService.getEndUserCallingLog(this.enduserList[0].id)
      .subscribe({
        next: (data) => {
          // Directly assign the data since the service method now handles response unwrapping.
          data.forEach((d: any) => {
            if (d.date) {
              const dateString = d.date.endsWith('Z') ? d.date : d.date + 'Z';
              const utcDate = new Date(dateString + d.time);

              // Transform the date to local time using DatePipe
              d.date = this.datePipe.transform(utcDate, 'dd.MM.yyyy HH:mm') || '';
            }
            d.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.call') + ', ' + (d.answered ? this.translateService.instant('planner.reportWorkorderDetail.logMessages.answered') : this.translateService.instant('planner.reportWorkorderDetail.logMessages.notAnswered'))
          })
          this.callingLog = data;
        },
        error: (error) => {
          // console.error('There was an error fetching the calling log:', error);
        }
      });
  }


  getInterruptoptions() {
    this.plannersService.getInterruptOptions(null, this.workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (interrupts: Interrupt[]) => {
          if (interrupts.length > 0) {
            this.selectedInterrupt = interrupts[0].interrupt_code;
          
            interrupts.forEach(interrupt => {
                let interrupt_definition
                let interruptdef = interrupt.definition || {}
                if(this.userLang) {
                  interrupt_definition = interruptdef[this.userLang] ||
                    interruptdef['en'] ||
                    undefined;
                } else {
                  interrupt_definition = interruptdef['en'] || undefined;
                }

              interrupt.definition = interrupt_definition
            });
          }
          let otherInterrupt: any = {definition: this.translateService.instant('basic.other'), interrupt_code: '00'}
          if (interrupts && interrupts.length > 0) interrupts = [...interrupts, otherInterrupt]
          else interrupts = [otherInterrupt]
          if (interrupts.length === 1) this.selectedInterrupt = interrupts[0].interrupt_code
          this.interruptOptions = interrupts

          const foundInterrupt = this.interruptOptions.find(option => option.interrupt_code == this.interruptCode);
          if(foundInterrupt && foundInterrupt.definition) {
            this.interruptCodeDefinition = foundInterrupt.definition
          } else if (this.interruptCode === '00') this.interruptCodeDefinition = this.translateService.instant('basic.other')
        },
        (error) => {
          console.error('Error fetching interrupt options', error);
        }

      )
  }

  sendMsgLog() {
    if (this.enduserId) {
      this.callService.updateEnduserMsgLog(this.enduserId, this.messageLogs).subscribe(
        () => this.getWorkorderInformation(this.workorderId)
      )
    }
  }
}
