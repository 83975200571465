import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FieldReportingService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  // TODO: Change handleError 'string' to better responses
  getFieldReporting(customerId) {
    const thisWeekOnly = customerId === 2;
    return this.http.get(`${baseUrl}/api/fieldreporting`, {params: {
        'thisweekonly': thisWeekOnly,
      }})
    .pipe(
      map((data) => {
        return data['message'];
      }),
      catchError(
        this.handleError(this.translateService.instant('services.gettingWorkordersFailed'), [])
      )
    )
  }

  reportWorkorder(id, data) {

    return this.http.post(`${baseUrl}/api/report/${id}`, data)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.reportSent'))
        return data['message']
      }),
      catchError(error => {
        if(error.error.message) {
          this.toastService.sendToast(false, this.translateService.instant('backend_errors.'+error.error.message))
        }
        // this.handleError(this.translateService.instant('services.reportFailed'))
        return of(false);
      })
   );
  }

  /**
   * 15.12.2022
   * Get projects barcodes with workorder ID
   * @param id Workorder ID
   * @returns Array that contains list of barcodes or empty array
   * @author Jesse Lindholm
   */
  getWorkerProjects(id: number) {
    return this.http.get(`${baseUrl}/api/workerprojects/${id}`)
    .pipe(
      map((projects) => {
        return projects['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.workerProjectFailed')))
    )
  }


  restatusMultipleWorkorders(workorders, status) {

    let body = {
      workorders: workorders,
      status_id: status
    }

    return this.http.patch(`${baseUrl}/api/restatusmultipleworkorders`, body)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, 'Success')
        return data['message']
      }),
      catchError(this.handleError('Patching workorders statuses failed'))
    )
  }

    /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
     private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        this.toastService.sendToast(false, error.message)


        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}
